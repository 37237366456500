import React from 'react'
import PropTypes from 'prop-types'
import Login from '../components/login'
import TitleMain from '../components/titleMain'
import userStore from '../store/userStore.js';
import {observer} from 'mobx-react';

class Signin extends React.Component {
  render () {
    return(
      <div className="Signin">
        <TitleMain name='登录'/>
        <Login userStore={this.props.userStore} history={this.props.history}/>
      </div>
    )
  }
}

export default Signin;
