import img__usb from '../assets/img/accessory__usb.jpg';
import img__dupont from '../assets/img/accessory__dupont.jpg';
import img__IRSensor from '../assets/img/accessory__IRSensor.jpg';
import img__servo from '../assets/img/accessory__servo.jpg';
import img__rubberCircle from '../assets/img/accessory__rubberCircle.jpg';
import img__motor from '../assets/img/accessory__motor.jpg';
import img__soilMoistureSensor from '../assets/img/accessory__soilMoistureSensor.jpg';
import img__rainSensor from '../assets/img/accessory__rainSensor.jpg';
import img__RTCSensor from '../assets/img/accessory__RTCSensor.jpg';
import img__gasSensor from '../assets/img/accessory__gasSensor.jpg';
import img__PIRSensor from '../assets/img/accessory__PIRSensor.jpg';
import img__ultrasonicSensor from '../assets/img/accessory__ultrasonicSensor.jpg';
import img__flameSensor from '../assets/img/accessory__flameSensor.jpg';
import img__vibrationSensor from '../assets/img/accessory__vibrationSensor.jpg';
import img__tiltSensor from '../assets/img/accessory__tiltSensor.jpg';
import img__lightSensor from '../assets/img/accessory__lightSensor.jpg';



const accessories={
    usb:{
      name:'USB Cable',
      nameSC:'USB线',
      nameTC:'USB线',
      img:img__usb,
    },
    dupont:{
      name:'Dupont Cable',
      nameSC:'杜邦线',
      nameTC:'杜邦线',
      img:img__dupont,
    },
    IRSensor:{
      name:'IR Sensor',
      nameTC:'红外距离传感器',
      nameSC:'红外线传感器',
      img:img__IRSensor,
    },
    servo:{
      name:'Servo',
      nameTC:'舵机',
      nameSC:'舵机',
      img:img__servo,
    },
    rubberCircle:{
      name:'Rubber Circle',
      nameTC:'橡胶圈',
      nameSC:'橡胶圈',
      img:img__rubberCircle,
    },
    motor:{
      name:'Motor',
      nameTC:'马达',
      nameSC:'马达',
      img:img__motor,
    },
    soilMoistureSensor:{
      name:'Soil Moisture Sensor',
      nameTC:'土壤传感器',
      nameSC:'土壤感测器',
      img:img__soilMoistureSensor,
    },
    rainSensor:{
      name:'Rain Sensor',
      nameTC:'雨滴传感器',
      nameSC:'雨滴感测器',
      img:img__rainSensor,
    },
    RTCSensor:{
      name:'Real Time Clock Sensor',
      nameTC:'时钟模块',
      nameSC:'时钟模块',
      img:img__RTCSensor,
    },
    gasSensor:{
      name:'Gas Sensor',
      nameTC:'气敏传感器',
      nameSC:'气体感测器',
      img:img__gasSensor,
    },
    PIRSensor:{
      name:'PIR Sensor',
      nameTC:'人体红外传感器',
      nameSC:'人体红外感测器',
      img:img__PIRSensor,
    },
    ultrasonicSensor:{
      name:'Ultrasonic Sensor',
      nameTC:'超声波传感器',
      nameSC:'超音波感测器',
      img:img__ultrasonicSensor,
    },
    flameSensor:{
      name:'Flame Sensor',
      nameTC:'火焰传感器',
      nameSC:'火焰感测器',
      img:img__flameSensor,
    },
    vibrationSensor:{
      name:'Vibration Sensor',
      nameTC:'震动传感器',
      nameSC:'震动感测器',
      img:img__vibrationSensor,
    },
    tiltSensor:{
      name:'Tilt Sensor',
      nameTC:'倾斜传感器',
      nameSC:'倾斜感测器',
      img:img__tiltSensor,
    },
    lightSensor:{
      name:'Light Sensor',
      nameTC:'光敏传感器',
      nameSC:'光敏感测器',
      img:img__lightSensor,
    },
}

export default accessories;
