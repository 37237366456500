import React, { Component } from 'react';
//css
import './assets/css/animation.css';
import './App.css';
//component
import Nav from './components/nav';
import Homepage from './layout/homepage';
import Footer from './components/footer';
import CocoMod from './components/cocomod';
import CocoKit from './components/cocokit';
import Curriculum from './components/curriculum';
import Create from './components/create';
import Competition from './components/competition';
import Form from './components/form';
import Login from './components/login';
import Logout from './components/logout';
import Order from './components/order';
import Service from './components/service';
import TakeOrder from './components/takeOrder';
import OrderState from './components/orderState';
import OrderQuery from './components/orderQuery';
import Blockly from './components/blockly';
import Activation from './components/activation';
import Reset from './components/reset';
import Success from './components/success';
import Workshop from './components/workshop';
import Download from './components/download';
import Train from './components/train';
//layout
import About from './layout/about';
import Contact from './layout/contact';
import Event from './layout/event';
import FAQ from './layout/faq';
import Print from './layout/print';
import Guide from './layout/guide';
import Discover from './layout/discover';
import Explore from './layout/explore';
import Product from './layout/product';
import CurriculumKitPage from './layout/curriculumKitPage';
import Feedback from './layout/feedback';
import Signup from './layout/signup';
import Profile from './layout/profile';
import Tutor from './layout/tutor';
import Signin from './layout/signin';
import DemoShow from './components/demoShow'
import DemoShows from './components/demoShowss'
import DemoDetail from './components/demodetail'
//router
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch
} from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
//store
import store from './store/orderStore.js';
import userStore from './store/userStore.js';
import uiStore from './store/uiStore.js';
//config
import config from './config';
//mobx
import { observer } from 'mobx-react';
//plugin
import axios from 'axios';


let loginState;
window.loginState = false;

let homepage = config.basename;

@observer
class App extends Component {
  constructor() {
    super();
    this.state = {
      auth: userStore.login,
    }
    this.checkLoginState = this.checkLoginState.bind(this);
    // TODO: set domain
    // document.domain = 'cocorobo.cn';
  }
  
  setAuth(authState) {
    this.setState({
      auth: authState
    })
  }
  componentDidMount() {
    this.checkLoginState();
    window.cocorobo = {
      login: function () {
        return userStore.login;
      }
    }
  }
  checkLoginState() {
    let self = this;
    axios.defaults.withCredentials = true;
    axios.get(config.server + '/api/state')
      .then(function (res) {
        if (res.data == 'Logged') {
          axios.get(`${config.server}/api/profile`)
            .then(function (response) {
              self.postMessageToParent('loginVerify', 'logged', response.data);
              userStore.username = response.data.username;
              userStore.alias = response.data.alias;
              userStore.login = true;
            })
            .catch(function (error) {
              console.log(error);
            })
        } else {
          userStore.login = false;
        }
      })
      .catch(function (err) {
        console.log(err);
      })
  }
  postMessageToParent(id, status, data) {
    //TODO: replace origin
    let result = {
      id,
      status,
      data
    }
    window.parent.postMessage(result, '*')
  }
  render() {
    const PrivateRoute = ({ render: Component, ...rest }) => (
      <Route {...rest} render={(props) => (
        userStore.login === true
          ? <Component {...props} />
          : <Redirect to='/login' />
      )} />
    )
    return (
      <Router basename={config.basename}>
        <LastLocationProvider>
          <div className="App">
            <Nav ref={(section) => { this.Nav = section; }} userStore={userStore} uiStore={uiStore} />
            <Switch>
              <Route exact path='/' render={() => (<Homepage uiStore={uiStore} />)} />
              <Route path='/cocomod' component={CocoMod} />
              <Route path='/cocokit' component={CocoKit} />
              <Route path='/curriculum' component={Curriculum} />
              <Route path='/service' component={Service} />
              <Route path='/discover' component={Discover} />
              <Route path='/explore' component={Explore} />
              <Route path='/create' component={Create} />
              <Route path='/about' component={About} />
              <Route path='/contact' component={Contact} />
              <Route path='/faq/' component={FAQ} />
              <Route path='/form' component={Form} />
              <Route path='/print' component={Print} />
              <Route path='/guide' component={Guide} />
              <Route path='/product' component={Product} />
              <Route path='/event' component={Event} />
              <Route path='/competition' component={Competition} />
              <Route path='/workshop' component={Workshop} />
              <Route path='/order' component={Order} />
              <Route path='/curriculumKit/:id' component={CurriculumKitPage} />
              <Route path='/feedback' component={Feedback} />
              <Route path='/signup' render={() => (<Signup userStore={userStore} />)} />
              <Route path='/login' render={({ match, location, history }) => (<Signin history={history} userStore={userStore} />)} />
              <Route path='/logout' render={() => (<Logout userStore={userStore} />)} />
              <Route path='/blockly' component={Blockly} />
              <Route path='/reset' component={Reset} />
              <Route path='/tutor' component={Tutor} />
              <Route path='/demoshow' component={DemoShow} />
              <Route path='/demoshows' component={DemoShows} />
              <Route path='/download' component={Download} />
              <Route path='/train' component={Train} />
              <Route path='/demodetail/:id' component={DemoDetail} />
              <PrivateRoute path='/success' render={() => (<Success content='登录成功' />)} />
              <PrivateRoute path='/activation' render={() => (<Activation />)} />
              <PrivateRoute path='/profile' render={() => (<Profile />)} />
              <PrivateRoute path='/orderQuery' render={() => (<OrderQuery store={store} />)} />
              <PrivateRoute path='/orderInfo' render={() => (<TakeOrder store={store} />)} />
              <PrivateRoute path='/orderInfo/succeed' render={() => (<OrderState store={store} />)} />
              <Route render={() => (<Redirect to='/' />)} />
            </Switch>
            <Footer />
          </div>
        </LastLocationProvider>
      </Router>
    );
  }
}



export default App;
