import {observable} from 'mobx'

class UIStore {
  @observable isHomepage = false;
  @observable windowWidth = window.innerWidth;
}

var uiStore = new UIStore;

export default uiStore;
