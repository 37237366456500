import React from 'react'
import '../assets/css/orderList.css';
//components
import OrderItem from './orderItem';
//data
import modules from './modules';
//store
import {observer} from 'mobx-react';
import store from '../store/orderStore.js';
import axios from 'axios';
import {Redirect} from 'react-router-dom';
//configure
import config from '../config';

@observer
class OrderList extends React.Component {
  constructor(props){
    super(props);
    this.submitOrder = this.submitOrder.bind(this);
    this.mobileOrderListToggle = this.mobileOrderListToggle.bind(this);
    this.state = {
      redirectTo:false,
      inActivated:false,
      redirectToLogin:false,
    }
  }
  submitOrder(){
    var self = this;
    axios.defaults.withCredentials = true;
    axios.get(`${config.server}/api/profile`)
    .then(function(res){
      if(res.data.active == true){
        self.setState({
          redirectTo:true,
        })
      }else{
        self.setState({
          inActivated:true,
        })
      }
    })
    .catch(function(err){
      if(err.request){
        if(err.request.status == 401){
          self.setState({
            redirectToLogin:true,
          })
        }
      }
    })
  }
  mobileOrderListToggle(){
    document.querySelectorAll('.OrderList')[0].classList.toggle('OrderList--expand');
    document.querySelectorAll('.OrderList__toggle')[0].classList.toggle('OrderList__toggle--open')
  }
  render () {
    const {amount, order , isEmpty} = this.props.store;
    let modulesInStore = [];
    // x=='t' escape t module in modules
    Object.keys(modules).forEach((x)=>Object.keys(modules[x]).forEach((i)=>x == 't'?'':modulesInStore.push(modules[x][i].name)));
    //avoid o5 moudle [Env module]
    let envModule = modulesInStore.indexOf('环境传感');
    modulesInStore.splice(envModule,1);

    let kitsInStore=['创意电子','动力小车','机械达人','智能家居'];
    let itemInStore = kitsInStore.concat(modulesInStore);
    if(this.state.redirectTo){
      return <Redirect to='/orderInfo' />
    }
    if(this.state.redirectToLogin){
      return <Redirect to='/login' />
    }
    if(this.state.inActivated){
      return <Redirect to ='/activation'/>
    }
    return(
      <div>
      <h2 className='OrderList__title'>清单</h2>
      <div className="OrderList">
        <div className='OrderList__placeholder'>{isEmpty}</div>
        <div className="OrderList__item">
        {itemInStore.map((x,y)=> order.has(x)? <OrderItem key={y+x} name={x} value={order.get(x)[1]} store={store}/>:'')}
        </div>
        {/*<div className="OrderList__amount">
          {amount} HKD
        </div>*/}
        {/*<div className="OrderList__btn">
          <button onClick={this.submitOrder} style={{padding:'10px 20px',backgroundColor:'#000',border:0,color:'#fff',fontSize:'20px',cursor:'pointer',borderRadius:'5px'}}>提交</button>
        </div>*/}
      </div>
      <div className='OrderList__bar'>
        <div className='OrderList__toggle' id='OrderList__toggle' onClick={this.mobileOrderListToggle}></div>
        <div className="OrderList__amount">Total : <b>{amount}</b> HKD</div>
        <div className="OrderList__btn">
          <button onClick={this.submitOrder} style={{padding:'10px 20px',backgroundColor:'#000',border:0,color:'#fff',fontSize:'20px',cursor:'pointer',borderRadius:'5px'}}>提交</button>
        </div>
      </div>
      </div>
    )
  }
}

export default OrderList;
