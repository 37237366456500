import React from 'react'
import PropTypes from 'prop-types'
//components
import TitleMain from './titleMain';
import Button from './button';
//css
import '../assets/css/activation.css';
//store
import store from '../store/userStore';
//observer
import {observer} from 'mobx-react';
//axios
import axios from 'axios';
//config
import config from '../config';

@observer
class Activation extends React.Component {
  autoJumpEmail(){
    let username = store.username;
    let atPos = username.indexOf('@');
    let emailUrl = username.slice(atPos+1);
    if(emailUrl == 'qq.com'){
       emailUrl  = 'http://mail.qq.com'
    }else{
       emailUrl = `http://${emailUrl}`;
    }
    return emailUrl;
  }
  resend(){
    axios.get(`${config.server}/activate/resend`)
      .then(function(response){
        if(response.data == 'OK'){
          alert('邮件已发送');
        }
      })
      .catch(function(err){
        console.log(err);
      })
  }
  render () {
    return(
      <div>
        <TitleMain name='激活邮箱'/>
        <div className='Activation'>
          <div className='Activation__Desc'>
            <h1>请激活您的邮箱</h1>
          </div>
          <div className='Activation__active'>
            <a href={this.autoJumpEmail()} target='_blank'>
              <Button name='去激活' size='button--king'/>
            </a>
          </div>
          <p>*邮件有可能被标记为垃圾邮件</p>
          <div className='Activation__resend'>
            <p>没收到邮件？ 点击<span onClick={this.resend}style={{cursor:'pointer',textDecoration:'underline',color:'#7ECEFD'}}>重新发送</span></p>
          </div>
        </div>
      </div>
    )
  }
}

export default Activation;
