import {observable} from 'mobx'

class InquiryStore {
  @observable light = 0;
  @observable lightExtension = false;
  @observable IoT = 0;
  @observable IoTExtension = false;
  @observable mechanism = 0;
  @observable mechanismExtension = false;
  @observable power = 0;
  @observable powerExtension = false;
  @observable music = 0;
  @observable musicExtension = false;
  @observable HCI = 0;
  @observable HCIExtension = false;
  @observable fullKit = 0;

  getList(){
    let list = {
      '灯光 Basic Kit':this.light,
      '扩展材料包：百变台灯制作':this.lightExtension,
      '物联网Basic Kit':this.IoT,
      '扩展材料包：迷你气象站制作':this.IoTExtension,
      '机械 Basic Kit':this.mechanism,
      '扩展材料包：激光切割机械臂制作':this.mechanismExtension,
      '动力 Basic Kit':this.power,
      '扩展材料包：激光切割风火轮制作':this.powerExtension,
      '音乐Basic Kit':this.music,
      '扩展材料包：尤克里里制作':this.musicExtension,
      '人机交互 Basic Kit':this.HCI,
      '扩展材料包：游戏手柄制作':this.HCIExtension,
      '全套套件':this.fullKit,
    }
    return list
  }
}

var inquiryStore =window.inquiryStore= new InquiryStore;

export default inquiryStore;
