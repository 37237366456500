import React,{Component} from 'react';
import '../assets/css/titleSub.css';

class TitleSub extends Component{
	render(){
		return(
			<div>
				<h1 className='titleSub' style={{margin:this.props.margin}}>{this.props.name}</h1>
			</div>
		)
	}
}

export default TitleSub;
