import React ,{Component} from 'react';
import TitleMain from './titleMain';
import Article from './article';
import Sidebar from './sidebar';
import Image from './image';
import {Router ,Route } from 'react-router-dom';
//img
import service_education from '../assets/img/service_education.jpg';
import service_onlinePlatform from '../assets/img/service_onlinePlatform.png';
import service_caseStudy from '../assets/img/service_caseStudy.jpg';
import service_s1 from '../assets/img/service_s1.png';
import service_s2 from '../assets/img/service_s2.png';
import service_s3 from '../assets/img/service_s3.png';
import service_s4 from '../assets/img/service_s4.png';
//css
import '../assets/css/service.css';


//import plugins
import nl2br from 'react-newline-to-break';


const service = {
	educationService:{
			img:service_education,
			title:'教学服务',
			tag:'教学服务',
			content:'1.课程定制方案\n\n基于不同学校的要求和特点，我们针对性的课程评估方案，以及个性化的课程定制方案 \n\n2.教师培训\n\n由我方对学生老师进行培训，现正在与香港中文大学教育学院深度合作，共同推出师资培训认证 \n\n3.到校服务\n\n根据学校的需求，我方可以提供课程、教学及技术方面的课堂支持',
	},
	onlinePlatform:{
			img:service_onlinePlatform,
			title:'在线平台',
			tag:'在线平台',
			content:'CocoRobo 将提供配套的在线平台，供学生和老师在课后讨论用，并进行作业上传。\n\n我们提供产品百科和项目探索版块，学生亦可在我们的在线网站上找到相对应的资源自行学习。配合产品与课程，我们推出了三款云端开发平台（IDE）方便用户使用。用户可以免费注册，再根据我们提供的教学方案进行学习。此外，结合我们的线下及在线课程，用户可以在线提交作业，让老师批阅和反馈。',
	},
	caseStudy:{
			img:service_caseStudy,
			title:'案例学习',
			tag:'案例学习',
			content:'针对中三学生的STEM课程，计划采用项目制教学法进行授课，利用11周，22次课时来授课。\n\n使用 Problem-based Learning 方法，我们会引导学生利用前一单元所掌握的关于「3D模型设计」、「3D打印技术」、「电子电路」，以及「编程」等技能，来针对校园，设计未来心中的智能校园的场景。在这过程中，学生会进一步学习各种传感器的模块的使用，更进阶的3D绘图方法和电脑编程技能等',
	},
}

class Service extends Component{
	constructor(props){
		super(props);
		this.state={
			title:service.educationService.title,
			tag:service.educationService.tag,
			img:service.educationService.img,
			content:nl2br(service.educationService.content),
		}
	}
	componentDidMount(){
		// document.getElementById('sidebar').addEventListener('click',this.changeResult.bind(this));
		this.scrollToTop();

	}
	scrollToTop(){
		window.scrollTo(0,0);
	}
	changeResult(event){
		if(event.target.nodeName === 'LI'){
			let getName = event.target.id;
			document.querySelectorAll('.sidebarListActive')[0].className = 'sidebarList';
			document.getElementById(getName).className ='sidebarListActive';
		this.setState({
			title:service[getName].title,
			tag:service[getName].tag,
			content:nl2br(service[getName].content),
			img:service[getName].img,
		});
		}
	}
	render(){
		return(
			<div className='Service'>
				<TitleMain name='教师培训' />
				<div className="Service__section">
					<Image src={service_s1} />
					<p>CocoRobo为学校提供专业和全面的教师培训服务，让老师能迅速掌握教学方式。</p>
				</div>
				<TitleMain name='到校授课' />
				<div className="Service__section">
					<Image src={service_s2} />
					<p>根据学校的不同需求，CocoRobo可以提供课程、教学以及技术方面的课堂支持</p>
				</div>
				<TitleMain name='赛事主办' />
				<div className="Service__section">
					<Image src={service_s3} />
					<p style={{width:'80%',margin:'50px auto '}}>为推广STEM教育的理念，并促进各校之间办学心得、学术成果的交流，CocoRobo联合各校长会举办了以「3D打印机器人对抗赛」为主题的系列化STEM赛事。鼓励学生将课堂所学的STEM知识运用于实际比赛中，从而提高学生对STEM的认识及学习兴趣。</p>
					<a href='http://ccc.3dprobot.org' target='_blank'>比赛链接</a>
				</div>
				<TitleMain name='科技节' />
				<div className="Service__section">
					<Image src={service_s4} />
					<p>针对丰富的课外活动，CocoRobo可以为校园科技节、兴趣社团等提供课程、技术咨询等方面的支持</p>
				</div>








				{/*<Article
					title={this.state.title}
					tag={this.state.tag}
					img={this.state.img}
					content={this.state.content} />
				<Sidebar />*/}
			</div>
		)
	}
}

export default Service;
