import React from 'react'
import PropTypes from 'prop-types'
import TitleMain from './titleMain'
import '../assets/css/printer.css';
import miniPrinter from '../assets/img/miniPrinter.jpg';

class Printer extends React.Component {
  render () {
    return(
      <div className='Printer'>
        <TitleMain name='3D打印机'/>
        <div className='Printer__body'>
          <img className='Printer__img' src={miniPrinter} width='100%' alt='mini-printer' />
          <div className='Printer__title'>
            <ul className='Printer__list'>
              <li className='Printer-list__item'>型号</li>
              <li className='Printer-list__item'>框架</li>
              <li className='Printer-list__item'>喷头数量</li>
              <li className='Printer-list__item'>成型尺寸</li>
              <li className='Printer-list__item'>层厚</li>
              <li className='Printer-list__item'>内存卡脱机打印</li>
              <li className='Printer-list__item'>液晶屏</li>
              <li className='Printer-list__item'>喷嘴直径</li>
              <li className='Printer-list__item'>平台温度</li>
              <li className='Printer-list__item'>平台制造材质</li>
              <li className='Printer-list__item'>支持材料</li>
              <li className='Printer-list__item'>材料直径</li>
              <li className='Printer-list__item'>耗材倾向性</li>
              <li className='Printer-list__item'>软件语言</li>
              <li className='Printer-list__item'>支持文档格式</li>
              <li className='Printer-list__item'>模型支撑功能</li>
              <li className='Printer-list__item'>机器尺寸</li>
              <li className='Printer-list__item'>机器重量</li>
            </ul>
          </div>
          <div className="Printer__content">
            <ul className='Printer__list'>
              <li className='Printer-list__item'>Mini</li>
              <li className='Printer-list__item'>钣金结构</li>
              <li className='Printer-list__item'>1</li>
              <li className='Printer-list__item'>105*135*120mm</li>
              <li className='Printer-list__item'>0.05-0.2mm</li>
              <li className='Printer-list__item'>SD卡脱机打印／USB联机打印</li>
              <li className='Printer-list__item'>有</li>
              <li className='Printer-list__item'>标配0.4mm</li>
              <li className='Printer-list__item'>室温至110度可调</li>
              <li className='Printer-list__item'>铝板、环氧树脂</li>
              <li className='Printer-list__item'>PLA／ABS／PETG</li>
              <li className='Printer-list__item'>1.75mm</li>
              <li className='Printer-list__item'>PLA</li>
              <li className='Printer-list__item'>中文／英文</li>
              <li className='Printer-list__item'>STL|OBJ|G-Code</li>
              <li className='Printer-list__item'>生成／不生成可选</li>
              <li className='Printer-list__item'>∮205*331mm</li>
              <li className='Printer-list__item'>7.5KG</li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default Printer;
