import kit_keyboardPiano_bg from '../assets/img/kit_keyboardPiano_bg.png';
import kit_keyboardPiano from '../assets/img/website-08.png';
import kit_carLineTracking_bg from '../assets/img/kit_carLineTracking_bg.png';
import kit_carLineTracking from '../assets/img/website-07.png';
import kit_LEDMiniBox_bg from '../assets/img/kit_LEDMiniBox_bg.png';
import kit_LEDMiniBox from '../assets/img/kit_LEDMiniBox.png';
import kit_bipedRobotBasic_bg from '../assets/img/kit_bipedRobotBasic_bg.png';
import kit_bipedRobotBasic from '../assets/img/website-06.png';
import kit_weatherStation_bg from '../assets/img/kit_weatherStation_bg.png';
import kit_weatherStation from '../assets/img/website-05.png';
import kit_robotArm_bg from '../assets/img/kit_robotArm_bg.png';
import kit_robotArm from '../assets/img/kit_robotArm.png';
import kit_competitionKit from '../assets/img/website-16.png';
import kit_fullKit from '../assets/img/website-04.png';
import kit_musicKit from '../assets/img/website-12.png';
import kit_mechanicalKit from '../assets/img/website-15.png';
import kit_machineKit from '../assets/img/website-13.png';
import kit_powerKit from '../assets/img/website-14.png';
import kit_ledKit from '../assets/img/website-11.png';
import kit_WebKit from '../assets/img/website-09.png';
import kit_WebKits from '../assets/img/website-10.png';
import kit_AientrylKit from '../assets/img/website-02.png';
import kit_AiotlKit from '../assets/img/website-03.png';
import kit_ailKit from '../assets/img/website-01.png';


//更改记录
//Fri Jun 29 7:00 P.M  Source: Tony

const kit ={
	0:{
		name:'人工智能套件',
		// nameEN:'Maker Kit',
		// desc:'百变智造套件是由 CocoRobo 自主研发的造物套件，里面包含所有功能的电子模块，可以互相组合满足上百种功能需求，充分赋能于个体，方便学生针对问题进行解决方案设计。',
		// modules:['m0','i0','i1','i2','i3','i4','o0','o1','o2','o3','o4','o5','c0','c1','a0','a1','a2','a3'],
		// lists:['土壤湿度传感器*1','雨滴传感器*1','时钟模块*1','气敏传感器*1','人体红外传感*1','超声波传感器*1','火焰传感器*1','震动传感器*1','红外循迹传感器*2','倾斜传感器*1','光照传感器*1','LED灯带*1',' MicroUSB数据线*1','母对母杜邦线*2','TT电机*2','9g舵机*4','彩色卡纸*3'],
		// bgImg:kit_fullKit,
		// bgColor:'#55257c',
		img:kit_ailKit,
		cat:'進階套件',
		video:'',
		id:'makerKit',
		unitPrice:450,
	},
	1:{
		name:'人工智能入門套件',
		// nameEN:'Maker Kit',
		// desc:'百变智造套件是由 CocoRobo 自主研发的造物套件，里面包含所有功能的电子模块，可以互相组合满足上百种功能需求，充分赋能于个体，方便学生针对问题进行解决方案设计。',
		// modules:['m0','i0','i1','i2','i3','i4','o0','o1','o2','o3','o4','o5','c0','c1','a0','a1','a2','a3'],
		// lists:['土壤湿度传感器*1','雨滴传感器*1','时钟模块*1','气敏传感器*1','人体红外传感*1','超声波传感器*1','火焰传感器*1','震动传感器*1','红外循迹传感器*2','倾斜传感器*1','光照传感器*1','LED灯带*1',' MicroUSB数据线*1','母对母杜邦线*2','TT电机*2','9g舵机*4','彩色卡纸*3'],
		// bgImg:kit_fullKit,
		// bgColor:'#55257c',
		img:kit_AientrylKit,
		cat:'进阶套件',
		video:"",
		id:'makerKit',
		unitPrice:450,
	},
	2:{
		name:'AIoT 套件',
		// nameEN:'Maker Kit',
		// desc:'百变智造套件是由 CocoRobo 自主研发的造物套件，里面包含所有功能的电子模块，可以互相组合满足上百种功能需求，充分赋能于个体，方便学生针对问题进行解决方案设计。',
		// modules:['m0','i0','i1','i2','i3','i4','o0','o1','o2','o3','o4','o5','c0','c1','a0','a1','a2','a3'],
		// lists:['土壤湿度传感器*1','雨滴传感器*1','时钟模块*1','气敏传感器*1','人体红外传感*1','超声波传感器*1','火焰传感器*1','震动传感器*1','红外循迹传感器*2','倾斜传感器*1','光照传感器*1','LED灯带*1',' MicroUSB数据线*1','母对母杜邦线*2','TT电机*2','9g舵机*4','彩色卡纸*3'],
		// bgImg:kit_fullKit,
		// bgColor:'#55257c',
		img:kit_AiotlKit,
		cat:'进阶套件',
		video:'https://online-cocorobo.s3.cn-northwest-1.amazonaws.com.cn/website-15.mp4',
		id:'makerKit',
		unitPrice:450,
	},
	3:{
		name:'百变制造',
		nameEN:'Maker Kit',
		desc:'百变智造套件是由 CocoRobo 自主研发的造物套件，里面包含所有功能的电子模块，可以互相组合满足上百种功能需求，充分赋能于个体，方便学生针对问题进行解决方案设计。',
		modules:['m0','i0','i1','i2','i3','i4','o0','o1','o2','o3','o4','o5','c0','c1','a0','a1','a2','a3'],
		lists:['土壤湿度传感器*1','雨滴传感器*1','时钟模块*1','气敏传感器*1','人体红外传感*1','超声波传感器*1','火焰传感器*1','震动传感器*1','红外循迹传感器*2','倾斜传感器*1','光照传感器*1','LED灯带*1',' MicroUSB数据线*1','母对母杜邦线*2','TT电机*2','9g舵机*4','彩色卡纸*3'],
		bgImg:kit_fullKit,
		bgColor:'#55257c',
		img:kit_fullKit,
		cat:'进阶套件',
		video:'https://online-cocorobo.s3.cn-northwest-1.amazonaws.com.cn/fullKit.mp4',
		id:'makerKit',
		unitPrice:450,
	},
	4:{
		name:'智能家居',
		nameEN:'Smart Home Kit',
		desc:'通过学习本套件，学生将能够制作迷你气象站。学生可以使用迷你气象站采集环境的温度／湿度等数据，并在荧幕上显示出来；同时可以通过Wi-Fi控制气象站，并实现数据的传输。',
		modules:['m0','c0','i5','o2','a1','a3'],
		lists:['土壤湿度传感器*1','雨滴传感器*1','时钟模块*1','气敏传感器*1','人体红外传感器*1','超声波传感器*1','火焰传感器*1','震动传感器*1','红外置近传感器*1','倾斜传感器*1','光敏传感器*1','USB数据线*1','杜邦线若干'],
		cat:'高级套件',
		bgImg:kit_weatherStation,
		bgColor:'#29a08f',
		img:kit_weatherStation,
		video:'https://online-cocorobo.s3.cn-northwest-1.amazonaws.com.cn/weatherStation.mp4',
		id:'smartHomeKit',
		unitPrice:450,
	},
	5:{
		name:'机械达人',
		nameEN:'Robot Guru',
		desc:'通过学习本套件，学生将能够制作一款百变双足机器人，可以通过设置进程使机器人作出特定的动作，除此之外，还可以通过蓝牙对它进行远程操作。通过这门课学生亦可制作一款可通过蓝牙操控的机械臂。',
		modules:['m0','o4','a2','a2','c1','a3','i0'],
		lists:['9克舵机*4','USB数据线*1','杜邦线若干','扳手螺丝套件','包装盒'],
		bgImg:kit_bipedRobotBasic,
		bgColor:'#302c7e',
		img:kit_bipedRobotBasic,
		cat:'中级套件',
		video:'https://online-cocorobo.s3.cn-northwest-1.amazonaws.com.cn/bipedRobot.mp4',
		id:'robotGuru',
		unitPrice:450,
	},
	6:{
		name:'动力小车',
		nameEN:'Robot Car',
		desc:'通过学习本套件，学生将能够制作多功能迷你小车。小车具有自动驾驶的功能，亦可通过感知前方是否有障碍物，来实现智能避障的功能，甚至可以通过蓝牙控制小车。',
		modules:['m0','o3','i0','c1','a1','a1'],
		lists:['橡胶圈*2','TT电机*2','万向轮*1','USB线*1','杜邦线若干','扳手螺丝套件','红外传感器','超声波传感器','包装盒'],
		bgImg:kit_carLineTracking,
		bgColor:'#43a0d0',
		img:kit_carLineTracking,
		cat:'中级套件',
		video:'https://online-cocorobo.s3.cn-northwest-1.amazonaws.com.cn/robotCarLineTracker.mp4',
		id:'robotCar',
		unitPrice:450,
	},
	7:{
		name:'创意电子',
		nameEN:'Creative Electronics',
		desc:'本套件将带领学生领略声音和灯光的美丽。利用这个套件可制作出可仿真不同乐器的电子琴。亦可以制作一个绚丽的呼吸灯。亦可将二者结合，制作出一个既可以发出声音也可以发出灯光的乐器。',
		modules:['m0','i2','o0','o1'],
		lists:['导电胶布*1','音频线','小音箱','USB数据线*1','杜邦线若干','包装盒'],
		bgImg:kit_keyboardPiano,
		bgColor:'#d83a37',
		img:kit_keyboardPiano,
		cat:'入门级套件',
		video:'https://online-cocorobo.s3.cn-northwest-1.amazonaws.com.cn/keyboardPiano.mp4',
		id:'creativeEletronics',
		unitPrice:450,
	},
	8:{
		name:'物联网主题——NB-IoT',
		// nameEN:'Maker Kit',
		// desc:'百变智造套件是由 CocoRobo 自主研发的造物套件，里面包含所有功能的电子模块，可以互相组合满足上百种功能需求，充分赋能于个体，方便学生针对问题进行解决方案设计。',
		// modules:['m0','i0','i1','i2','i3','i4','o0','o1','o2','o3','o4','o5','c0','c1','a0','a1','a2','a3'],
		// lists:['土壤湿度传感器*1','雨滴传感器*1','时钟模块*1','气敏传感器*1','人体红外传感*1','超声波传感器*1','火焰传感器*1','震动传感器*1','红外循迹传感器*2','倾斜传感器*1','光照传感器*1','LED灯带*1',' MicroUSB数据线*1','母对母杜邦线*2','TT电机*2','9g舵机*4','彩色卡纸*3'],
		// bgImg:kit_fullKit,
		// bgColor:'#55257c',
		img:kit_WebKit,
		cat:'进阶套件',
		video:'https://online-cocorobo.s3.cn-northwest-1.amazonaws.com.cn/website-08.mp4',
		id:'makerKit',
		unitPrice:450,
	},
	9:{
		name:'物联网主题——Wifi',
		// nameEN:'Maker Kit',
		// desc:'百变智造套件是由 CocoRobo 自主研发的造物套件，里面包含所有功能的电子模块，可以互相组合满足上百种功能需求，充分赋能于个体，方便学生针对问题进行解决方案设计。',
		// modules:['m0','i0','i1','i2','i3','i4','o0','o1','o2','o3','o4','o5','c0','c1','a0','a1','a2','a3'],
		// lists:['土壤湿度传感器*1','雨滴传感器*1','时钟模块*1','气敏传感器*1','人体红外传感*1','超声波传感器*1','火焰传感器*1','震动传感器*1','红外循迹传感器*2','倾斜传感器*1','光照传感器*1','LED灯带*1',' MicroUSB数据线*1','母对母杜邦线*2','TT电机*2','9g舵机*4','彩色卡纸*3'],
		// bgImg:kit_fullKit,
		// bgColor:'#55257c',
		img:kit_WebKits,
		cat:'进阶套件',
		video:"https://online-cocorobo.s3.cn-northwest-1.amazonaws.com.cn/website-01.mp4",
		id:'makerKit',
		unitPrice:450,
	},
	10:{
		name:'灯光主题',
		// nameEN:'Maker Kit',
		// desc:'百变智造套件是由 CocoRobo 自主研发的造物套件，里面包含所有功能的电子模块，可以互相组合满足上百种功能需求，充分赋能于个体，方便学生针对问题进行解决方案设计。',
		// modules:['m0','i0','i1','i2','i3','i4','o0','o1','o2','o3','o4','o5','c0','c1','a0','a1','a2','a3'],
		// lists:['土壤湿度传感器*1','雨滴传感器*1','时钟模块*1','气敏传感器*1','人体红外传感*1','超声波传感器*1','火焰传感器*1','震动传感器*1','红外循迹传感器*2','倾斜传感器*1','光照传感器*1','LED灯带*1',' MicroUSB数据线*1','母对母杜邦线*2','TT电机*2','9g舵机*4','彩色卡纸*3'],
		// bgImg:kit_fullKit,
		// bgColor:'#55257c',
		img:kit_ledKit,
		cat:'进阶套件',
		video:'https://online-cocorobo.s3.cn-northwest-1.amazonaws.com.cn/website-06.mp4',
		id:'makerKit',
		unitPrice:450,
	},
	11:{
		name:'音乐主题',
		// nameEN:'Maker Kit',
		// desc:'百变智造套件是由 CocoRobo 自主研发的造物套件，里面包含所有功能的电子模块，可以互相组合满足上百种功能需求，充分赋能于个体，方便学生针对问题进行解决方案设计。',
		// modules:['m0','i0','i1','i2','i3','i4','o0','o1','o2','o3','o4','o5','c0','c1','a0','a1','a2','a3'],
		// lists:['土壤湿度传感器*1','雨滴传感器*1','时钟模块*1','气敏传感器*1','人体红外传感*1','超声波传感器*1','火焰传感器*1','震动传感器*1','红外循迹传感器*2','倾斜传感器*1','光照传感器*1','LED灯带*1',' MicroUSB数据线*1','母对母杜邦线*2','TT电机*2','9g舵机*4','彩色卡纸*3'],
		// bgImg:kit_fullKit,
		// bgColor:'#55257c',
		img:kit_musicKit,
		cat:'进阶套件',
		video:'https://online-cocorobo.s3.cn-northwest-1.amazonaws.com.cn/website-02.mp4',
		id:'makerKit',
		unitPrice:450,
	},
	12:{
		name:'人机交互主题',
		// nameEN:'Maker Kit',
		// desc:'百变智造套件是由 CocoRobo 自主研发的造物套件，里面包含所有功能的电子模块，可以互相组合满足上百种功能需求，充分赋能于个体，方便学生针对问题进行解决方案设计。',
		// modules:['m0','i0','i1','i2','i3','i4','o0','o1','o2','o3','o4','o5','c0','c1','a0','a1','a2','a3'],
		// lists:['土壤湿度传感器*1','雨滴传感器*1','时钟模块*1','气敏传感器*1','人体红外传感*1','超声波传感器*1','火焰传感器*1','震动传感器*1','红外循迹传感器*2','倾斜传感器*1','光照传感器*1','LED灯带*1',' MicroUSB数据线*1','母对母杜邦线*2','TT电机*2','9g舵机*4','彩色卡纸*3'],
		// bgImg:kit_fullKit,
		// bgColor:'#55257c',
		img:kit_machineKit,
		cat:'进阶套件',
		video:'https://online-cocorobo.s3.cn-northwest-1.amazonaws.com.cn/website-04.mp4',
		id:'makerKit',
		unitPrice:450,
	},
	13:{
		name:'动力主題',
		// nameEN:'Maker Kit',
		// desc:'百变智造套件是由 CocoRobo 自主研发的造物套件，里面包含所有功能的电子模块，可以互相组合满足上百种功能需求，充分赋能于个体，方便学生针对问题进行解决方案设计。',
		// modules:['m0','i0','i1','i2','i3','i4','o0','o1','o2','o3','o4','o5','c0','c1','a0','a1','a2','a3'],
		// lists:['土壤湿度传感器*1','雨滴传感器*1','时钟模块*1','气敏传感器*1','人体红外传感*1','超声波传感器*1','火焰传感器*1','震动传感器*1','红外循迹传感器*2','倾斜传感器*1','光照传感器*1','LED灯带*1',' MicroUSB数据线*1','母对母杜邦线*2','TT电机*2','9g舵机*4','彩色卡纸*3'],
		// bgImg:kit_fullKit,
		// bgColor:'#55257c',
		img:kit_powerKit,
		cat:'进阶套件',
		video:'https://online-cocorobo.s3.cn-northwest-1.amazonaws.com.cn/website-05.mp4',
		id:'makerKit',
		unitPrice:450,
	},
	14:{
		name:'机械主題',
		// nameEN:'Maker Kit',
		// desc:'百变智造套件是由 CocoRobo 自主研发的造物套件，里面包含所有功能的电子模块，可以互相组合满足上百种功能需求，充分赋能于个体，方便学生针对问题进行解决方案设计。',
		// modules:['m0','i0','i1','i2','i3','i4','o0','o1','o2','o3','o4','o5','c0','c1','a0','a1','a2','a3'],
		// lists:['土壤湿度传感器*1','雨滴传感器*1','时钟模块*1','气敏传感器*1','人体红外传感*1','超声波传感器*1','火焰传感器*1','震动传感器*1','红外循迹传感器*2','倾斜传感器*1','光照传感器*1','LED灯带*1',' MicroUSB数据线*1','母对母杜邦线*2','TT电机*2','9g舵机*4','彩色卡纸*3'],
		// bgImg:kit_fullKit,
		// bgColor:'#55257c',
		img:kit_mechanicalKit,
		cat:'进阶套件',
		video:'https://online-cocorobo.s3.cn-northwest-1.amazonaws.com.cn/website-03.mp4',
		id:'makerKit',
		unitPrice:450,
	},
	15:{
		name:'3D 打印机器人比赛套件',
		nameEN:'Competition Kit',
		desc:'开启奇幻的魔盒，进行一场勇者之间的夺宝之旅。',
		modules:['t0','t1','t2','a1','a2','a3','t3','t4'],
		lists:['电机*4','9g舵机*2','金属舵机*4','USB线(一分二)*1','轮胎胶圈*4','小车装配用螺丝和螺帽*10','USB数据线*1','杜邦线*40','自封袋*1','包装盒 *1'],
		cat:'比赛套件',
		bgImg:kit_competitionKit,
		bgColor:'#3C78BC',
		img:kit_competitionKit,
		video:'https://online-cocorobo.s3.cn-northwest-1.amazonaws.com.cn/competitionKit.mp4',
		id:'competitionKit',
	},
}

export default kit;
