import React,{Component} from "react";
import TitleMain from './titleMain';
import '../assets/css/train.css'
import Train1 from '../assets/img/train1.png'
import Train2 from '../assets/img/train2.png'
import Train3 from '../assets/img/train3.png'
import Train4 from '../assets/img/train4.png'
import TrainRight from '../assets/img/train-right.png'
import Train5 from '../assets/img/train5.png'
import Train6 from '../assets/img/train6.png'
import Train6_1 from '../assets/img/train6-1.png'
import Train7 from '../assets/img/train7.png'
import Train8 from '../assets/img/train8.png'
import Train8_1 from '../assets/img/train8-1.png'
import Train9 from '../assets/img/train9.png'
import Train10 from '../assets/img/train10.png'
import Train11 from '../assets/img/train11.png'
import Train11_1 from '../assets/img/train11-1.png'
import Train11_2 from '../assets/img/train11-2.png'
import Train11_3 from '../assets/img/train11-3.png'
import Train12 from '../assets/img/train12.png'
import Train12_1 from '../assets/img/train12-1.png'
import Train13 from '../assets/img/train13.png'
import Train13_1 from '../assets/img/train13-1.png'
import Train13_2 from '../assets/img/train13-2.png'
import Train14 from '../assets/img/train14.png'
import Train15 from '../assets/img/train15.png'




class Train extends Component{
    constructor(props){
        super(props);
        this.state = {

        }
    }
    render(){
        return (
            <div className="train">
                <TitleMain name='训练平台使用介绍' />
                <h2 className="train_title">机器学习模型训练及应用平台</h2>
                <div className="train_content flex">
                    <img className="img1" src={Train1} alt="" />
                    <ul>
                        <li>
                            <span><a href="https://xunlian.cocorobo.cn" target="_blank">机器学习训练平台</a></span>
                            <p>
                            一站式的在线训练平台，涵盖图片标记、数据集处理、模型训练/验证/导出等功能，任何人都能训练属于自己的目标识别模型。通过AWS云服务提供常见机器学习模型的存储和自定义模型的训练，并在人工智能及物联网硬件上部署和应用。
                            </p>
                        </li>
                        {/* <li>
                            <span>自定义模型训练</span>
                            <p>
                            通过基于AWS云服务的自定义模型的训练平台，实现机器学习模型训练全流程的体验，输出在人工智能及物联网硬件上部署的自定义模型，并在实验室或真实应用场景进行验证。在K12学校，职业院校和大专院校通过训练自定义机器学习模型，满足常规课，竞赛和科研需求。
                            </p>
                        </li> */}
                    </ul>
                </div>
                <h2 className="train_title">机器学习模型训练流程</h2>
                
                <div className="train_content">
                    <img style={{width:'30%'}} src={Train2} alt="" />
                    <img style={{float:'right',width:'68%'}} src={Train4} alt="" />
                    <img style={{width:'30%',marginTop:'24px'}} src={Train3} alt="" />
                    <div className="train_process flex">
                        <div>
                            <h4>1.登录训练平台</h4>
                            <ul>
                                <li>用户名</li>
                                <li>密码</li>
                            </ul>
                        </div>
                        <img src={TrainRight} alt="" />
                        <div>
                            <h4>2.选择登录方式</h4>
                            <ul>
                                <li>预约方式</li>
                                <li>分组方式</li>
                            </ul>
                        </div>
                        <img src={TrainRight} alt="" />
                        <div>
                            <h4>3.进入训练平台主界面</h4>
                            <ul>
                                <li>监控服务器状态</li>
                                <li>开始训练（分组、标记、训练）</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="train_content">
                    <ul className="img_list flex">
                        <li>
                            <img src={Train5} alt="" />
                        </li>
                        <li>
                            <img src={Train6} alt="" />
                            <img style={{marginTop:25}} src={Train6_1} alt="" />
                        </li>
                        <li>
                            <img src={Train7} alt="" />
                        </li>
                        <li>
                            <img src={Train8} alt="" />
                            <img style={{marginTop:15}} src={Train8_1} alt="" />
                        </li>
                    </ul>
                    <div className="train_process flex">
                        <div>
                            <h4>4.创建分组或分组任务</h4>
                            <ul>
                                <li>创建分组</li>
                                <li>创建分组任务</li>
                                <li>进入分组</li>
                            </ul>
                        </div>
                        <img src={TrainRight} alt="" />
                        <div>
                            <h4>5.创建分组</h4>
                            <ul>
                                <li>输入最大分组数</li>
                                <li>长传带标注图片</li>
                                <li>创建标签</li>
                                <li>生成分组邀请码</li>
                            </ul>
                        </div>
                        <img src={TrainRight} alt="" />
                        <div>
                            <h4>6.选择登录方式</h4>
                            <ul>
                                <li>选择分组进入</li>
                            </ul>
                        </div><img src={TrainRight} alt="" />
                        <div>
                            <h4>7.进入分组</h4>
                            <ul>
                                <li>输入分组邀请码</li>
                                <li>进入已创建分组</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="train_content">
                    <ul className="img_list flex">
                        <li>
                            <img src={Train9} alt="" />
                        </li>
                        <li>
                            <img src={Train10} alt="" />
                        </li>
                        <li>
                            <img src={Train11} alt="" />
                            <div className="flex train11_img">
                                <img src={Train11_1} alt="" />
                                <img src={Train11_2} alt="" />
                                <img src={Train11_3} alt="" />
                            </div>
                        </li>
                    </ul>
                    <div className="train_process flex">
                        <div>
                            <h4>8.进入数据标记平台</h4>
                            <ul>
                                <li>使用已有图片标注（分组）</li>
                                <li>上传待标注图片（个人）</li>
                                <li>开始数据标记</li>
                            </ul>
                        </div>
                        <img src={TrainRight} alt="" />
                        <div>
                            <h4>9. 创建或加载标签</h4>
                            <ul>
                                <li>从文件加载标签</li>
                                <li>手动创建标签</li>
                                <li>开始数据标记项目</li>
                            </ul>
                        </div>
                        <img src={TrainRight} alt="" />
                        <div>
                            <h4>10. 数据标记及导出</h4>
                            <ul>
                                <li>标记图片并选择标签</li>
                                <li>上传标记文件</li>
                                <li>导出标记文件</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="train_content">
                    <ul className="img_list flex">
                        <li>
                            <img src={Train12} alt="" />
                            <img src={Train12_1} alt="" />
                        </li>
                        <li>
                            <img src={Train13} alt="" />
                            <img style={{marginTop:25}} src={Train13_1} alt="" />
                            <img style={{marginTop:25}} src={Train13_2} alt="" />
                        </li>
                        <li>
                            <img src={Train14} alt="" />
                        </li>
                        <li>
                            <img src={Train15} alt="" />
                        </li>
                    </ul>
                    <div className="train_process flex">
                        <div>
                            <h4>11. 完成训练数据集准备</h4>
                            <ul>
                                <li>合并分组</li>
                                <li>上传数据</li>
                            </ul>
                        </div>
                        <img src={TrainRight} alt="" />
                        <div>
                            <h4>12. 设置训练任务</h4>
                            <ul>
                                <li>选择训练数据集</li>
                                <li>设置最多训练迭代次数</li>
                                <li>开始训练</li>
                            </ul>
                        </div>
                        <img src={TrainRight} alt="" />
                        <div>
                            <h4>13. 查看训练状态</h4>
                            <ul>
                                <li>正在训练数据集</li>
                                <li>训练状态可视化（已迭代次数、当前进度、预估完成时间、训练持续时长）</li>
                            </ul>
                        </div>
                        <img src={TrainRight} alt="" />
                        <div>
                            <h4>14. 完成训练</h4>
                            <ul>
                                <li>结束训练</li>
                                <li>验证模型（默认图片或上传图片）</li>
                                <li>导出模型</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Train;