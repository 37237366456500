import React from 'react';
import TitleMain from '../components/titleMain';
import PropTypes from 'prop-types';
import '../assets/css/print.css';
import Button from '../components/button';
import Modal from '../components/modal';
import '../assets/css/modal.css';
import STLLoader from '../components/stlLoader';
import ComingSoon from '../components/comingSoon';



class Print extends React.Component {
  constructor(props){
    super(props);
  }

  render () {
    return(
        <div className='print'>
          <TitleMain name='3D打印' />
          <ComingSoon />

            {/*<div className='container'>
            <div className='present' id='present'>
              <input className='present__placeholder' type='file' id='uploadFile' accept='.stl' style={{display:'none'}}/>
              <label htmlFor='uploadFile' className='present__placeholder'></label>
              <STLLoader />
            </div>
            <div className='info'>
              <h3 className='info__title' style={{borderBottom:'2px solid #E3E3E3',paddingBottom:20,boxSizing:'border-box'}}>模型信息</h3>
              <div className='title__color'>颜色选择</div>
              <div className='title__material'>材料选择</div>
              <div className='title__volume'>体积</div>
              <div className='title__surfaceArea'>表面积</div>
              <div className='title__price'>总价格</div>
              <div className='color' id='picker'>
                <svg width="40" height="40">
                  <circle cx="20" cy="20" r="15" fill="#ffffff" />
                </svg>
                <svg width="40" height="40">
                  <circle cx="20" cy="20" r="15" fill="#7CCBDC" />
                </svg>
                <svg width="40" height="40">
                  <circle cx="20" cy="20" r="15" fill="#DA7045" />
                </svg>
              </div>
              <div className='material'>
                <div className='material__child'>PLA</div>
                <div className='material__child'>ABS</div>
                <button className='material_modal__button' uk-toggle='target: #material__modal' style={{backgroundColor:'#000',color:'#fff',borderRadius:'100%',border:0,cursor:'pointer'}}>!</button>
              </div>
              <div className="volume">
                <p>15 mm³</p>
              </div>
              <div className="surfaceArea">
                <p>20 mm²</p>
              </div>
              <div className="price">
                <p>110 ¥</p>
              </div>
            </div>
            <textarea className='note' placeholder='  备注信息' style={{fontSize:24}}>
            </textarea>
            <Button name='确认提交' />
          </div>
          <Modal id='material__modal'>
            <table style={{textAlign:'center'}} className='material__table'>
              <tbody>
              <tr>
                <th>材料类型</th>
                <th>精准度</th>
                <th>材料特性</th>
                <th>预计交货时间</th>
                <th>单价</th>
              </tr>
              <tr>
                <td>PLA</td>
                <td>0.15-0.30 mm</td>
                <td>良好的光泽性和透明度及良好的抗拉强度及延展度</td>
                <td>三天-五天</td>
                <td>0.75RMB/cm³</td>
              </tr>
              <tr>
                <td>ABS</td>
                <td>0.20-0.40 mm</td>
                <td>强度、耐冲击性、抗老化、硬度等性能指针兼顾的平衡优点</td>
                <td>三天-五天</td>
                <td>0.75RMB/cm³</td>
              </tr>
              </tbody>
            </table>
          </Modal>*/}

        </div>
    )
  }
}

export default Print ;
