import React, { Component } from 'react';
import TitleMain from './titleMain';
import cocoBlockly from '../assets/img/blockly-1.png';
import cocoBlocklyX from '../assets/img/blockly-2.png';
import Cocopi from '../assets/img/CocoPi.png';
import aiBlockly from '../assets/img/AI blockly-icon.png';
import python from '../assets/img/python blockly-icon.png'
import aihub from '../assets/img/AI Hub.png';
import aiScratch from '../assets/img/AI Scratch.png';
// import cocoPlayground from '../assets/img/cocoPlayground_t.svg';
// import cocoARApp from '../assets/img/cocoARApp_t.svg';
import cocoCAD from '../assets/img/cococad.png';
// import Maker from '../assets/img/3DMaker_t.svg';
import cocoCloud from '../assets/img/cloud.png';
import AItraining from '../assets/img/训练平台icon.png';

import '../assets/css/create.css';
import config from '../config';
//plugin
import Tilt from 'react-tilt';


const AButton = { padding: '5px 10px', background: '#fff', color: '#2e3e3f', fontSize: 14, borderRadius: 3, fontWeight: 600, position: 'absolute', bottom: 25, left: 'calc(50% - 38px)' }
const TiltInner = { background: '#2e3e3f', color: '#fff', padding: '0 0 20px 0', minHeight: 440, position: 'relative' }

class create extends Component {
	render() {
		return (
			<div>
				<TitleMain name='创造' />
				<div className="create">
					<Tilt className="Tilt" options={{ max: 25 }} >
						<div className="Tilt-inner" style={TiltInner}>
							<img style={{ width: '80%' }} src={aihub} alt="AI Hub" />
							<h3 style={{ fontWeight: 700, color: '#fff', marginTop: 0 }}>人工智能体验平台</h3>
							<p style={{ padding: '0 15px', fontSize: 14 }}>人工智能体验平台无需安装任何插件，使用浏览器便可直接体验最前沿的人工智能研究、技术和应用，实现与机器的高效互动。</p>
							<a href={`//aihub.cocorobo.cn/`} target="_blank" style={AButton}>点击进入</a>
						</div>
					</Tilt>
					<Tilt className="Tilt" options={{ max: 25 }} >
						<div className="Tilt-inner" style={TiltInner}>
							<img style={{ width: '80%' }} src={aiScratch} alt="AI Scratch" />
							<h3 style={{ fontWeight: 700, color: '#fff', marginTop: 0 }}>AI Scratch</h3>
							<p style={{ padding: '0 15px', fontSize: 14 }}>基于 Scratch 3.0 的人工智能积木式编程开发环境</p>
							<a href={`//ai-scratch.cocorobo.cn/`} target="_blank" style={AButton}>点击进入</a>
						</div>
					</Tilt>
					<Tilt className="Tilt" options={{ max: 25 }} >
						<div className="Tilt-inner" style={TiltInner}>
							<img style={{ width: '80%' }} src={cocoBlockly} className='' alt="CocoBlockly" />
							<h3 style={{ fontWeight: 700, color: '#fff', marginTop: 0 }}>百变智造<br />图形化编程平台</h3>
							<p style={{ padding: '0 15px', fontSize: 14 }}>使用百变智造图形化编程平台(Arduino)为我们百变智造电子套件进行编程</p>
							<a href={`${config.host}/cocoblockly/dev/`} target="_blank" style={AButton}>点击进入</a>
						</div>
					</Tilt>
					<Tilt className="Tilt" options={{ max: 25 }} >
						<div className="Tilt-inner" style={TiltInner}>
							<img style={{ width: '80%' }} src={cocoBlocklyX} className='' alt="CocoBlockly X" />
							<h3 style={{ fontWeight: 700, color: '#fff', marginTop: 0 }}>AIoT 图形化编程平台</h3>
							<p style={{ padding: '0 15px', fontSize: 14 }}>使用 AIoT 图形化编程平台为我们的AIoT电子套件进行编程</p>
							<a href={`//x.cocorobo.cn/`} target="_blank" style={AButton}>点击进入</a>
						</div>
					</Tilt>
					<Tilt className="Tilt" options={{ max: 25 }} >
						<div className="Tilt-inner" style={TiltInner}>
							<img style={{ width: '80%' }} src={aiBlockly} className='' alt="CocoBlockly X" />
							<h3 style={{ fontWeight: 700, color: '#fff', marginTop: 0 }}>AI 图形化编程平台</h3>
							<p style={{ padding: '0 15px', fontSize: 14 }}>AI图形化编程平台基于 Google Blockly 开发的人工智能平台，可以直接通过浏览器对人工智能进行相关体验</p>
							<a href={`//ai-blockly.cocorobo.cn/?lang=zh-hans`} target="_blank" style={AButton}>点击进入</a>
						</div>
					</Tilt>
					<Tilt className="Tilt" options={{ max: 25 }} >
						<div className="Tilt-inner" style={TiltInner}>
							<img style={{ width: '80%' }} src={Cocopi} className='' alt="CocoBlockly CocoPi" />
							<h3 style={{ fontWeight: 700, color: '#fff', marginTop: 0 }}>CocoPi<br />图形化编程平台</h3>
							<p style={{ padding: '0 15px', fontSize: 14 }}>CocoPi图形化编程平台基于 Google Blockly 开发的人工智能平台，可以直接通过浏览器对人工智能进行相关体验</p>
							<a href={`//pi.cocorobo.cn/?lang=zh-hans`} target="_blank" style={AButton}>点击进入</a>
						</div>
					</Tilt>
					<Tilt className="Tilt" options={{ max: 25 }} >
						<div className="Tilt-inner" style={TiltInner}>
							<img style={{ width: '80%' }} src={python} className='' alt="CocoBlockly X" />
							<h3 style={{ fontWeight: 700, color: '#fff', marginTop: 0 }}>Python<br />图形化编程平台</h3>
							<p style={{ padding: '0 15px', fontSize: 14 }}>使用 Python 图形化编程平台可以通过积木生成代码然后输出结果</p>
							<a href={`//python-blockly.cocorobo.cn/?lang=zh-hans`} target="_blank" style={AButton}>点击进入</a>
						</div>
					</Tilt>
					<Tilt className="Tilt" options={{ max: 25 }} >
						<div className="Tilt-inner" style={TiltInner}>
							<img style={{ width: '80%' }} src={cocoCloud} width='100%' alt="cocoCloud" />
							<h3 style={{ fontWeight: 700, color: '#fff', marginTop: 0 }}>物联网平台</h3>
							<p style={{ padding: '0 15px', fontSize: 14 }}>用户可以在该平台上进行物联网事件的管理,以及时间状态的查看，支持数据可视化功能，用户可以控制面板中的创建，并查看各类的数据</p>
							<a href={`${config.host}/cloud/`} target="_blank" style={AButton}>点击进入</a>
						</div>
					</Tilt>
					<Tilt className="Tilt" options={{ max: 25 }} >
						<div className="Tilt-inner" style={TiltInner}>
							<img style={{ width: '80%' }} src={cocoCAD} alt="cocoCAD" />
							<h3 style={{ fontWeight: 700, color: '#fff', marginTop: 0 }}>Coco CAD</h3>
							<p style={{ padding: '0 15px', fontSize: 14 }}>与 CocoBlockly 原理相似，这款 Coco-CAD 同样是使用积木的方式让学生体验到通过渐进式方法来进行 3D 建模</p>
							<a href={`${config.host}/cococad/`} target="_blank" style={AButton}>点击进入</a>
						</div>
					</Tilt>
					<Tilt className="Tilt" options={{ max: 25 }} >
						<div className="Tilt-inner" style={TiltInner}>
							<img style={{ width: '80%',padding:30 }} src={AItraining} alt="cocoCAD" />
							<h3 style={{ fontWeight: 700, color: '#fff', marginTop: 0 }}>训练平台</h3>
							<p style={{ padding: '0 15px', fontSize: 14 }}>使用可可乐博训练平台可以帮助您处理数据集，引导您一步步的开始模型训练。</p>
							<a href={`//xunlian.cocorobo.cn/`} target="_blank" style={AButton}>点击进入</a>
						</div>
					</Tilt>

					{/*<Tilt className="Tilt" options={{ max : 25 }} >
					 <div className="Tilt-inner">
						<a href='#'><img style={{height:100}}src={cocoPlayground} alt="cocoPlayground"/></a>
					 </div>
					</Tilt>
					<Tilt className="Tilt" options={{ max : 25 }} >
					 <div className="Tilt-inner">
						<a href='#'><img style={{height:100}}src={cocoARApp} alt="cocoARApp"/></a>
					 </div>
					</Tilt>
					<Tilt className="Tilt" options={{ max : 25 }} >
					 <div className="Tilt-inner">
						<a href='#'><img style={{height:100}}src={Maker} alt="3DMaker"/></a>
					 </div>
					</Tilt>*/}
				</div>
			</div>
		)
	}
}

export default create;
