import React , {Component} from 'react';
import '../assets/css/scroll.css'
//import plugins
import 'uikit/dist/css/uikit.min.css';
import 'uikit/dist/js/uikit.min.js';
import 'uikit/dist/js/uikit-icons.min.js';

class Scroll extends Component{
	render(){
		return(
			<div className="uk-box-shadow-medium scroll" state="closed" >
					<div className="wrapper">
						<img src={this.props.bgImg} alt='scroll'/>
						<h2>{this.props.title}</h2>
					</div>
					<p>{this.props.content}</p>
					<div className="desc">
						<img src={this.props.img} alt={this.props.name}/>
					</div>
					<div className="toggle" expand="false">
						<span></span>
						<span></span>
					</div>
			</div>
		)
	}
}
export default Scroll;
