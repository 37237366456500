import React ,{Component} from 'react';
import '../assets/css/button.css';

//props

//{
// size:['button--king','button--medium'],
// style:{},
// id:'',
// fn:function(),
// name:''
// }

class Button extends Component{
	render(){
		return(
			<button
				className={`button ${this.props.size} ${this.props.styleclass}`}
				style={this.props.style}
				id={this.props.id}
				onClick={this.props.fn}
				{...this.props}
				>
					{this.props.name}
			</button>
		)
	}
}
export default Button;
