import React from 'react'
import PropTypes from 'prop-types'
import '../assets/css/register.css';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
//router
import { Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';
//config
import config from '../config';
//geetest
import Geetest from 'react-geetest';
import qs from 'qs'



@observer
class Register extends React.Component {
  constructor(props) {
    super(props);
    this.toggleMask = this.toggleMask.bind(this);
    this.register = this.register.bind(this);
    this.state = {
      auth: false,
      geetest: false,
      gt: '',
      challenge: '',
      geetest_challenge: '',
      geetest_validate: '',
      geetest_seccode: '',
      prompt: '',
      codeShow: true,
      loginPress: false,
      codeNnm: "",
      codeTime: 60,
      codeTImeShow: true
    }
  }
  componentDidMount() {
    //googleSignIn
    if (this.props.store.username !== '') {
      document.getElementById('Register__input--username').value = this.props.store.username;
    }
    let self = this
    if (window.openId) {
      this.setState({
        codeShow: false
      })
    }
    this.geetestRegister();
  }

  // url 取值
  getQueryString = (name) => {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]); return null;
  }
  postMessageToParent(id, status, data) {
    //TODO: replace origin
    let result = {
      id,
      status,
      data
    }
    window.parent.postMessage(result, '*')
  }
  toggleMask(event) {
    event.target.classList.toggle('Register__unmask');
    if (document.getElementById('Register__input--pwd').type === 'password') {
      document.getElementById('Register__input--pwd').type = 'text';
    } else {
      document.getElementById('Register__input--pwd').type = 'password';
    }
  }
  async register() {
    //validation input is not empty
    let name = document.getElementById('Register__input--username').value.toLowerCase();
    let pwd = document.getElementById('Register__input--pwd').value;
    // if(name.length >= 3 && pwd.length >= 6){
    this.setState({
      prompt: '',
    })

    // if (name.length >= 5 && this.checkPassword(pwd) && this.checkUsername(name)) {
      this.setState({
        loginPress: true
      })
      this.props.store.isLoading = true;
      let self = this;
      axios.defaults.withCredentials = true;
      axios.post(config.server + '/api/user', {
        username: document.getElementById('Register__input--username').value.toLowerCase(),
        password: document.getElementById('Register__input--pwd').value,
        college: !this.state.codeShow ? document.getElementById('Register__input--college').value : this.state.codeNnm,
        identity: 1,
        googleId: self.props.store.googleId,
        geetest_challenge: this.state.geetest_challenge,
        geetest_validate: this.state.geetest_validate,
        geetest_seccode: this.state.geetest_seccode
      })
        .then(function (response) {
          if (response.data == 'OK') {
            window.alert('注册成功');
            self.props.store.isLoading = false;
            self.props.store.username = document.getElementById('Register__input--username').value;
            self.props.store.alias = self.props.store.username.slice(0, self.props.store.username.indexOf('@'));
            self.props.store.login = true;
            axios.get(`//login.cocorobo.cn/api/profile`)
              .then(function (res) {
                self.postMessageToParent('loginVerify', 'logged', res.data);
                self.props.store.isLoading = false;
                self.setState({
                  loginPress: false
                })
              })
              .catch(function (err) {
                console.log(err);
                self.props.store.isLoading = false;
                self.setState({
                  loginPress: false
                })
              })
          } else if (response.data.code == '11000') {
            document.querySelectorAll('.Register__prompt')[0].innerHTML = '用户名已注册';
            self.props.store.isLoading = false;
            self.setState({
              loginPress: false
            })
          } else {
            console.log(response);
            self.props.store.isLoading = false;
            self.setState({
              loginPress: false
            })
          }
        })
        .catch(function (error) {
          if (error.response.data == 'reCaptcha verify fails.') {
            self.setState({ geetest: true, prompt: '请点击验证' })
          }
          self.setState({
            loginPress: false
          })
        })
    // } else if (!this.checkUsername(name)) {
    //   document.querySelectorAll('.Register__prompt')[0].innerHTML = '请输入有效的邮箱地址';
    // } else if (name.length >= 3 && pwd.length < 6) {
    //   document.querySelectorAll('.Register__prompt')[0].innerHTML = '密码长度需大于6位';
    // } else if (name.length >= 3 && !this.checkPassword(pwd)) {
    //   document.querySelectorAll('.Register__prompt')[0].innerHTML = '密码种类需包含两种以上(数字及英文)';
    // }
  }
  checkUsername(str) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(str.toLowerCase());
  }
  checkPassword(str) {
    let reg = /^(?=.*\d)(?=.*[A-Za-z]).{6,}$/;
    return reg.test(str);
  }
  geetestRegister() {
    let self = this;
    axios.get(`${config.server}/geetest/pc-geetest/register`)
      .then((res) => {
        self.setState({
          challenge: res.data.challenge,
          gt: res.data.gt
        })
      })
      .catch((err) => {
        console.log(err);
      })
  }
  geetestValidate(isSuccess) {
    this.setState({
      geetest_challenge: isSuccess.geetest_challenge,
      geetest_validate: isSuccess.geetest_validate,
      geetest_seccode: isSuccess.geetest_seccode
    })
  }

  returnLogin() {
    window.location.href = "/login";
  }

  registers = () => {
    let params = {
      openid: window.openId,
      isregister: true,
      googleId: '',
      identity: 1,
      college: "",
    }
    let self = this
    axios.defaults.withCredentials = true;
    axios.post(`//login.cocorobo.cn/api/user`, qs.stringify(params)).then(res => {
      if (res.data === "OK") {
        axios.get(`//login.cocorobo.cn/api/profile`)
          .then(function (response) {
            self.props.store.username = response.data.username;
            self.props.store.alias = response.data.alias;
            self.props.store.login = true;
            self.postMessageToParent('loginVerify', 'logged', response.data);
            self.setState({
              loginPress: false
            })
          })
          .catch(function (error) {
            console.log(error);
          })
      }
    }).catch(err => {
    })
  }
  // 获取code
  getCode = () => {
    if (!this.state.codeTImeShow) {
      return;
    }
    let name = document.getElementById('Register__input--username').value.toLowerCase();
    let pwd = document.getElementById('Register__input--pwd').value;
    let params = {
      openid: window.openId,
      username: name,
      password: pwd,
      googleId: '',
      identity: 1,
      college: "",
    }
    axios.post(`//login.cocorobo.cn/api/user`, qs.stringify(params)).then(res => {
      console.log(res.data)
      if (res.data === 'OK') {
        window.alert('验证码已发送');
        this.setState({
          codeShow: false,
          codeTImeShow:false
        }, () => {
          this.time()
        })
      } else {
        window.alert('验证码发送失败，请检查邮箱和密码是否正确')
      }
    })
  }

  registerLOgin = () => {
    let params = {
      openid: window.openId,
      code: this.state.codeNnm,
      isregister: false
    }
    let _this = this;
    axios.defaults.withCredentials = true;
    axios.post("//login.cocorobo.cn/activate/emailverification", qs.stringify(params)).then(response => {
      if (response.data.active) {
        _this.props.store.username = response.data.username;
        _this.props.store.alias = response.data.alias;
        _this.props.store.login = true;
        _this.postMessageToParent('loginVerify', 'logged', response.data);
        _this.setState({
          loginPress: false
        })
      } else {
        alert('验证失败，请检查验证码输入是否正确')
      }
    })
  }
  // 获取code 点击倒计时
  time = () => {
    let times = setInterval(() => {
      if (this.state.codeTime === 0) {
        clearInterval(times)
        this.setState({
          codeTime: 60,
          codeTImeShow: true
        })
      } else {
        let codeTime = this.state.codeTime
        this.setState({
          codeTime: --codeTime
        })
      }
    }, 1000);
  }
  render() {
    const { codeShow, codeTime, codeTImeShow } = this.state
    if (this.props.store.login) {
      return <Redirect to='/success' />
    }
    return (
      <div className="Register">
        <div className='Register__account'>
          <span className='Register__title'><span style={{ color: 'red' }}>*</span>电子邮件</span>
          <input type='text' id='Register__input--username' className='Register__input' />
          <p className='Register__prompt'>{this.state.prompt}</p>
        </div>
        <div className='Register__pwd'>
          <span className='Register__title'><span style={{ color: 'red' }}>*</span>密码</span>
          <button className='Register__mask' onClick={this.toggleMask}></button>
          <input type='password' className='Register__input' id='Register__input--pwd' placeholder='至少6位数' pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" />
        </div>
        {codeShow ? <div>
          <div className='Register__college'>
            <span className='Register__title'>学校名称</span>
            <input type='text' className='Register__input' id='Register__input--college' />
          </div>
          {this.state.loginPress
            ?
            <div style={{ position: 'relative' }}>
              <button className='Register__button_click' onClick={this.register}>注册</button>
              <CircularProgress size={24} className="Register_loading" />
            </div>
            : <div style={{ position: 'relative' }}>
                <button className='Register__button' onClick={this.register}>注册</button>
                <button className='Return__button' onClick={this.returnLogin}>返回</button>
              </div>}

        </div> :
          <div>
            <div className='Register__college'>
              <span className='Register__title'>验证码</span>
              <input type='text' className='Register__input' id='Register__input--college' onChange={(e) => { this.setState({ codeNnm: e.target.value }) }} />
            </div>
            <button className='Register__buttons' onClick={this.getCode}>{codeTImeShow ? "获取验证码" : `获取验证码(${codeTime})`}</button>
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
              <button className='Register__buttons' onClick={this.registerLOgin}>邮箱绑定</button>
              <button className='Register__buttons' onClick={this.registers}>跳过验证</button>
            </div>
          </div>}

      </div>
    )
  }
}

export default Register;
