import React ,{Component} from 'react';
import '../assets/css/sidebar.css';

class Sidebar extends Component{
	render(){
		return(
			<div className='sidebar' id='sidebar'>
				<ul>
					<h4>文章分类</h4>
					<li className='sidebarListActive' id='educationService'>教学服务</li>
					<li className='sidebarList' id='onlinePlatform'>在线平台</li>
					<li className='sidebarList' id='caseStudy'>案例学习</li>
				</ul>
			</div>
		)
	}
}

export default Sidebar;
