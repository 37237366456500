// 机械
import mechanical1 from '../assets/img/一代/机械主题/3D打印机械爪.png'
import mechanical2 from '../assets/img/一代/机械主题/3D打印双足机器人.png'
import mechanical3 from '../assets/img/一代/机械主题/激光切割机械臂.png'
import mechanical4 from '../assets/img/一代/机械主题/激光切割机械爪.png'
import mechanical5 from '../assets/img/一代/机械主题/激光切割双足机器人.png'
// IOT 
import IoT1 from '../assets/img/一代/物联网主题/3D打印气象站.png';
import IoT2 from '../assets/img/一代/物联网主题/3D打印植物监测器.png';
import IoT3 from '../assets/img/一代/物联网主题/激光切割NB-IoT气象站（带屏幕）.png';
import IoT4 from '../assets/img/一代/物联网主题/激光切割NB-IoT气象站.png';
import IoT5 from '../assets/img/一代/物联网主题/激光切割简易版气象站.png';
import IoT6 from '../assets/img/一代/物联网主题/激光切割气象站.png';
import IoT7 from '../assets/img/一代/物联网主题/蓝牙防丢器.png';

// 动力
import power1 from '../assets/img/一代/动力主题/3D打印动力小车.png';
import power2 from '../assets/img/一代/动力主题/单电机双足机器人.png';
import power3 from '../assets/img/一代/动力主题/激光切割动力小车.png';
import power4 from '../assets/img/一代/动力主题/激光切割风火轮.png';
import power5 from '../assets/img/一代/动力主题/激光切割爬行机器人.png';
import power6 from '../assets/img/一代/动力主题/万花筒绘圆装置.png';
import power7 from '../assets/img/一代/动力主题/星球转动仪.png';
import power8 from '../assets/img/一代/动力主题/纸质卡通小车.png';
// 人机交互
import interaction1 from '../assets/img/一代/人机交互主题/互动手套.png';
import interaction2 from '../assets/img/一代/人机交互主题/激光切割手柄.png';
import interaction3 from '../assets/img/一代/人机交互主题/纸质游戏手柄.png';

// 音乐
import music1 from '../assets/img/一代/音乐主题/3D打印灯光电子琴.png';
import music2 from '../assets/img/一代/音乐主题/3D打印电子琴.png';
import music3 from '../assets/img/一代/音乐主题/激光切割电子鼓.png';
import music4 from '../assets/img/一代/音乐主题/激光切割尤克里里.png';
import music5 from '../assets/img/一代/音乐主题/纸模型电子琴.png';
// 灯光
import led1 from '../assets/img/一代/灯光主题/2×2灯屏.png';
import led2 from '../assets/img/一代/灯光主题/3D打印月球灯.png';
import led3 from '../assets/img/一代/灯光主题/激光切割百变灯台.png';
import led4 from '../assets/img/一代/灯光主题/激光切割触摸台灯.png';

// 木质
import wooden1 from '../assets/img/二代/木质结构主题/宝丽来相机.png';
import wooden2 from '../assets/img/二代/木质结构主题/垃圾分类装置侧面.png';
import wooden3 from '../assets/img/二代/木质结构主题/麦克纳姆轮机械臂车.png';
import wooden4 from '../assets/img/二代/木质结构主题/麦克纳姆轮机械车.png';
import wooden5 from '../assets/img/二代/木质结构主题/人脸识别双足机器人.png';
import wooden6 from '../assets/img/二代/木质结构主题/瓦力机器人.png';
import wooden7 from '../assets/img/二代/木质结构主题/音乐播放器.png';
import wooden8 from '../assets/img/二代/木质结构主题/云台追踪.png';
import wooden9 from '../assets/img/二代/木质结构主题/招财猫.png';
import wooden10 from '../assets/img/二代/木质结构主题/智能机械臂.png';
import wooden11 from '../assets/img/二代/木质结构主题/智能闸门.png';
import wooden12 from '../assets/img/二代/木质结构主题/自动驾驶遥控车.png';
// 金属
import metal1 from '../assets/img/二代/金属结构主题/双足机器人1-PNG.png';
import metal2 from '../assets/img/二代/金属结构主题/四足机器人-02-PNG.png';
import metal3 from '../assets/img/二代/金属结构主题/四足机器人加强版-PNG.png';
import metal4 from '../assets/img/二代/金属结构主题/物体追踪小车.png';
import metal5 from '../assets/img/二代/金属结构主题/新垃圾分类-PNG.png';
import metal6 from '../assets/img/二代/金属结构主题/新瓦力机器人-PNG.png';
import metal7 from '../assets/img/二代/金属结构主题/新智能机械臂-PNG.png';
import metal8 from '../assets/img/二代/金属结构主题/新智能闸门-PNG.png';
import metal9 from '../assets/img/二代/金属结构主题/智能宠物.png';

// 模块插画图片
import LED from '../assets/img/一代模块（插画）/LED灯屏模块.png';
import NBIoT from '../assets/img/一代模块（插画）/NBIoT通信模块.png';
import OLED from '../assets/img/一代模块（插画）/OLED灯屏模块.png';
import WiFi from '../assets/img/一代模块（插画）/Wi-Fi通信模块.png';
import TOUCH from '../assets/img/一代模块（插画）/触摸感应模块.png';
import MOTOR from '../assets/img/一代模块（插画）/电机驱动模块.png';
import GEAR from '../assets/img/一代模块（插画）/舵机转接模块.png';
import HUANJING from '../assets/img/一代模块（插画）/环境感应模块.png';
import TEACHING from '../assets/img/一代模块（插画）/教学模块.png';
import BLUE from '../assets/img/一代模块（插画）/蓝牙通信模块.png';
import TRANSFER1 from '../assets/img/一代模块（插画）/平行转接模块.png';
import COLOR from '../assets/img/一代模块（插画）/颜色分析模块.png';
import MUSIC from '../assets/img/一代模块（插画）/音乐模块.png';
import MOVEMENT from '../assets/img/一代模块（插画）/运动感应模块.png';
import TRANSFER2 from '../assets/img/一代模块（插画）/正反转接模块.png';
import MAIN from '../assets/img/一代模块（插画）/主控模块.png';
import TRANSFERA1 from '../assets/img/一代模块（插画）/转接模块A1.png';
import TRANSFERA2 from '../assets/img/一代模块（插画）/转接模块A2.png';
import TRANSFERB1 from '../assets/img/一代模块（插画）/转接模块B1.png';
import TRANSFERB2 from '../assets/img/一代模块（插画）/转接模块B2.png';
import DIANYUAN from '../assets/img/一代模块（插画）/电源管理模块.png';
import AI from '../assets/img/二代模块（插画斜角）/AI模块.png';
import IoT from '../assets/img/二代模块（插画斜角）/IoT模块.png';
import LED2 from '../assets/img/二代模块（插画斜角）/LED灯屏模块.png';
import MOTORTWO from '../assets/img/二代模块（插画斜角）/电机驱动模块.png';
import HANDLE from '../assets/img/二代模块（插画斜角）/控制手柄模块.png';
import EXTENSION from '../assets/img/二代模块（插画斜角）/扩展转接模块.png';
import SCREEN from '../assets/img/二代模块（插画斜角）/屏幕模块.png';
import CAMERA from '../assets/img/二代模块（插画斜角）/摄像头模块.png';


const demo = {
    one:[
        [// 机械
            {id:0,name:"3D打印机械爪",img:mechanical1,
            video:"https://cocorobo.cn/downloads/%E8%A7%86%E9%A2%91/3D%E6%89%93%E5%8D%B0%E6%9C%BA%E6%A2%B0%E7%88%AA.mp4",
            moduleImgs:[
                {name:"主控模块",img:MAIN,url:"https://help.cocorobo.cn/#/cocomod/main-controller"},
                {name:"舵机转接模块",img:GEAR,url:"https://help.cocorobo.cn/#/cocomod/servo"},
                {name:"教学模块",img:TEACHING,url:"https://help.cocorobo.cn/#/cocomod/sensor-101"}
            ],
            module:"主控模块,舵机转接模块,教学模块",
            content:"通过控制一个舵机,驱动三个机械爪臂张合,进行抓取物体"},
            {id:1,name:"3D打印双足机器人",img:mechanical2,
            video:"https://cocorobo.cn/downloads/%E8%A7%86%E9%A2%91/3D%E6%89%93%E5%8D%B0%E5%8F%8C%E8%B6%B3%E6%9C%BA%E5%99%A8%E4%BA%BA.mp4",
            moduleImgs:[
                {name:"主控模块",img:MAIN,url:"https://help.cocorobo.cn/#/cocomod/main-controller"},
                {name:"舵机转接模块",img:GEAR,url:"https://help.cocorobo.cn/#/cocomod/servo"},
                {name:"LED灯屏模块",img:LED,url:"https://help.cocorobo.cn/#/cocomod/led-matrix"},
                {name:"电源管理模块",img:DIANYUAN}
            ],
            module:"主控模块,舵机转接模块,电源管理模块,LED灯屏模块",
            content:"通过控制四个舵机,驱动双足机器人运动"},
            {id:2,name:"激光切割机械臂",img:mechanical3,
            moduleImgs:[
                {name:"主控模块",img:MAIN,url:"https://help.cocorobo.cn/#/cocomod/main-controller"},
                {name:"舵机转接模块",img:GEAR,url:"https://help.cocorobo.cn/#/cocomod/servo"},
                {name:"蓝牙通信模块",img:BLUE,url:"https://help.cocorobo.cn/#/cocomod/bluetooth"}
            ],
            module:"主控模块,舵机转接模块,蓝牙通信模块",
            content:"通过控制四个舵机,驱动机械臂关节运动,可进行物体抓取功能"},
            {id:3,name:"激光切割机械爪",img:mechanical4,
            moduleImgs:[
                {name:"主控模块",img:MAIN,url:"https://help.cocorobo.cn/#/cocomod/main-controller"},
                {name:"舵机转接模块",img:GEAR,url:"https://help.cocorobo.cn/#/cocomod/servo"},
                {name:"教学模块",img:TEACHING,url:"https://help.cocorobo.cn/#/cocomod/sensor-101"}
            ],
            module:"主控模块,舵机转接模块,教学模块",
            content:"通过控制一个舵机,驱动机械爪张合,进行抓取物体"},
            {id:4,name:"激光切割双足机器人",img:mechanical5,
            moduleImgs:[
                {name:"主控模块",img:MAIN,url:"https://help.cocorobo.cn/#/cocomod/main-controller"},
                {name:"舵机转接模块",img:GEAR,url:"https://help.cocorobo.cn/#/cocomod/servo"},
                {name:"LED灯屏模块",img:LED,url:"https://help.cocorobo.cn/#/cocomod/led-matrix"},
                {name:"电源管理模块",img:DIANYUAN}
            ],
            module:"主控模块,舵机转接模块,电源管理模块,LED灯屏模块",
            content:"通过控制四个舵机,驱动双足机器人运动"}
        ],
        [ // 物联网
            {id:5,name:"3D打印气象站",img:IoT1,
            moduleImgs:[
                {name:"主控模块",img:MAIN,url:"https://help.cocorobo.cn/#/cocomod/main-controller"},
                {name:"环境感应模块",img:HUANJING,url:"https://help.cocorobo.cn/#/cocomod/environment"},
                {name:"屏幕模块",img:OLED,url:"https://help.cocorobo.cn/#/cocomod/screen"},
                {name:"转接模块A2",img:TRANSFERA2,url:"https://help.cocorobo.cn/#/cocomod/hub"}
            ],
            module:"主控模块,环境感应模块,屏幕模块,转接模块A2",
            content:"通过环境模块可检测环境的温度,湿度,声音,光照强度,并在屏幕模块显示"},
            {id:6,name:"3D打印植物监测器",img:IoT2,
            moduleImgs:[
                {name:"主控模块",img:MAIN,url:"https://help.cocorobo.cn/#/cocomod/main-controller"},
                {name:"环境感应模块",img:HUANJING,url:"https://help.cocorobo.cn/#/cocomod/environment"},
                {name:"屏幕模块",img:OLED,url:"https://help.cocorobo.cn/#/cocomod/screen"},
                {name:"转接模块A2",img:TRANSFERA2,url:"https://help.cocorobo.cn/#/cocomod/hub"}
            ],
            module:"主控模块,环境感应模块,屏幕模块,转接模块A2",
            content:"通过土壤传感器接触土壤,可检测土壤的温度及湿度,并在屏幕模块显示"},
            {id:7,name:"激光切割NB-IoT气象站（带屏幕）",img:IoT3,
            moduleImgs:[
                {name:"主控模块",img:MAIN,url:"https://help.cocorobo.cn/#/cocomod/main-controller"},
                {name:"环境感应模块",img:HUANJING,url:"https://help.cocorobo.cn/#/cocomod/environment"},
                {name:"NBIOT通信模块",img:NBIoT,url:"https://help.cocorobo.cn/#/cocomod/nbiot"},
                {name:"正反转接模块",img:TRANSFER2,url:"https://help.cocorobo.cn/#/cocomod/reversed-adapter"},
                {name:"屏幕模块",img:OLED,url:"https://help.cocorobo.cn/#/cocomod/screen"}
            ],
            module:"主控模块,环境感应模块,NBIOT通信模块,正反转接模块,屏幕模块",
            content:"获取环境四组数据,并显示在屏幕上,通过NBIOT模块上传数据至对应的云端事件中,在上传前,要先更改云端事件的 API Key"},
            {id:8,name:"激光切割NB-IoT气象站",img:IoT4,
            video:'https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/93715dc9-e735-f642-3515-647d5b164eba1//%E7%BB%84%E8%A3%85NB-%20IOT%E6%B0%94%E8%B1%A1%E7%AB%99.mp4',
            moduleImgs:[
                {name:"主控模块",img:MAIN,url:"https://help.cocorobo.cn/#/cocomod/main-controller"},
                {name:"环境感应模块",img:HUANJING,url:"https://help.cocorobo.cn/#/cocomod/environment"},
                {name:"NBIOT通信模块",img:NBIoT,url:"https://help.cocorobo.cn/#/cocomod/nbiot"}
            ],
            module:"主控模块,环境感应模块,NBIOT通信模块",
            content:"获取环境四组数据,通过NBIOT模块上传资料至对应的云端事件中,上传前要先更改云端事件的 API Key"},
            {id:9,name:"激光切割简易版气象站",img:IoT5,
            video:'https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/102925dd-412e-d18b-3503-bfab95e8ef341//%E7%BB%84%E8%A3%85%E4%BA%91%E7%AB%AF%E6%A3%80%E6%B5%8B%E6%B0%94%E8%B1%A1%E7%AB%99.mp4',
            moduleImgs:[
                {name:"主控模块",img:MAIN,url:"https://help.cocorobo.cn/#/cocomod/main-controller"},
                {name:"环境感应模块",img:HUANJING,url:"https://help.cocorobo.cn/#/cocomod/environment"},
                {name:"Wi-Fi通信模块",img:WiFi,url:"https://help.cocorobo.cn/#/cocomod/wifi"}
            ],
            module:"主控模块,环境感应模块,Wi-Fi通信模块",
            content:"通过环境模块可检测环境的温度,湿度,声音,光照强度,通过Wi-Fi通信模块上传资料至对应的云端事件中,上传前要先更改云端事件的 API Key"},
            {id:10,name:"激光切割气象站",img:IoT6,
            video:'https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/2260ca58-6bf6-cf92-4411-b0081ce1d790/%E6%BF%80%E5%85%89%E5%88%87%E5%89%B2%E6%B0%94%E8%B1%A1%E7%AB%99_%E6%BF%80%E5%85%89%E5%88%87%E5%89%B2%E6%B0%94%E8%B1%A1%E7%AB%99%E7%9A%84%E5%88%B6%E4%BD%9C_%E6%93%8D%E4%BD%9C19-1_v0.1.mp4',
            moduleImgs:[
                {name:"主控模块",img:MAIN,url:"https://help.cocorobo.cn/#/cocomod/main-controller"},
                {name:"环境感应模块",img:HUANJING,url:"https://help.cocorobo.cn/#/cocomod/environment"},
                {name:"屏幕模块",img:OLED,url:"https://help.cocorobo.cn/#/cocomod/screen"},
                {name:"转接模块A2",img:TRANSFERA2,url:"https://help.cocorobo.cn/#/cocomod/hub"}
            ],
            module:"主控模块,环境感应模块,屏幕模块,转接模块A2",
            content:"通过环境模块可检测环境的温度,湿度,声音,光照强度,并在屏幕模块显示"},
            {id:11,name:"蓝牙防丢器",img:IoT7,
            moduleImgs:[
                {name:"主控模块",img:MAIN,url:"https://help.cocorobo.cn/#/cocomod/main-controller"},
                {name:"教学模块",img:TEACHING,url:"https://help.cocorobo.cn/#/cocomod/sensor-101"},
                {name:"电源管理模块",img:DIANYUAN},
                {name:"蓝牙通信模块",img:BLUE,url:"https://help.cocorobo.cn/#/cocomod/bluetooth"}
            ],
            module:"主控模块,教学模块,电源管理模块,蓝牙通信模块",
            content:"通过蓝牙连接,当超过一定距离,蜂鸣器则发出警报"}
        ],
        [// 动力
            {id:12,name:"3D打印动力小车",img:power1,
            moduleImgs:[
                {name:"主控模块",img:MAIN,url:"https://help.cocorobo.cn/#/cocomod/main-controller"},
                {name:"电机驱动模块",img:MOTOR,url:"https://help.cocorobo.cn/#/cocomod/motor-driver"}
            ],
            module:"主控模块,电机驱动模块",
            content:"通过控制电机,驱动小车前进后退,左右转向运动"},,
            {id:13,name:"单电机双足机器人",img:power2,
            moduleImgs:[
                {name:"主控模块",img:MAIN,url:"https://help.cocorobo.cn/#/cocomod/main-controller"},
                {name:"电机驱动模块",img:MOTOR,url:"https://help.cocorobo.cn/#/cocomod/motor-driver"}
            ],
            module:"主控模块,电机驱动模块",
            content:"通过单个电机驱动机器人行走"},
            {id:14,name:"激光切割动力小车",img:power3,
            video:'https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/28b17d25-6852-a807-20ca-b6e9093264e3/%E7%BB%84%E8%A3%85%E5%B0%8F%E8%BD%A6~1.mp4',
            moduleImgs:[
                {name:"主控模块",img:MAIN,url:"https://help.cocorobo.cn/#/cocomod/main-controller"},
                {name:"电机驱动模块",img:MOTOR,url:"https://help.cocorobo.cn/#/cocomod/motor-driver"},
                {name:"蓝牙通信模块",img:BLUE,url:"https://help.cocorobo.cn/#/cocomod/bluetooth"}
            ],
            module:"主控模块,电机驱动模块,蓝牙通信模块",
            content:"通过蓝牙控制电机,驱动小车前进后退,左右转向运动"},
            {id:15,name:"激光切割风火轮",img:power4,
            video:'https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/5ebb73fe-c197-9564-b234-9eacc423a528/%E9%A3%8E%E7%81%AB%E8%BD%AE_%E8%AF%BE%E7%A8%8B%E9%A2%84%E8%A7%88%E6%BC%94%E7%A4%BA%E8%A7%86%E9%A2%91.mp4',
            moduleImgs:[
                {name:"主控模块",img:MAIN,url:"https://help.cocorobo.cn/#/cocomod/main-controller"},
                {name:"电机驱动模块",img:MOTOR,url:"https://help.cocorobo.cn/#/cocomod/motor-driver"},
                {name:"蓝牙通信模块",img:BLUE,url:"https://help.cocorobo.cn/#/cocomod/bluetooth"}
            ],
            module:"主控模块,电机驱动模块,蓝牙通信模块",
            content:"通过控制电机,驱动小车前进后退,左右旋转运动"},
            {id:16,name:"激光切割爬行机器人",img:power5,
            moduleImgs:[
                {name:"主控模块",img:MAIN,url:"https://help.cocorobo.cn/#/cocomod/main-controller"},
                {name:"电机驱动模块",img:MOTOR,url:"https://help.cocorobo.cn/#/cocomod/motor-driver"}
            ],
            module:"主控模块,电机驱动模块",
            content:"通过单个电机驱动爬行机器人前进"},
            {id:17,name:"万花筒绘圆装置",img:power6,
            moduleImgs:[
                {name:"主控模块",img:MAIN,url:"https://help.cocorobo.cn/#/cocomod/main-controller"},
                {name:"电机驱动模块",img:MOTOR,url:"https://help.cocorobo.cn/#/cocomod/motor-driver"}
            ],
            module:"主控模块,电机驱动模块",
            content:"通过电机驱动转盘及摇杆,描绘万花筒图案还可通过扭转教学模块上的转盘调节电机速度画出不一样的图案"},
            {id:18,name:"星球转动仪",img:power7,
            video:"",
            moduleImgs:[
                {name:"主控模块",img:MAIN,url:"https://help.cocorobo.cn/#/cocomod/main-controller"},
                {name:"电机驱动模块",img:MOTOR,url:"https://help.cocorobo.cn/#/cocomod/motor-driver"}
            ],
            module:"主控模块,电机驱动模块",
            content:"模拟太阳,地球,月亮的转动关系"},
            {id:19,name:"纸质卡通小车",img:power8,
            video:'https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/3005fcfc-ab44-9389-2a16-10d0c275fed6/%E7%BB%84%E8%A3%85%E6%8A%98%E7%BA%B8%E5%B0%8F%E8%BD%A6~1.mp4',
            moduleImgs:[
                {name:"主控模块",img:MAIN,url:"https://help.cocorobo.cn/#/cocomod/main-controller"},
                {name:"电机驱动模块",img:MOTOR,url:"https://help.cocorobo.cn/#/cocomod/motor-driver"}
            ],
            module:"主控模块,电机驱动模块",
            content:"通过电机驱动,使小车行走"}
        ],
        [// 音乐
            {id:20,name:"3D打印灯光电子琴",img:music1,
            moduleImgs:[
                {name:"主控模块",img:MAIN,url:"https://help.cocorobo.cn/#/cocomod/main-controller"},
                {name:"触摸感应模块",img:TOUCH,url:"https://help.cocorobo.cn/#/cocomod/touch"},
                {name:"音乐模块",img:MUSIC,url:"https://help.cocorobo.cn/#/cocomod/music"},
                {name:"LED灯屏模块",img:LED,url:"https://help.cocorobo.cn/#/cocomod/led-matrix"}
            ],
            module:"主控模块,触摸感应模块,音乐模块,LED灯屏模块",
            content:"模拟电子琴,通过触摸感应模块连出来的导电贴纸,由音乐模块连接扬声器播放,播放同时LED会有不同灯光"},
            {id:21,name:"3D打印电子琴",img:music2,
            moduleImgs:[
                {name:"主控模块",img:MAIN,url:"https://help.cocorobo.cn/#/cocomod/main-controller"},
                {name:"触摸感应模块",img:TOUCH,url:"https://help.cocorobo.cn/#/cocomod/touch"},
                {name:"音乐模块",img:MUSIC,url:"https://help.cocorobo.cn/#/cocomod/music"}
            ],
            module:"主控模块,触摸感应模块,音乐模块",
            content:"模拟电子琴,通过触摸感应模块连出的导电贴纸,由音乐模块连接扬声器播放"},
            {id:22,name:"激光切割电子鼓",img:music3,
            moduleImgs:[
                {name:"主控模块",img:MAIN,url:"https://help.cocorobo.cn/#/cocomod/main-controller"},
                {name:"触摸感应模块",img:TOUCH,url:"https://help.cocorobo.cn/#/cocomod/touch"},
                {name:"音乐模块",img:MUSIC,url:"https://help.cocorobo.cn/#/cocomod/music"},
                {name:"LED灯屏模块",img:LED,url:"https://help.cocorobo.cn/#/cocomod/led-matrix"}
            ],
            module:"主控模块,触摸感应模块,音乐模块,LED灯屏模块",
            content:"模拟尤克里里,通过触摸感应模块连接对应的鼓器,由音乐模块连接扬声器播放,同时根据不同的鼓声,LED模块发出不同的灯光"},
            {id:23,name:"激光切割尤克里里",img:music4,
            video:'https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/9d8a9b24-d6fb-29a5-f21d-f0e49570ae1e/%E7%BB%84%E8%A3%85%E5%B0%A4%E5%85%8B%E9%87%8C%E9%87%8C.mp4',
            moduleImgs:[
                {name:"主控模块",img:MAIN,url:"https://help.cocorobo.cn/#/cocomod/main-controller"},
                {name:"触摸感应模块",img:TOUCH,url:"https://help.cocorobo.cn/#/cocomod/touch"},
                {name:"音乐模块",img:MUSIC,url:"https://help.cocorobo.cn/#/cocomod/music"},
                {name:"LED灯屏模块",img:LED,url:"https://help.cocorobo.cn/#/cocomod/led-matrix"}
            ],
            module:"主控模块,触摸感应模块,音乐模块,LED灯屏模块",
            content:"模拟尤克里里,通过触摸感应模块连出的导电源,由音乐模块连接扬声器播放"},
            {id:24,name:"纸模型电子琴",img:music5,
            video:'https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/19191044-b7ed-5ba9-2c9c-fd44d881311d/%E7%BB%84%E8%A3%85%E7%BA%B8%E6%A8%A1%E7%94%B5%E5%AD%90%E7%90%B4.mp4',
            moduleImgs:[
                {name:"主控模块",img:MAIN,url:"https://help.cocorobo.cn/#/cocomod/main-controller"},
                {name:"触摸感应模块",img:TOUCH,url:"https://help.cocorobo.cn/#/cocomod/touch"},
                {name:"音乐模块",img:MUSIC,url:"https://help.cocorobo.cn/#/cocomod/music"}
            ],
            module:"主控模块,触摸感应模块,音乐模块",
            content:"模拟电子琴,通过触摸感应模块连出的导电贴纸,由音乐模块连接扬声器播放"}
        ],
        [// 灯光
            {id:25,name:"2×2灯屏",img:led1,
            moduleImgs:[
                {name:"主控模块",img:MAIN,url:"https://help.cocorobo.cn/#/cocomod/main-controller"},
                {name:"转接模块A2",img:TRANSFERA2,url:"https://help.cocorobo.cn/#/cocomod/hub"},
                {name:"LED灯屏模块",img:LED,url:"https://help.cocorobo.cn/#/cocomod/led-matrix"}
            ],
            module:"主控模块,LED灯屏模块,转接模块A2",
            content:"面板由四块LED灯屏模块组成,可演示出不同效果的彩屏"},
            {id:26,name:"3D打印月球灯",img:led2,
            video:'https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/bd51b7be-96e0-08f7-ff2c-f2275922b9673//04P25.mp4',
            moduleImgs:[
                {name:"主控模块",img:MAIN,url:"https://help.cocorobo.cn/#/cocomod/main-controller"},
                {name:"教学模块",img:TEACHING,url:"https://help.cocorobo.cn/#/cocomod/sensor-101"},
                {name:"LED灯屏模块",img:LED,url:"https://help.cocorobo.cn/#/cocomod/led-matrix"}
            ],
            module:"主控模块,LED灯屏模块,教学模块",
            content:"可调节教学模块按键,LED灯屏发出不同颜色的灯光"},
            {id:27,name:"激光切割百变灯台",img:led3,
            video:'https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/fbfc9b2f-679e-a319-1c74-2d18a1880da32//%E7%BB%84%E8%A3%85%E6%BF%80%E5%85%89%E5%88%87%E5%89%B2%E5%8F%B0%E7%81%AF.mp4',
            moduleImgs:[
                {name:"主控模块",img:MAIN,url:"https://help.cocorobo.cn/#/cocomod/main-controller"},
                {name:"LED灯屏模块",img:LED,url:"https://help.cocorobo.cn/#/cocomod/led-matrix"}
            ],
            module:"主控模块,LED灯屏模块",
            content:"百变叶外形,LED灯屏发出光照"},
            {id:28,name:"激光切割触摸台灯",img:led4,
            video:"",
            moduleImgs:[
                {name:"主控模块",img:MAIN,url:"https://help.cocorobo.cn/#/cocomod/main-controller"},
                {name:"触摸感应模块",img:TOUCH,url:"https://help.cocorobo.cn/#/cocomod/touch"},
                {name:"LED灯屏模块",img:LED,url:"https://help.cocorobo.cn/#/cocomod/led-matrix"}
            ],
            module:"主控模块,LED灯屏模块,触摸感应模块",
            content:"由六个触摸控制键来调节台灯的不同亮度"}
        ],
        [// 人机交互
            {id:29,name:"互动手套",img:interaction1,
            moduleImgs:[
                {name:"主控模块",img:MAIN,url:"https://help.cocorobo.cn/#/cocomod/main-controller"},
                {name:"触摸感应模块",img:TOUCH,url:"https://help.cocorobo.cn/#/cocomod/touch"}
            ],
            module:"主控模块,触摸感应模块",
            content:"通过触摸模块上的触摸针脚,连接到各手指,可代替电脑上的按键进行操控"},
            {id:30,name:"激光切割手柄",img:interaction2,
            video:'https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/fbfa9282-092d-cd3c-78fa-5de6c68a4c261//%E6%89%8B%E6%9F%84%E5%88%B6%E4%BD%9C.mp4',
            moduleImgs:[
                {name:"主控模块",img:MAIN,url:"https://help.cocorobo.cn/#/cocomod/main-controller"},
                {name:"触摸感应模块",img:TOUCH,url:"https://help.cocorobo.cn/#/cocomod/touch"}
            ],
            module:"主控模块,触摸感应模块",
            content:"通过触摸模块上的触摸针脚,连接到激光切割手柄,可代替键盘上的按键进行操控"},
            {id:31,name:"纸质游戏手柄",img:interaction3,
            moduleImgs:[
                {name:"主控模块",img:MAIN,url:"https://help.cocorobo.cn/#/cocomod/main-controller"},
                {name:"触摸感应模块",img:TOUCH,url:"https://help.cocorobo.cn/#/cocomod/touch"}
            ],
            module:"主控模块,触摸感应模块",
            content:"通过触摸模块上的触摸针脚,连接到纸质游戏手柄,可代替键盘上的按键进行操控"}
        ],
    ],
    two:[
        [
            {id:32,name:"双足机器人",img:metal1,
            moduleImgs:[
                {name:"AI模块",img:AI,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8AI%E6%A8%A1%E5%9D%97"},
                {name:"LCD屏幕模块",img:SCREEN,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E5%B1%8F%E5%B9%95%E6%A8%A1%E5%9D%97"},
                {name:"扩展转接模块",img:EXTENSION,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E6%89%A9%E5%B1%95%E8%BD%AC%E6%8E%A5%E6%A8%A1%E5%9D%97"}
            ],
            module:"AI模块,LCD屏幕模块,扩展转接模块",
            content:"<span class='contentTitle'>语音功能：</span>通过语音指令控制机器人四个舵机,操控双足机器人做出对应的动作"},
            {id:33,name:"四足机器人",img:metal2,
            moduleImgs:[
                {name:"AI模块",img:AI,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8AI%E6%A8%A1%E5%9D%97"},
                {name:"LCD屏幕模块",img:SCREEN,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E5%B1%8F%E5%B9%95%E6%A8%A1%E5%9D%97"},
                {name:"摄像头模块",img:CAMERA,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E6%91%84%E5%83%8F%E5%A4%B4"},
                {name:"扩展转接模块",img:EXTENSION,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E6%89%A9%E5%B1%95%E8%BD%AC%E6%8E%A5%E6%A8%A1%E5%9D%97"}
            ],
            module:"AI模块,LCD屏幕模块,扩展转接模块,摄像头模块",content:"<span class='contentTitle'>颜色识别功能：</span>通过摄像头识别方块的颜色,四足机器人分析不同颜色做不同的动作</br><span class='contentTitle'>语音识别功能：</span>通过语音指令控制机器人八个舵机,操控四足机器人做出对应的动作"},
            {id:34,name:"四足机器人加强版",img:metal3,
            moduleImgs:[
                {name:"AI模块",img:AI,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8AI%E6%A8%A1%E5%9D%97"},
                {name:"LCD屏幕模块",img:SCREEN,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E5%B1%8F%E5%B9%95%E6%A8%A1%E5%9D%97"},
                {name:"摄像头模块",img:CAMERA,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E6%91%84%E5%83%8F%E5%A4%B4"},
                {name:"扩展转接模块",img:EXTENSION,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E6%89%A9%E5%B1%95%E8%BD%AC%E6%8E%A5%E6%A8%A1%E5%9D%97"}
            ],
            module:"AI模块,LCD屏幕模块,扩展转接模块,摄像头模块",
            content:"<span class='contentTitle'>颜色识别功能：</span>通过摄像头识别方块的颜色,四足机器人分析不同颜色做不同的动作</br><span class='contentTitle'>语音识别功能：</span>通过语音指令控制机器人八个舵机,操控四足机器人做出对应的动作"},
            {id:35,name:"金属结构麦轮车",img:metal4,
            moduleImgs:[
                {name:"AI模块",img:AI,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8AI%E6%A8%A1%E5%9D%97"},
                {name:"LCD屏幕模块",img:SCREEN,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E5%B1%8F%E5%B9%95%E6%A8%A1%E5%9D%97"},
                {name:"摄像头模块",img:CAMERA,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E6%91%84%E5%83%8F%E5%A4%B4"},
                {name:"电机驱动模块",img:MOTORTWO,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E7%94%B5%E6%9C%BA%E9%A9%B1%E5%8A%A8%E6%A8%A1%E5%9D%97"}
            ],
            module:"AI模块,LCD屏幕模块,电机驱动模块,摄像头模块",
            content:"<span class='contentTitle'>物体跟踪功能：</span>基于模型训练,通过摄像头识别到物体,并自动跟随物体"},
            {id:36,name:"垃圾分类",img:metal5,
            moduleImgs:[
                {name:"AI模块",img:AI,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8AI%E6%A8%A1%E5%9D%97"},
                {name:"LCD屏幕模块",img:SCREEN,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E5%B1%8F%E5%B9%95%E6%A8%A1%E5%9D%97"},
                {name:"摄像头模块",img:CAMERA,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E6%91%84%E5%83%8F%E5%A4%B4"},
                {name:"扩展转接模块",img:EXTENSION,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E6%89%A9%E5%B1%95%E8%BD%AC%E6%8E%A5%E6%A8%A1%E5%9D%97"}
            ],
            module:"AI模块,LCD屏幕模块,扩展转接模块,摄像头模块",
            content:"<span class='contentTitle'>物体识别功能：</span>基于模型训练,通过摄像头识别到物体,分析该物体类别,自动打开对应的垃圾箱"},
            {id:37,name:"瓦力机器人",img:metal6,
            moduleImgs:[
                {name:"AI模块",img:AI,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8AI%E6%A8%A1%E5%9D%97"},
                {name:"LCD屏幕模块",img:SCREEN,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E5%B1%8F%E5%B9%95%E6%A8%A1%E5%9D%97"},
                {name:"电机驱动模块",img:MOTORTWO,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E7%94%B5%E6%9C%BA%E9%A9%B1%E5%8A%A8%E6%A8%A1%E5%9D%97"},
                {name:"扩展转接模块",img:EXTENSION,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E6%89%A9%E5%B1%95%E8%BD%AC%E6%8E%A5%E6%A8%A1%E5%9D%97"}
            ],
            module:"AI模块,LCD屏幕模块,扩展转接模块,电机驱动模块",
            content:"<span class='contentTitle'>语音功能：</span>通过语音指令控制机器人,操控瓦力机器人做出对应的动作"},
            {id:38,name:"智能机械臂",img:metal7,
            moduleImgs:[
                {name:"AI模块",img:AI,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8AI%E6%A8%A1%E5%9D%97"},
                {name:"LCD屏幕模块",img:SCREEN,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E5%B1%8F%E5%B9%95%E6%A8%A1%E5%9D%97"},
                {name:"摄像头模块",img:CAMERA,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E6%91%84%E5%83%8F%E5%A4%B4"},
                {name:"扩展转接模块",img:EXTENSION,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E6%89%A9%E5%B1%95%E8%BD%AC%E6%8E%A5%E6%A8%A1%E5%9D%97"}
            ],
            module:"AI模块,LCD屏幕模块,扩展转接模块,摄像头模块",
            content:"<span class='contentTitle'>颜色识别功能：</span>通过摄像头识别方块的颜色,机械臂进行抓取方块并将不同颜色的方块放置不同的位置"},
            {id:39,name:"智能闸门",img:metal8,
            moduleImgs:[
                {name:"AI模块",img:AI,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8AI%E6%A8%A1%E5%9D%97"},
                {name:"LCD屏幕模块",img:SCREEN,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E5%B1%8F%E5%B9%95%E6%A8%A1%E5%9D%97"},
                {name:"摄像头模块",img:CAMERA,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E6%91%84%E5%83%8F%E5%A4%B4"},
                {name:"扩展转接模块",img:EXTENSION,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E6%89%A9%E5%B1%95%E8%BD%AC%E6%8E%A5%E6%A8%A1%E5%9D%97"}
            ],
            module:"AI模块,LCD屏幕模块,扩展转接模块,摄像头模块",
            content:"<span class='contentTitle'>人脸识别功能：</span>通过摄像头拍摄到人脸,自动识别人脸特征并打开门"},
            {id:40,name:"机械狗",img:metal9,
            moduleImgs:[
                {name:"AI模块",img:AI,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8AI%E6%A8%A1%E5%9D%97"},
                {name:"LCD屏幕模块",img:SCREEN,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E5%B1%8F%E5%B9%95%E6%A8%A1%E5%9D%97"},
                {name:"摄像头模块",img:CAMERA,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E6%91%84%E5%83%8F%E5%A4%B4"}
            ],
            module:"AI模块,LCD屏幕模块,摄像头模块",
            content:"<span class='contentTitle'>语音识别功能：</span>通过语音指令控制机器狗,操控机械狗做出对应的动作</br><span class='contentTitle'>蓝牙控制功能：</span>通过蓝牙通讯控制机器狗,操控机械狗做出对应的动作"}
        ],
        [
            {id:41,name:"宝丽来相机",img:wooden1,
            moduleImgs:[
                {name:"AI模块",img:AI,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8AI%E6%A8%A1%E5%9D%97"},
                {name:"LCD屏幕模块",img:SCREEN,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E5%B1%8F%E5%B9%95%E6%A8%A1%E5%9D%97"},
                {name:"摄像头模块",img:CAMERA,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E6%91%84%E5%83%8F%E5%A4%B4"}
            ],
            module:"AI模块,LCD屏幕模块,摄像头模块",
            content:"<span class='contentTitle'>相机功能：</span>通过控制AI模块按键,实现拍照并存储功能"},
            {id:42,name:"垃圾分类",img:wooden2,
            moduleImgs:[
                {name:"AI模块",img:AI,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8AI%E6%A8%A1%E5%9D%97"},
                {name:"LCD屏幕模块",img:SCREEN,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E5%B1%8F%E5%B9%95%E6%A8%A1%E5%9D%97"},
                {name:"摄像头模块",img:CAMERA,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E6%91%84%E5%83%8F%E5%A4%B4"},
                {name:"扩展转接模块",img:EXTENSION,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E6%89%A9%E5%B1%95%E8%BD%AC%E6%8E%A5%E6%A8%A1%E5%9D%97"}
            ],
            module:"AI模块,LCD屏幕模块,扩展转接模块,摄像头模块",
            content:"<span class='contentTitle'>物体识别功能：</span>基于模型训练,通过摄像头识别到物体,分析该物体类别,自动打开对应的垃圾箱"},
            {id:43,name:"木制麦轮小车",img:wooden4,
            moduleImgs:[
                {name:"AI模块",img:AI,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8AI%E6%A8%A1%E5%9D%97"},
                {name:"LCD屏幕模块",img:SCREEN,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E5%B1%8F%E5%B9%95%E6%A8%A1%E5%9D%97"},
                {name:"摄像头模块",img:CAMERA,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E6%91%84%E5%83%8F%E5%A4%B4"},
                {name:"电机驱动模块",img:MOTORTWO,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E7%94%B5%E6%9C%BA%E9%A9%B1%E5%8A%A8%E6%A8%A1%E5%9D%97"}
            ],
            module:"AI模块,LCD屏幕模块,电机驱动模块,摄像头模块",
            content:"<span class='contentTitle'>物体跟踪功能：</span>基于模型训练,通过摄像头识别到物体,并自动跟随物体"},
            {id:44,name:"木制麦轮机械臂小车",img:wooden3,
            moduleImgs:[
                {name:"AI模块",img:AI,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8AI%E6%A8%A1%E5%9D%97"},
                {name:"LCD屏幕模块",img:SCREEN,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E5%B1%8F%E5%B9%95%E6%A8%A1%E5%9D%97"},
                {name:"摄像头模块",img:CAMERA,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E6%91%84%E5%83%8F%E5%A4%B4"},
                {name:"电机驱动模块",img:MOTORTWO,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E7%94%B5%E6%9C%BA%E9%A9%B1%E5%8A%A8%E6%A8%A1%E5%9D%97"},
                {name:"扩展转接模块",img:EXTENSION,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E6%89%A9%E5%B1%95%E8%BD%AC%E6%8E%A5%E6%A8%A1%E5%9D%97"}
            ],
            module:"AI模块,LCD屏幕模块,电机驱动模块,摄像头模块,扩展转接模块",
            content:"<span class='contentTitle'>物体识别功能：</span>基于模型训练,通过摄像头识别到物体,自动跟随物体并抓取"},
            {id:45,name:"双足机器人",img:wooden5,
            moduleImgs:[
                {name:"AI模块",img:AI,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8AI%E6%A8%A1%E5%9D%97"},
                {name:"LCD屏幕模块",img:SCREEN,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E5%B1%8F%E5%B9%95%E6%A8%A1%E5%9D%97"},
                {name:"扩展转接模块",img:EXTENSION,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E6%89%A9%E5%B1%95%E8%BD%AC%E6%8E%A5%E6%A8%A1%E5%9D%97"}
            ],
            module:"AI模块,LCD屏幕模块,扩展转接模块",
            content:"<span class='contentTitle'>语音功能：</span>通过语音指令控制机器人四个舵机,操控双足机器人做出对应的动作"},
            {id:46,name:"瓦力机器人",img:wooden6,
            moduleImgs:[
                {name:"AI模块",img:AI,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8AI%E6%A8%A1%E5%9D%97"},
                {name:"LCD屏幕模块",img:SCREEN,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E5%B1%8F%E5%B9%95%E6%A8%A1%E5%9D%97"},
                {name:"扩展转接模块",img:EXTENSION,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E6%89%A9%E5%B1%95%E8%BD%AC%E6%8E%A5%E6%A8%A1%E5%9D%97"},
                {name:"电机驱动模块",img:MOTORTWO,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E7%94%B5%E6%9C%BA%E9%A9%B1%E5%8A%A8%E6%A8%A1%E5%9D%97"}
            ],
            module:"AI模块,LCD屏幕模块,扩展转接模块,电机驱动模块",
            content:"<span class='contentTitle'>语音功能：</span>通过语音指令控制机器人,操控瓦力机器人做出对应的动作"},
            {id:47,name:"音乐播放器",img:wooden7,
            moduleImgs:[
                {name:"AI模块",img:AI,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8AI%E6%A8%A1%E5%9D%97"},
                {name:"LCD屏幕模块",img:SCREEN,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E5%B1%8F%E5%B9%95%E6%A8%A1%E5%9D%97"},
                {name:"摄像头模块",img:CAMERA,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E6%91%84%E5%83%8F%E5%A4%B4"}
            ],
            module:"AI模块,LCD屏幕模块,摄像头模块",
            content:"<span class='contentTitle'>音频分析功能：</span>通过分析环境声音大小,LCD屏幕呈现模拟声音大小光环"},
            {id:48,name:"云台追踪",img:wooden8,
            moduleImgs:[
                {name:"AI模块",img:AI,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8AI%E6%A8%A1%E5%9D%97"},
                {name:"LCD屏幕模块",img:SCREEN,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E5%B1%8F%E5%B9%95%E6%A8%A1%E5%9D%97"},
                {name:"摄像头模块",img:CAMERA,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E6%91%84%E5%83%8F%E5%A4%B4"},
                {name:"扩展转接模块",img:EXTENSION,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E6%89%A9%E5%B1%95%E8%BD%AC%E6%8E%A5%E6%A8%A1%E5%9D%97"}
            ],
            module:"AI模块,LCD屏幕模块,扩展转接模块,摄像头模块",
            content:"<span class='contentTitle'>人脸识别功能：</span>通过摄像头拍摄到人脸,自动识别人脸特征并追踪人脸运动"},
            {id:49,name:"招财猫",img:wooden9,
            moduleImgs:[
                {name:"AI模块",img:AI,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8AI%E6%A8%A1%E5%9D%97"},
                {name:"LCD屏幕模块",img:SCREEN,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E5%B1%8F%E5%B9%95%E6%A8%A1%E5%9D%97"},
                {name:"摄像头模块",img:CAMERA,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E6%91%84%E5%83%8F%E5%A4%B4"},
                {name:"扩展转接模块",img:EXTENSION,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E6%89%A9%E5%B1%95%E8%BD%AC%E6%8E%A5%E6%A8%A1%E5%9D%97"}
            ],
            module:"AI模块,LCD屏幕模块,扩展转接模块,摄像头模块",
            content:"<span class='contentTitle'>人脸识别功能：</span>通过摄像头拍摄到人脸,自动识别人脸特征并自动招手"},
            {id:50,name:"智能机械臂",img:wooden10,
            moduleImgs:[
                {name:"AI模块",img:AI,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8AI%E6%A8%A1%E5%9D%97"},
                {name:"LCD屏幕模块",img:SCREEN,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E5%B1%8F%E5%B9%95%E6%A8%A1%E5%9D%97"},
                {name:"摄像头模块",img:CAMERA,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E6%91%84%E5%83%8F%E5%A4%B4"},
                {name:"扩展转接模块",img:EXTENSION,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E6%89%A9%E5%B1%95%E8%BD%AC%E6%8E%A5%E6%A8%A1%E5%9D%97"}
            ],
            module:"AI模块,LCD屏幕模块,扩展转接模块,摄像头模块",
            content:"<span class='contentTitle'>颜色识别功能：</span>通过摄像头识别方块的颜色,机械臂进行抓取方块并将不同颜色的方块放置不同的位置"},
            {id:51,name:"智能闸门",img:wooden11,
            moduleImgs:[
                {name:"AI模块",img:AI,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8AI%E6%A8%A1%E5%9D%97"},
                {name:"LCD屏幕模块",img:SCREEN,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E5%B1%8F%E5%B9%95%E6%A8%A1%E5%9D%97"},
                {name:"摄像头模块",img:CAMERA,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E6%91%84%E5%83%8F%E5%A4%B4"},
                {name:"扩展转接模块",img:EXTENSION,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E6%89%A9%E5%B1%95%E8%BD%AC%E6%8E%A5%E6%A8%A1%E5%9D%97"}
            ],
            module:"AI模块,LCD屏幕模块,扩展转接模块,摄像头模块",
            content:"<span class='contentTitle'>人脸识别功能：</span>通过摄像头拍摄到人脸,自动识别人脸特征并打开门"},
            {id:52,name:"自动驾驶遥控车",img:wooden12,
            moduleImgs:[
                {name:"AI模块",img:AI,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8AI%E6%A8%A1%E5%9D%97"},
                {name:"LCD屏幕模块",img:SCREEN,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E5%B1%8F%E5%B9%95%E6%A8%A1%E5%9D%97"},
                {name:"摄像头模块",img:CAMERA,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E6%91%84%E5%83%8F%E5%A4%B4"},
                {name:"电机驱动模块",img:MOTORTWO,url:"https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E7%94%B5%E6%9C%BA%E9%A9%B1%E5%8A%A8%E6%A8%A1%E5%9D%97"}
            ],
            module:"AI模块,LCD屏幕模块,电机驱动模块,摄像头模块",
            content:"<span class='contentTitle'>物体识别功能：</span>基于模型训练,通过摄像头识别到路标,并执行路标的指示运动"}
        ]
    ]
}

export default demo
