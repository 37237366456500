import React from 'react'
import PropTypes from 'prop-types'

class Image extends React.Component {
  render () {
    return(
      <img src={this.props.src} alt={this.props.src} width='100%' /> 
    )
  }
}

export default Image;
