import basicKit__light from '../assets/img/basicKit__light.jpg'
import basicKit__IoT from '../assets/img/basicKit__IoT.jpg'
import basicKit__mechanism from '../assets/img/basicKit__mechanism.jpg'
import basicKit__power from '../assets/img/basicKit__power.jpg'
import basicKit__music from '../assets/img/basicKit__music.jpg'
import basicKit__HCI from '../assets/img/basicKit__HCI.jpg'

//更改记录
//Feb 12 2019  Source: Dongting

const basicKit ={
	0:{
		img:basicKit__light,
		name:'light',
		nameCN:'灯光 Basic Kit',
		extension:'扩展包：百变台灯制作',
		price:200,
	},
	1:{
		img:basicKit__IoT,
		name:'IoT',
		nameCN:'物联网Basic Kit',
		extension:'扩展包：迷你气象站制作',
		price:240
	},
	2:{
    img:basicKit__mechanism,
		name:'mechanism',
		nameCN:'机械 Basic Kit',
		extension:'扩展包：激光切割机械臂制作',
		price:180
	},
	3:{
	  img:basicKit__power,
		name:'power',
		nameCN:'动力 Basic Kit',
		extension:'扩展包：激光切割风火轮制作',
		price:180
	},
	4:{
	  img:basicKit__music,
		name:'music',
		nameCN:'音乐Basic Kit',
		extension:'扩展包：尤克里里制作',
		price:220,
	},
	5:{
    img:basicKit__HCI,
		name:'HCI',
		nameCN:'人机交互 Basic Kit',
		extension:'扩展包：游戏手柄制作',
		price:200
	}
}

export default basicKit;
