import React from 'react'
import PropTypes from 'prop-types'
import Competition from '../components/competition'
import Workshop from '../components/workshop'

class Event extends React.Component {
  render () {
    return(
      <div className="Event">
        <Competition/>
        <Workshop/>
      </div>
    )
  }
}

export default Event;
