import React , {Component} from 'react';
import TitleMain from '../components/titleMain';
import officePic from '../assets/img/officePic.jpg';
import '../assets/css/office.css';
import Button from '../components/button';
//plugin
import scrollToComponent from 'react-scroll-to-component';

const bgColor = {
	backgroundColor:'#000',
}
class About extends Component{
	componentDidMount(){
		scrollToComponent(this.Container, { offset: -150, align: 'top', duration: 500, ease:'inCirc'});
	}
	render(){
		return(
			<div ref={(container)=>{this.Container = container}}>
				<TitleMain name='关于我们' />
				<div className='office'>
					<div className='officePic'>
						<img src={officePic} alt='officePic'/>
					</div>
					<div className='desc'>
						<p><b>可可乐博（CocoRobo）</b>于2016年4月在香港成立，
						是一家专注于<b>STEAM教育（科技，技术，工程，艺术及数学）</b>
						的教育科技公司。我们的使命是通过灵感和科技的有机结合
						，将最新的理念和技术带到STEAM教育领域，目前在香港和内地服务过近120家学校，
						知名学校包括弘立书院，英华书院，圣公会邓肇坚中学等，并与香港中文大学教育学院、
						工程学院保持长期深度的合作。<br></br><br></br>团队于2017年1月荣获香港政府大学科技创业项目资助50万港币;
						7月入选香港科学园科技孵化器，并得到18万港币资助及场地赞助；9月和10月先后在深圳创新创业
						大赛和宝安创新创业大赛中取得优胜奖和一等奖，并获得包括中国日报，大公报，明报等在内的香港
						及内地媒体的专访。</p>
					</div>
					<a href='http://cocorobo.hk/hr/'>
						<Button name='加入我们' style={{backgroundColor:'#000'}}/>
					</a>

				</div>
			</div>
		)
	}
}

export default About;
