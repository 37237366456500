import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import TitleMain from '../components/titleMain';
import Demo from '../components/demo';
import TwoDemo from '../components/twoDemo';
import Button from '../components/button';
import FancyBtn from '../components/fancyBtn';
import Combination from  '../components/combination';
import TwoCombination from '../components/twocombination'
import Printer from '../components/printer';
import CocoKit from '../components/cocokit';
import Consumables from '../components/consumables';
// plugin
import scrollToComponent from 'react-scroll-to-component';
import {Link} from 'react-router-dom';
// import ScrollableAnchor ,{goToAnchor}from 'react-scrollable-anchor';

class Product extends React.Component {
  constructor(props){
    super(props);
    this.state={
      combinationActive:true,
      twocombinationActive:true,
    }
    this.displayCombination = this.displayCombination.bind(this);
    this.scrollToCombination = this.scrollToCombination.bind(this);
    this.scrollTo = this.scrollTo.bind(this);
  }
  componentDidMount(){
    document.getElementById('scrollToCombination').addEventListener('click',this.displayCombination);
    let anchors = document.querySelectorAll('.Nav__anchor');
    Object.keys(anchors).map(x=>anchors[x].addEventListener('click',this.scrollTo));
    if(window.location.href.indexOf('#') == -1){
      if(window.location.href.indexOf('product/') !== -1){
        let clickedAnchor = window.location.href.slice(window.location.href.lastIndexOf('/')+1);
        let clickedAnchorPos=ReactDOM.findDOMNode(this[clickedAnchor]).getBoundingClientRect();
        window.scrollTo(0,clickedAnchorPos.y);
      }
    }else{
      let clickedAnchor = window.location.href.slice(window.location.href.lastIndexOf('/')+1);
      let clickedAnchorPos = document.getElementById(clickedAnchor).getBoundingClientRect();
      window.scrollTo(0,clickedAnchorPos.y);
    }

    // scrollToComponent(this[clickedAnchor],{offset: 0, align: 'top', duration: 300, ease:'inCirc'})
  }
  displayCombination(){
    this.setState({
      combinationActive:!this.state.combinationActive,
    })
    this.scrollToCombination();
  }
  scrollToCombination(){
    scrollToComponent(this.Combination, { offset: 0, align: 'top', duration: 300, ease:'inCirc'});
  }
  scrollTo(event){
    scrollToComponent(this[event.target.id], { offset: 0, align: 'top', duration: 300, ease:'inCirc'});
  }
  TwoScroll=()=>{
    this.setState({
      twocombinationActive:!this.state.twocombinationActive
    })
  }

  render () {
    return(
      <div className='product__container'>
        <Demo ref={(section)=>{this.cocomod = section}}/>
        <Button name='去搭配看看' id='scrollToCombination' style={{fontSize:20}}/>
        {!this.state.combinationActive && <Combination ref={(section) => { this.Combination = section; }}/>}
        <TwoDemo></TwoDemo>
        <Button name='去搭配看看' onClick={this.TwoScroll} style={{fontSize:20}}/>
        {!this.state.twocombinationActive && <TwoCombination />}
        <CocoKit ref={(section) => { this.cocokit = section; }}/>
        {/*<Printer ref={(section) => { this.printer = section; }}/>
        <Consumables ref={(section) => { this.consumables = section; }}/>*/}
        <a href='//edu.cocorobo.cn'>
          <FancyBtn name='查看课程'/>
        </a>
      </div>
    )
  }
}

export default Product;