import React , {Component} from 'react';
import Scroll from './scroll';
import '../assets/css/PCS.css';
import homepage_product_scroll from '../assets/img/homepage_product_scroll.jpg';
import homepage_curriculum_scroll from '../assets/img/homepage_curriculum_scroll.png';
import homepage_service_scroll from '../assets/img/homepage_service_scroll.jpg';
import homepage_product_banner from '../assets/img/homepage_product_banner.jpg';
import homepage_curriculum_banner from '../assets/img/homepage_curriculum_banner.jpg';
import homepage_service_banner from '../assets/img/homepage_service_banner.jpg';





const pcs= {
	product:{
		title:'产品',
		content:'CocoMod 是一系列由 CocoRobo 研发的「即插即用式」的电子模块。目前已经研发了16款，其中包括主板模块、环境感应模块、音乐模块、触摸感应模块、灯光模块、Wi-Fi通信模块、电机控制模块等。',
		img:homepage_product_scroll,
		bgImg:homepage_product_banner,
	},
	course:{
		title:'课程',
		content:'我们的课程体系融合了3D打印技、配合CocoRobo自主研发的电子模块和图形化的编程界面，学生可以从八大主题出发：灯光、机械、音乐、科学实验、可穿戴、IoT、人工智能、增强现实，从而制作出千变万化的机器人和智能设备。',
		img:homepage_curriculum_scroll,
		bgImg:homepage_curriculum_banner,
	},
	service:{
		title:'服务',
		content:'我们提供教学服务，内容包括「课程定制方案」、「教师培训」、「到校服务」。',
		img:homepage_service_scroll,
		bgImg:homepage_service_banner,
	},
};
class PCS extends Component{
	constructor(props){
		super(props);
		this.state={
			content:''
		}
	}
	toggle(event){
		if(event.target.className === 'toggle'){
			let getToggleButton = event.target;
			let getScroll = event.target.parentNode;
			let getImg = event.target.parentNode.firstChild.firstChild;
			let getContent = event.target.previousSibling.previousSibling;
			let getDesc = event.target.previousSibling;
			let getImgHeight = getImg.offsetHeight;
			let mediaSize = window.innerWidth > 639 ? 'medium' : 'small';
			let getScrollObject = document.querySelectorAll('.scroll');


			if(event.target.getAttribute('expand') === 'false'){
				getToggleButton.setAttribute('expand','true');
				getScroll.setAttribute('state','opened');
				Object.keys(getScrollObject).forEach(function(scroll){
					if(getScrollObject[scroll].getAttribute('state') === 'closed'){
						getScrollObject[scroll].style.width ='calc(0%)';
						getScrollObject[scroll].style.opacity = '0';
						window.setTimeout(()=>{getScrollObject[scroll].style.display = 'none';},1000);
						}
					}
				);
				getImg.style.height = getImgHeight+'px';  //set static img height
				getScroll.style.width = '100%'; //expand scroll canva
				getContent.style.display = 'block'; //content show
				getDesc.style.display = 'block';
				getToggleButton.style.transform = 'rotate(45deg)'; //rotate button
				window.setTimeout(function(){
					Object.keys(getScrollObject).forEach(function(scroll){
						if(getScrollObject[scroll].getAttribute('state') === 'closed'){
							getScrollObject[scroll].firstChild.firstChild.style.height = getImgHeight + 'px';
							getScrollObject[scroll].style.order = '2';
							getScrollObject[scroll].style.display = 'block';
							getScrollObject[scroll].style.width = mediaSize === 'medium'? 'calc(90%/2)':'100%' ;
							getScrollObject[scroll].style.opacity = '1';
							}
						}
					);
				},2000);
			}else if(event.target.getAttribute('expand') === 'true'){
				getToggleButton.setAttribute('expand','false');
				getScroll.setAttribute('state','closed');
				getScroll.style.width = 'calc(90%/3)'; //expand scroll canva
				getDesc.style.display = 'none';
				getContent.style.display = 'none'; //content show
				getToggleButton.style.transform = 'rotate(0deg)'; //rotate button
				Object.keys(getScrollObject).forEach(function(scroll){
					getScrollObject[scroll].style.order = 'initial';
					if(getScrollObject[scroll].getAttribute('state') === 'closed'){
						getScrollObject[scroll].style.width = mediaSize === 'medium'?'calc(90%/3)':'100%';
						getScrollObject[scroll].style.opacity = '1';
						}
					}
				);

			}
		}
	}
	render(){
		return(
			<div className='PCS' onClick={this.toggle.bind(this)}>
				{Object.keys(pcs).map((x,y) =>(
					<Scroll
						key={x+y}
						bgImg={pcs[x].bgImg}
						title={pcs[x].title}
						content={pcs[x].content}
					 	img={pcs[x].img}
				 	/>
			 ))}
			</div>
		)
	}
}

export default PCS;
