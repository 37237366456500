import React ,{Component} from  'react';

export default class QA extends Component{
	render(){
		return(
				<div>
					<h5 className='question' aria-pressed='false'>{this.props.question}</h5>
					<p className='answer'>{this.props.answer}</p>
				</div>
			)
	}
}
