import React from 'react'
import PropTypes from 'prop-types'
import TitleMain from '../components/titleMain';
import '../assets/css/profile.css';
import avatar from '../assets/img/profile__avatar.svg';
import axios from 'axios';
import config from '../config';
import Button from '../components/button';
import Modal from '../components/modal';

class Profile extends React.Component {
  constructor(props){
    super(props);
    // this.updateProfile = this.updateProfile.bind(this);
  }
  componentDidMount(){
    axios.get(config.server+'/api/profile')
      .then(function(res){
        document.getElementById('Profile__username').value = res.data.username;
        document.getElementById('Profile__alias').value = res.data.alias;
        document.getElementById('Profile__defaultSchool').value = res.data.defaultSchool;
        document.getElementById('Profile__defaultAddress').value = res.data.defaultAddress;
        document.querySelectorAll(`.gender__${res.data.gender}`)[0].setAttribute('selected','true');
      })
      .catch((err)=>{
        console.log(err)
      })
  }
  updatePassword(){
    axios.defaults.withCredentials = true;
    axios.put(config.server+'/api/user',{
      newPassword:document.getElementById('Profile__newPassword').value,
      password:document.getElementById('Profile__password').value,
    })
    .then(function(res){
      if(res.status == 200){
        alert('Update');
      }
    })
    .catch(function(err){
      console.log(err);
    })
  }
  updateProfile(){
    axios.defaults.withCredentials = true;
    axios.put(config.server+'/api/user',{
      alias:document.getElementById('Profile__alias').value,
      defaultSchool:document.getElementById('Profile__defaultSchool').value,
      defaultAddress:document.getElementById('Profile__defaultAddress').value,
      gender:document.getElementById('Profile__gender').options[document.getElementById('Profile__gender').selectedIndex].value,
    })
    .then(function(res){
      if(res.status == 200){
        alert('变更完成');
      }
    })
    .catch(function(err){
      console.log(err);
    })
  }
  render () {
    return(
      <div className='Profile'>
        <TitleMain name='个人数据编辑'/>
        <div className='Profile__body'>
            <div className='Profile__avatar'>
              <img src={avatar} width='100%'/>
            </div>
            <div className='Profile__blank'>
              <label className='Profile__label'>暱称</label>
              <input className='Profile__input' id="Profile__alias"></input>
            </div>
            <div className='Profile__blank'>
              <label className='Profile__label'>性别</label>
              <select name="gender" id="Profile__gender" className='Profile__select'>
                <option className='gender__male' value='male'>男</option>
                <option className='gender__female' value='female'>女</option>
                <option className='gender__unknown' value='unknown'>保密</option>
              </select>
            </div>
            <div className='Profile__blank'>
              <label className='Profile__label'>邮箱</label>
              <input className='Profile__input' id='Profile__username' readOnly='true'></input>
            </div>
            <div className='Profile__blank'>
              <label className='Profile__label'>默认学校名称</label>
              <input className='Profile__input' id='Profile__defaultSchool'></input>
            </div>
            <div className='Profile__blank'>
              <label className='Profile__label'>默认学校地址</label>
              <input className='Profile__input' id='Profile__defaultAddress'></input>
            </div>
            {/*
              <div className='Profile__blank'>
                <label className='Profile__label'>密码修改</label>
                <button className="uk-button uk-button-default" uk-toggle='target:#editPassword'>修改密码</button>
              </div>
            */}
        </div>
        <div className='Profile__btn'>
          <Button name='提交修改' fn={this.updateProfile}/>
        </div>
        <div className='Profile__editPassword'>
          <Modal id='editPassword' width='30%' >
            <div className='input__wrapper'>
              <label className='Profile__label'>旧密码</label>
              <input className='Profile__input' id='Profile__password' placeholder='请输入旧密码' ></input>
            </div>
            <div className='input__wrapper'>
              <label className='Profile__label'>新密码</label>
              <input className='Profile__input' id='Profile__newPassword' placeholder='请输入新密码'></input>
            </div>
            <Button name='提交' fn={this.updatePassword}/>
          </Modal>
        </div>
      </div>
    )
  }
}

export default Profile;
