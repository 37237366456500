import React from 'react'
import PropTypes from 'prop-types'
import TitleMain from '../components/titleMain';
import '../assets/css/feedback.css';
import Button from '../components/button';

class Feedback extends React.Component {
  constructor(props){
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitLink = this.onSumbitLink.bind(this);
    this.enter = this.enter.bind(this);
    this.state={
      url:'',
      height:0,
      width:0,
    }
  }
  componentDidMount(){
    window.scrollTo(0,0);
  }
  enter(e){
    if(e.keyCode == 13){
      e.preventDefault();
      this.onSubmit();
    }
  }
  checkIframe(){
    let iframe = document.getElementById('frame');
    let iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
    //check if loading
    if( iframeDoc.readyState == 'complete'){
      iframe.contentWindow.onload = function(){
            window.alert("I am loaded");
        };
      window.alert('hi');
      return;
    }
    window.setTimeout(this.checkIframe, 100);
  }
  onSubmit(){
    let value = document.getElementById('code').value;
    if(value == '0412PLK'){
      document.querySelectorAll('.Feedback__form')[0].innerHTML = '<p>加载中...</p>';
      window.setTimeout(()=> (document.querySelectorAll('.Feedback__form')[0].style.display='none'),1000)
      this.setState({
        url:'https://docs.google.com/forms/d/e/1FAIpQLSeoYmyy7SGMPOAIusuXEaA3VcZHcb4W3QYHSC0QfrSX4yJ6FA/viewform?embedded=true',
        height:900,
        width:window.innerWidth > window.innerHeight? window.innerWidth - window.innerWidth * 0.2:window.innerWidth - window.innerWidth * 0.1,
      })
    }
  }
  onSumbitLink(){
    let value = document.getElementById('code').value.toLowerCase();
    if(value == '0509cfxx'){
      document.querySelectorAll('.Feedback__form')[0].innerHTML = '<a href="https://www.wjx.cn/jq/23559519.aspx" target="_blank">填写课堂反馈</a>';
    }
  }
  render () {
    return(
      <div className='Feedback'>
        <TitleMain name='课程反馈' />
        <form className='Feedback__form'>
          <p className='Feedback__intro'>欢迎你们加入CocoRobo的STEM创意课堂，通过本次课程的学习，你们或许有了不小的收获和感受吧！下面我们对本次课堂效果进行一次简单的评价，请各位同学放心地将你们真实的想法填写到调查问卷上，我们会对你们填写的信息进行保密哦～</p>
          <p>请输入课程码</p> <input type='text' id='code'onKeyDown={this.enter} className='Form__input'/>
          <button onClick={this.onSubmitLink} type='button' style={{border:0,backgroundColor:'#000',color:'#fff',marginLeft:20,padding:'10px 20px',cursor:'pointer'}}>提交</button>
        </form>
        <div className='Feedback__iframe'>
          <iframe id='frame' src={this.state.url} width={this.state.width} height={this.state.height} frameBorder="0" marginHeight="0" marginWidth="0">加载中...</iframe>
        </div>
      </div>
    )
  }
}

export default Feedback;
