import React ,{Component} from 'react';
import '../assets/css/footer.css';
import Facebook from '../assets/img/facebook.svg';
import Vimeo from '../assets/img/vimeo.svg';
import cocoroboPatternIcon from '../assets/img/cocoroboPatternIcon.svg';
import {Link} from 'react-router-dom';
import cocorobo_wechat_qrcode from '../assets/img/cocorobo_wechat_qrcode.png'
import cocorobo_facebook_qrcode from '../assets/img/cocorobo_facebook_qrcode.png'



class Footer extends Component{
	render(){
		var date = new Date();
		var year = date.getFullYear();
		return(
			<footer>
				<div id='contentInfo'>
					<section>
					<ul className='footerLogo'>
						<span id='cocoroboLogo'><img src={cocoroboPatternIcon} alt='logo' width='30%'/></span>
					</ul>
					</section>
					<section>
					<ul>
						<span>公司</span>
						<li><Link to='/about'>关于我们</Link></li>
						<li><Link to='/contact'>联系方式</Link></li>
						<li><a href='https://www.lagou.com/gongsi/248174.html'>加入我们</a></li>
					</ul>
					</section>
					<section>
					<ul>
						<span>支持</span>
						{/*<li><Link to='/faq/cat1'>FAQ</Link></li> */}
						<li><Link to='/form'>问题反馈</Link></li>
						<li><a href='//help.cocorobo.cn'>使用教程</a></li>
					</ul>
					</section>
					<section>
					<ul>
						<span>关注我们</span>
						<li className="news">微信公众号<img src={cocorobo_wechat_qrcode}></img></li>
						<li><a href='http://v.qq.com/vplus/903177e263822586bb70c7e955ac4366'>腾讯视频</a></li>
					</ul>
					</section>
				</div>
				{/*
					<div id='social'>
					<span><img src={Facebook} alt='facebook' width="100%"/></span>
					<span><img src={Vimeo} alt='vimeo' width="100%"/></span>
				</div>
				*/}
				<div className='author'>
					<p>© 可可乐博（深圳）科技有限公司 {year} 版权所有. <a style={{color:'#fff'}} href="http://beian.miit.gov.cn/">粤ICP备18071116号</a></p>
					<p>地址：广东省深圳市宝安区新安街道兴东社区72区留仙三路38号创兴达商务中心2010 | 广东省深圳市南山区高新区粤兴二道10号香港中文大学深圳研究院 3 楼 310</p>
				    <p>电话：0755-86525412</p>
				</div>
			</footer>
		)
	}
}

export default Footer;
