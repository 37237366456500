import React,{Component} from 'react';
import '../assets/css/cardSmallFn.css';
import xhh from '../assets/img/xhh.svg';


//import plugins
import 'uikit/dist/css/uikit.min.css';
import 'uikit/dist/js/uikit.min.js';
import 'uikit/dist/js/uikit-icons.min.js';


class CardSmallFn extends Component{
	render(){
		return(
			<div className='cardSmallFn uk-card uk-card-default uk-width-1-4'>
				<div className='uk-card-media-top'>
					<img src={this.props.img} alt={this.props.name} />
				</div>
				<div className='uk-card-body'>{this.props.content}</div>
				<div className='cardSmallFnTitle'>{this.props.title}</div>
				<button className='like'><img src={xhh} alt='like' width='100%'/><span>1</span></button>
			</div>
		)
	}
}

export default CardSmallFn;
