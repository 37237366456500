import React, { Component } from 'react';
import TitleMain from '../components/titleMain';
import '../assets/css/contact.css';

//plugin
import scrollToComponent from 'react-scroll-to-component';

class Contact extends Component {
	componentDidMount() {
		scrollToComponent(this.Container, { offset: -150, align: 'top', duration: 500, ease: 'inCirc' });
	}
	render() {
		return (
			<div ref={(container) => { this.Container = container }}>
				<TitleMain name='联系我们' />
				<div className='contact'>
					<div className='wrapper'>
						<div>
							<h3>商务合作 & 技术支持</h3>
							<a href='mailTo:support@cocorobo.cc'>support@cocorobo.cc</a>
						</div>
						<div>
							<h3>地址</h3>
							<p>中国内地</p>
							<p>深圳市南山区虚拟大学园区粤兴二道10号香港中文大学深圳研究院3楼310</p>
							<p>0755-86525412</p>
							<p>邮编：518000</p>
							<br/>
							<p>Hong Kong</p>
							<p>Unit 2205, 22/F, Prosperity Place, 6 Shing Yip Street, Kwun Tong</p>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Contact;
