import React, { Component } from 'react';
import '../assets/css/nav.css';
import { Link } from 'react-router-dom';
import logoBlack from '../cocoroboB.svg';
import logoWhite from '../cocoroboW.svg';
import vimeo from '../assets/img/nav_vimeo.svg';
import config from '../config';
//plugin
import Pace from 'react-pace-progress';
import { observer } from 'mobx-react';
import axios from 'axios';



let homepage = config.basename;

@observer
class Nav extends Component {
	constructor(props) {
		super(props);
		this.state = {
			active: false,
			currentTarget: '',
			isToggleOpen: false,
			redirectToProduct: false,
			redirectToCurriculum: false,
			redireactToCompetition: false
		}
		this.checkLoginState = this.checkLoginState.bind(this);
	}
	checkLoginState() {
		let self = this;
		axios.defaults.withCredentials = true;
		axios.get(config.server + '/api/state')
			.then(function (res) {
				if (res.data == 'Logged') {
					self.props.userStore.login = true;
				} else {
					self.props.userStore.login = false;
				}
			})
			.catch(function (err) {
				console.log(err);
			})
	}
	hamburgerClick() {
		this.setState({
			isToggleOpen: !this.state.isToggleOpen
		})
		let hamburgerButton = document.getElementById('hamburger');
		let state = hamburgerButton.getAttribute('state');
		//change logo Style
		//special issue in homepage
		if (window.location.pathname === homepage && state === 'closed') {
			document.querySelectorAll('.logo')[0].children[0].setAttribute('src', logoBlack);
		} else if (window.location.pathname === homepage && state === 'opened') {
			document.querySelectorAll('.logo')[0].children[0].setAttribute('src', logoWhite);
		}

		if (state === 'closed') {
			document.getElementById('nav').style.display = 'block';
			hamburgerButton.setAttribute('state', 'opened');
			hamburgerButton.children[0].setAttribute('class', 'navButtonOpened');
			hamburgerButton.children[1].setAttribute('class', 'navButtonOpened');
			hamburgerButton.children[2].setAttribute('class', 'navButtonOpened');
		} else {
			document.getElementById('nav').setAttribute('style', '');
			hamburgerButton.setAttribute('state', 'closed');
			hamburgerButton.children[0].setAttribute('class', 'navButton');
			hamburgerButton.children[1].setAttribute('class', 'navButton');
			hamburgerButton.children[2].setAttribute('class', 'navButton');
		}
	}
	homepageClick() {
		let hamburgerButton = document.getElementById('hamburger');
		let state = hamburgerButton.getAttribute('state');
		if (state !== 'closed') {
			document.getElementById('nav').setAttribute('style', '');
			hamburgerButton.setAttribute('state', 'closed');
			hamburgerButton.children[0].setAttribute('class', 'navButton');
			hamburgerButton.children[1].setAttribute('class', 'navButton');
			hamburgerButton.children[2].setAttribute('class', 'navButton');
		}
	}
	hamburgerToggle(event) {
		if (event.target.nodeName === 'LI' && window.innerWidth < 640 && typeof event.target.children[0] === 'undefined') {
			this.hamburgerClick();
		}
	}
	navToggle(event) {
		//if span is exist
		if (event.target.nodeName === 'LI' && event.target.parentNode.id === 'nav') {
			if (typeof document.querySelectorAll('.navOpen')[0] !== 'undefined') {
				document.querySelectorAll('.navOpen')[0].classList.toggle('navOpen')
			}
			event.target.children[1].classList.toggle('navOpen');
		} else if (event.target.nodeName == 'A' && event.target.parentNode.parentNode.id == 'nav') {
			if (typeof document.querySelectorAll('.navOpen')[0] !== 'undefined') {
				document.querySelectorAll('.navOpen')[0].classList.toggle('navOpen')
			}
			event.target.parentNode.children[1].classList.toggle('navOpen');
		}
	}
	navItemToggle(event) {
		if (event.target.nodeName === 'SPAN') {
			// this.clearNavOpen();
			event.target.parentNode.parentNode.children[1].classList.toggle('navOpen');
		} else if (event.target.nodeName === 'A') {
			// this.clearNavOpen();
			event.target.parentNode.children[1].classList.toggle('navOpen');
		} else if (event.target.nodeName === 'LI') {
			this.hamburgerToggle(event);
		}
	}
	clearNavOpen() {
		if (typeof document.querySelectorAll('.navOpen')[0] !== 'undefined') {
			document.querySelectorAll('.navOpen')[0].classList.toggle('navOpen')
		}
	}
	fadeOut(event) {
		if (typeof document.querySelectorAll('.navOpen')[0] !== 'undefined') {
			document.querySelectorAll('.navOpen')[0].classList.toggle('navOpen')
		}
	}

	// 退出登录
	logout = () => {
		if (window.QC) {
			window.QC.Login({
				btnId: "qqLogin",	//插入按钮的节点id
			}, (reqData, opts) => {
				window.QC.Login.getMe(function (openId, accessToken) {
					// thirdparty(null, null, reqData.figureurl_qq_2, reqData.nickname, 1, openId);
					window.QC.Login.signOut();//退出QQ登录调用事件
					// this.props.history.push('/login')
					// }
				})
			});
		}
	}
	render() {
		return (
			<nav className={`${this.props.uiStore.isHomepage ? 'nav' : 'nav navDark'} ${this.props.uiStore.windowWidth < 640 && this.state.isToggleOpen ? 'nav--active' : ''}`}>
				{this.props.userStore.isLoading ? <Pace color="rgb(126, 206, 253)" height={3} /> : ''}
				<Link
					to={this.state.isToggleOpen ? '#' : '/'}
					className='logo'
					onClick={this.state.isToggleOpen ? null : this.homepageClick.bind(this)}
				>
					<span style={this.props.uiStore.isHomepage ? {color:"#fff"} : {color:"#000"}}>可可乐博</span>
					{/* <img src={this.props.uiStore.isHomepage ? logoWhite : logoBlack} alt="logo" align='left' /> */}
				</Link>
				<div className='navWrapper'>
					<div id='hamburger' state='closed' onClick={this.hamburgerClick.bind(this)}>
						<span className='navButton'></span>
						<span className='navButton'></span>
						<span className='navButton'></span>
					</div>
					<ul
						id='nav'
						className='navList font'
						onMouseOver={this.props.uiStore.windowWidth > 640 ? this.navToggle.bind(this) : null}
						onClick={this.props.uiStore.windowWidth < 640 ? this.navItemToggle.bind(this) : null}
					>
						{/*<ul id='nav' className='navList font' onMouseOver={window.innerWidth > 640 ? this.navToggle.bind(this) : null} onClick={this.hamburgerToggle.bind(this)}>*/}
						<li onMouseLeave={this.props.uiStore.windowWidth > 640 ? this.fadeOut.bind(this) : null}>
							<Link to={window.innerWidth < 640 ? '#' : '/product'} style={{ display: 'block', width: '100%' }}><span>产品</span></Link>
							<div className='navDropdown'>
								<ul onMouseLeave={this.props.uiStore.windowWidth > 640 ? this.fadeOut.bind(this) : null}>
									{/*<Link to='/product'><li className='Nav__anchor'>产品简介</li></Link>*/}
									<Link to='/cocomod'><li id='cocomod' className='Nav__anchor'>电子模块</li></Link>
									<Link to='/cocokit'><li id='cocokit' className='Nav__anchor'>课程套件</li></Link>
									<Link to='/train'><li id='train' className='Nav__anchor'>训练平台</li></Link>
									<Link to='/demoshow'><li id='demoshow' className='Nav__anchor'>Demo 展示</li></Link>
									{/*<Link to='/printer'><li id='printer' className='Nav__anchor'>3D打印机</li></Link>
											<Link to='/consumables'><li id='consumables' className='Nav__anchor'>打印耗材</li></Link>*/}
								</ul>
							</div>
						</li>
						<a href='//edu.cocorobo.cn' target='_blank'><li>课程</li></a>
						<a href='//cloud.cocorobo.cn' target='_blank'><li style={{width:140}}>可可智慧教育平台</li></a>
						{/*<li onMouseLeave={this.props.uiStore.windowWidth>640?this.fadeOut.bind(this):null}>
									<a href='//edu.cocorobo.cn' style={{display:'block',width:'100%'}}><span>课程</span></a>
									<div className='navDropdown'>
										<ul onMouseLeave = {this.props.uiStore.windowWidth > 640 ? this.fadeOut.bind(this) : null}>
											<Link to='/curriculum'><li className='Nav__anchor'>课程简介</li></Link>
											<Link to='/curriculumKit/0'><li id='curriculumKit' className='Nav__anchor'>课程中心</li></Link>
										</ul>
									</div>
								</li>*/}
						{/*<li onMouseLeave={this.props.uiStore.windowWidth>640?this.fadeOut.bind(this):null}>
									<Link to='#' style={{display:'block',width:'100%'}}><span>教程</span></Link>
									<div className='navDropdown'>
										<ul onMouseLeave = {this.props.uiStore.windowWidth > 640 ? this.fadeOut.bind(this) : null}>
											<a href='//help.cocorobo.cn' target='_blank'><li className='Nav__anchor'>帮助文档</li></a>
											<Link to='/tutor'><li className='Nav__anchor'>在线教学</li></Link>
										</ul>
									</div>
								</li>*/}
						<li onMouseLeave={this.props.uiStore.windowWidth > 640 ? this.fadeOut.bind(this) : null}>
							<Link to={window.innerWidth < 640 ? '#' : '/curriculum'} style={{ display: 'block', width: '100%' }}><span>服务</span></Link>
							<div className='navDropdown'>
								<ul onMouseLeave={this.props.uiStore.windowWidth > 640 ? this.fadeOut.bind(this) : null}>
									{/*<Link to='/curriculum'><li className='Nav__anchor'>课程简介</li></Link>*/}
									<Link to='/service'><li>教学</li></Link>
									<Link to='/print'><li>3D打印</li></Link>
								</ul>
							</div>
						</li>
						<li onMouseLeave={this.props.uiStore.windowWidth > 640 ? this.fadeOut.bind(this) : null}>
							<Link to={window.innerWidth < 640 ? '#' : '/event'} style={{ display: 'block', width: '100%' }}><span>活动</span></Link>
							<div className='navDropdown'>
								<ul onMouseLeave={this.props.uiStore.windowWidth > 640 ? this.fadeOut.bind(this) : null}>
									<Link to='/competition'><li>赛事</li></Link>
									<Link to='/workshop'><li>工作坊</li></Link>
								</ul>
							</div>
						</li>
						{/* <a href='http://v.qq.com/vplus/903177e263822586bb70c7e955ac4366' target='_blank'><li>视频</li></a> */}
						{/*<Link to='/order' style={{display:'block',width:'100%'}}><li id='nav__order'>采购</li></Link>*/}
						<Link to='/create' style={{ display: 'block', width: '100%' }}><li>创作</li></Link>
						<Link to='/download' style={{ display: 'block', width: '100%' }}><li>下载</li></Link>
						{this.props.userStore.login ?
							<li onMouseLeave={this.props.uiStore.windowWidth > 640 ? this.fadeOut.bind(this) : null}>
								<Link to='/profile' style={{ display: 'block', width: '100px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}><span>{this.props.userStore.alias}</span></Link>
								<div className='navDropdown'>
									<ul onMouseLeave={this.props.uiStore.windowWidth > 640 ? this.fadeOut.bind(this) : null}>
										<Link to='/profile'><li>编辑资料</li></Link>
										{/* <Link to='/orderQuery'><li>我的订单</li></Link> */}
										<Link to='/logout' ><li>注销</li></Link>
									</ul>
								</div>
							</li>
							: <Link to='/login' style={{ display: 'block', width: '100%' }}><li onClick={this.checkLoginState}>登录</li></Link>}
						{/*<li onMouseLeave={this.fadeOut.bind(this)}>
									<span>探索</span>
									<div className={this.state.active && this.state.currentTarget === '探索'? 'navDropdown navOpen':'navDropdown'}>
										<ul onMouseLeave = {this.fadeOut.bind(this)}>
											<Link to='/guide/blockly'><li>教程</li></Link>
											<Link to='/discover/project'><li>发现</li></Link>
											<a href='http://cocorobo.hk/wiki/doku.php?id=wiki' target='_blank'><li>Wiki</li></a>
										</ul>
									</div>
								</li>*/}
						{/*<Link to='/explore'><li>探索</li></Link>*/}
						{/* <a href="#"><li>商城</li></a> */}
						{/*<a href="#" uk-tooltip='title:即将开放;pos:bottom'><li>登录</li></a>*/}
					</ul>
				</div>
			</nav>
		)
	}
}

export default Nav;
