import React from 'react'
import PropTypes from 'prop-types'
import '../assets/css/colors.css'

let colors;
class Colors extends React.Component {
  constructor(props){
    super(props);
    colors = this.props.colors;
  }
  render () {
    return(
      <ul className='Colors'>
        {colors.map((x,y)=> <li key={x} className='Colors__item'><svg width="40" height="40"><circle cx="20" cy="20" r="15" fill={x} /></svg></li>)}
      </ul>
    )
  }
}

export default Colors;
