import React ,{Component} from  'react';
import QA from './QA';
import {Route} from 'react-router-dom'
const faqList={
	cat1:{
		question:['this is question1 in cat1','this is question 2 in cat1'],
		answer:['this is answer 1 from cat1','this is answer 2 from cat1'],
	},
	cat2:{
		question:['this is question1 in cat2','this is question 2 in cat2'],
		answer:['this is answer 1 from cat2','this is answer 2 from cat2'],
	},
	cat3:{
		question:['this is question1 in cat3','this is question 2'],
		answer:['this is answer 1 from cat3','this is answer 2 from cat3'],
	}
}

export default class FAQList extends Component{
	toggle(event){
		if(event.target.nodeName === 'H5'){
			if(event.target.getAttribute('aria-pressed') === 'false'){
				event.target.setAttribute('aria-pressed','true');
				event.target.nextSibling.className= 'answer_show answer';
				event.target.className= 'question_active';
			}else{
				event.target.setAttribute('aria-pressed','false');
				event.target.nextSibling.className= 'answer';
				event.target.className= 'question';
			}
		}
	}
	render(){
		return(
			<div className='faqList'>
				<div onClick={this.toggle}>
					<Route path='/faq/:id' component={main} />
				</div>
			</div>
		)
	}
}

const main = ({ match }) => (
	<div>
		{Object.keys(faqList[match.params.id]).map((x,y)=>
			<QA
			key={y}
			question={faqList[match.params.id].question[y]}
			answer={faqList[match.params.id].answer[y]} />)}
	</div>
)
