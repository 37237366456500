import React ,{Component} from 'react';
//components
import TitleMain from './titleMain';
import TitleSub from './titleSub';
import CardMedium from './card_medium';
import Image from './image';
import CocoKit from './cocokit';
import Kit from './kit';
import TabBar from './tabBar';
import Button from '../components/button';
import FancyBtn from '../components/fancyBtn';

//css
import CurriculumImg from '../assets/img/curriculumImg.jpg'
import curriculum_theme_light from '../assets/img/curriculum_theme_light.svg'
import curriculum_theme_robot from '../assets/img/curriculum_theme_robot.svg'
import curriculum_theme_smartHome from '../assets/img/curriculum_theme_smartHome.svg'
import curriculum_theme_music from '../assets/img/curriculum_theme_music.svg'
import curriculum_program_problemSolving from '../assets/img/curriculum_program_problemSolving.svg';
import curriculum_program_combination from '../assets/img/curriculum_program_combination.svg';
import '../assets/css/curriculum.css';
//plugins
import scrollToComponent from 'react-scroll-to-component';
import nl2br from 'react-newline-to-break';
import {Link,Route} from 'react-router-dom';
//img
import section_1 from '../assets/img/curriculum_s1.png';
import section_2 from '../assets/img/curriculum_s2.png';
import section_3 from '../assets/img/curriculum_s3.png';
import section_4 from '../assets/img/curriculum_s4.png';
import section_5 from '../assets/img/curriculum_s5.png';
import kit_keyboardPiano_bg from '../assets/img/kit_keyboardPiano_bg.png';
import kit_keyboardPiano from '../assets/img/kit_keyboardPiano.png';
import kit_carLineTracking_bg from '../assets/img/kit_carLineTracking_bg.png';
import kit_carLineTracking from '../assets/img/kit_carLineTracking.png';
import kit_LEDMiniBox_bg from '../assets/img/kit_LEDMiniBox_bg.png';
import kit_LEDMiniBox from '../assets/img/kit_LEDMiniBox.png';
import kit_bipedRobotBasic_bg from '../assets/img/kit_bipedRobotBasic_bg.png';
import kit_bipedRobotBasic from '../assets/img/kit_bipedRobotBasic.png';
import kit_weatherStation_bg from '../assets/img/kit_weatherStation_bg.png';
import kit_weatherStation from '../assets/img/kit_weatherStation.png';
import kit_robotArm_bg from '../assets/img/kit_robotArm_bg.png';
import kit_robotArm from '../assets/img/kit_robotArm.png';



const curriculum ={
	theme:{
		0:{
			content:'你是否想拥有一个属于自己的特制创意灯? 你是否想让自己的枱灯发出不一样的灯光?\n\nCOCOROBO的这个课程将手把手带你做出一盏你自己设计的专属创意呼吸灯。针对四年级上学期的学生我们开设了创意呼吸灯课程。\n\n课程分为两个部分，第一部分通过让学生学习3D建模技术，让学生们建出自己所构想的呼吸灯模型，再通过3D打印把3D模型实体化，这样学生就可以 拥有一个独一无二自主设计的创意呼吸灯模型。第二部分是教授学生Arduino进程设计技术，通过对LED灯等电子模块实行进程设计来达到可以随心控制自己的呼吸灯的颜色、亮度、以及频率。我们致力于在学生学习同时能收获到成就感，完成一份与众不同的产品',
		},
		1:{
			content:'拥有一个智能机器人再也不是梦！学生可利用在课堂上所学的基本电子知识，3D绘图和程序编程技巧，制作一个载歌载舞、独一无二的双足机器人！我们同时提供有趣的扩增实境（AR）应用程序，让双足机器人化身小战士，和你的朋友一起来一场既精彩又刺激的大战。'
		},
		2:{
			content:'你喜欢哪一样乐器，钢琴还是小提琴？学习3D绘、编程与电子基本知识后，你也可以成为乐器工匠，设计与制作属于自己的乐器！',
		},
		3:{
			content:'想了解物联网和智能家居是怎样工作的吗？通过运用我们的环境感应模块，学生将可以学习如何制作一个简单的家庭环境监测设备，自创外形、3D打印并组装，制作自己第一个物联网设备。',
		},
	},
	program:{
		0:{
			content:'在项目式教学方案中，学校可以自由组合不同的项目进行课程安排。\n\n 例如：安排一个学期的课程完成巡线小车制作和双足机器人制作，持续12周，每周2节课，共24小时',
		},
		1:{
			content:'在问题是教学方案中，我们会教授学生基本技能，引导学生思考问题，提出解决方案并实践该解决方案。\n\n 例如：开设一门以“智能校园”为主题的问题式教学课程，我们会引导学生利用前一单元所掌握的关于「3D模型设计」、「3D打印技术」、「电子电路」，以及「编程」等技能，来针对校园，设计他们未来心中的智能校园的场景。',
		}
	}
}
const kit ={
	0:{
		name:'创意电子',
		nameEN:'Creative Electronics',
		desc:'本套件将带领学生领略声音和灯光的美丽。利用这个套件可制作出可仿真不同乐器的电子琴。亦可以制作一个绚丽的呼吸灯。亦可将二者结合，制作出一个既可以发出声音也可以发出灯光的乐器。',
		modules:['m0','i2','o0','o1'],
		lists:['USB数据线*1','杜邦线若干'],
		bgImg:kit_keyboardPiano_bg,
		img:kit_keyboardPiano,
		cat:'入门级套件',
		video:'https://online-cocorobo.s3.cn-northwest-1.amazonaws.com.cn/keyboardPiano.mp4',
	},
	1:{
		name:'动力小车',
		nameEN:'Robot Car',
		desc:'通过学习本套件，学生将能够制作多功能迷你小车。小车具有自动驾驶的功能，亦可通过感知前方是否有障碍物，来实现智能避障的功能，甚至可以通过蓝牙控制小车。',
		modules:['m0','o3','i0','c1','a1','a1'],
		lists:['红外距离传感器*2','轮胎橡胶圈*2','USB数据线*1','杜邦线若干'],
		bgImg:kit_carLineTracking_bg,
		img:kit_carLineTracking,
		cat:'中级套件',
		video:'https://online-cocorobo.s3.cn-northwest-1.amazonaws.com.cn/robotCarLineTracker.mp4',
	},
	// 2:{
	// 	name:'LED灯盒',
	// 	nameEN:'LED Mini Box',
	// 	desc:'通过简单的3D绘图，并经由3D打印，DIY一台独一无二的灯盒',
	// 	modules:['m0','o1','i0'],
	// 	lists:['USB数据线*1'],
	// 	bgImg:kit_LEDMiniBox_bg,
	// 	img:kit_LEDMiniBox,
	// 	cat:'Light Kit',
	// 	video:video_LEDMiniBox,
	// },
	2:{
		name:'机械达人',
		nameEN:'Robot Guru',
		desc:'通过学习本套件，学生将能够制作一款百变双足机器人，可以通过设置进程使机器人作出特定的动作，除此之外，还可以通过蓝牙对它进行远程操作。通过这门课学生亦可制作一款可通过蓝牙操控的机械臂。',
		modules:['m0','o4','a2','a2','c1','a3'],
		lists:['50克舵机*4','USB数据线*1','机器人组装所需零件'],
		bgImg:kit_bipedRobotBasic_bg,
		img:kit_bipedRobotBasic,
		cat:'中级套件',
		video:'https://online-cocorobo.s3.cn-northwest-1.amazonaws.com.cn/bipedRobot.mp4',
	},
	3:{
		name:'智能家居',
		nameEN:'Smart Home Kit',
		desc:'通过学习本套件，学生将能够制作迷你气象站。学生可以使用迷你气象站采集环境的温度／湿度等数据，并在荧幕上显示出来；同时可以通过Wi-Fi控制气象站，并实现数据的传输。',
		modules:['m0','c0','i5','o2','a1','a3'],
		lists:['土壤湿度传感器*1','雨滴传感器*1','时钟模块*1','气敏传感器*1','人体红外传感器*1','超声波传感器*1','火焰传感器*1','震动传感器*1','红外置近传感器*1','倾斜传感器*1','光敏传感器*1','USB数据线*1','杜邦线若干'],
		cat:'高级套件',
		bgImg:kit_weatherStation_bg,
		img:kit_weatherStation,
		video:'https://online-cocorobo.s3.cn-northwest-1.amazonaws.com.cn/weatherStation.mp4',
	},
	// 5:{
	// 	name:'机械臂',
	// 	nameEN:'Robot Arm',
	// 	desc:'配合3D打印的零部件，使用电子模块搭建一台简单的机械臂',
	// 	modules:['m0','o4','a0'],
	// 	lists:['50克舵机*1','270度金属齿轮舵机*3','USB数据线*1','机械臂组装所需零件'],
	// 	cat:'Robot Kit',
	// 	bgImg:kit_robotArm_bg,
	// 	img:kit_robotArm,
	// 	video:video_robotArm,
	// }
}
let name=1;

class Curriculum extends Component{
	constructor(props){
		super(props);
		this.state={
			title:kit[0].name,
			video:kit[0].video,
			desc:kit[0].desc,
			name:0,
		}
		this.getCurriculumId = this.getCurriculumId.bind(this);
	}
	componentWillMount(){
		this.scrollToTop();
	}
	getCurriculumId(event){
		if(event.target.nodeName === 'BUTTON'){
			let length = event.target.className.length;
			let paramsId = event.target.className.charAt(length-1);
			this.modalState(paramsId);
			this.setState({
				name:paramsId,
			})
		}
	}
	modalState(i){
		this.setState({
			title:kit[i].name,
			video:kit[i].video,
			desc:kit[i].desc,
		})
	}
	scrollToTop(){
		window.scrollTo(0,0);
	}
	render(){
		return(
			<div className='curriculum' ref={(section)=> this.curriculum = section}>
				<TitleMain name='专题研习' />
				<div className="curriculum__section">
					<Image src={section_1}/>
					<p className="curriculum__content">专题研习可以将课题分解为一个个完整的并具有紧密关联的学习单元，方便学生在适当的周期内进行深度探究并获得阶段性的成果反馈。</p>
				</div>
				<TitleMain name='设计思维模型' />
				<div className="curriculum__section">
					<Image src={section_2}/>
					<p className="curriculum__content">通过设计思维模型的良性循环，让学生在学习过程中获得解决实际问题、不断自我更新和突破的能力。</p>
				</div>
				<div className="curriculum__section">
					<Image src={section_3}/>
				</div>
				<TitleMain name='综合项目式学习' />
				<div className="curriculum__section">
					<Image src={section_4}/>
					<p className="curriculum__content">以学生为中心，分组进行团队合作</p>
				</div>
				<TitleMain name='课程特色' />
				<div className="curriculum__section">
					<Image src={section_5}/>
				</div>
				<TitleMain name='课程套件' />
				<div>
					<TabBar fn={this.getCurriculumId}/>
					{/*<Kit
					key={kit[this.state.name].name}
					desc={kit[this.state.name].desc}
					modules={kit[this.state.name].modules}
					lists={kit[this.state.name].lists}
					bgImg={kit[this.state.name].bgImg}
					img={kit[this.state.name].img}
					name={kit[this.state.name].name}
					nameEN={kit[this.state.name].nameEN}
					cat={kit[this.state.name].cat}
					video={kit[this.state.name].video}
					/>*/}
				</div>
				{/*
				<div id='modal-video' uk-modal='true' className='uk-flex-top'>
					<div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
						<h2>{this.state.title}</h2>
						<p>{this.state.desc}</p>
						<video controls='true' playsInline uk-video='true' width="100%" src={this.state.video}></video>
						<button className="uk-modal-close-outside"  uk-icon='close'>X</button>
					</div>
				</div>
				*/}
				{/*
					<div>
					<p className='content'>我们的课程体系融合了3D打印技术、配合CocoRobo自主研发的电子模块和图形化进程设计界面，学生可以从四大主题出发，制作出千变万化的机器人和智能设备。<br></br><br></br><br></br>
					我们的教学理念是以「Project based learning(PBL)」为核心。基于项目的学习(PBL)是以学生为中心的 教学法，涉及一种动态的课堂教学方式，相信学生通过积极探索现实世界的挑战和问题，获得更深入的知 识。学生通过长时间工作来学习一个主题，以调查和响应一个复杂的课程或问题。这是一种积极学习和探 究式学习的风格PBL与以纸为基础的记忆或教师主导的指令式教育不同，只是提出既定的事实，或者通过 改变问题或场景来描绘流畅的知识路径。结合该理念，我们设计了许多不同类型的课程，方便不同年龄段 的学生深入学习各种知识结构。<br></br><br></br><br></br>
					我们希望通过这种课程体系，为STEAM教育提供最好的一体化方案。激发学生们的学习动力，让他们积极 主动的去接触与了解知识。将不同学科的知识结合，强调个性化教育。让每个学生的产品都富有自己的特色。 达到教学统一化，成果个性化的效果。
					</p>
				</div>
				<TitleSub name='课程主题' margin='50px 10%'/>
				<CardMedium img={curriculum_theme_light} content={nl2br(curriculum.theme[0].content)} title='灯光'/>
				<CardMedium img={curriculum_theme_robot} content={nl2br(curriculum.theme[1].content)} title='机器人'/>
				<CardMedium img={curriculum_theme_music} content={nl2br(curriculum.theme[2].content)} title='音乐'/>
				<CardMedium img={curriculum_theme_smartHome} content={nl2br(curriculum.theme[3].content)} title='智能家居'/>

				<TitleSub name='课程方案' margin='50px 10%'/>
				<CardMedium img={curriculum_program_combination} content={nl2br(curriculum.program[0].content)} title='项目式教学'/>
				<CardMedium img={curriculum_program_problemSolving} content={nl2br(curriculum.program[1].content)} title='问题式教学'/>
				*/}
				<div style={{margin:'200px 0'}}>
					<Link to='/service'><FancyBtn name='查看服务'/></Link>
				</div>
			</div>
		)
	}
}
export default Curriculum;
