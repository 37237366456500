import React , {Component} from 'react';
import '../assets/css/partner.css';
import cooperate from '../assets/img/cooperate.svg';
import partner_1 from '../assets/img/college_1.jpg';
import partner_2 from '../assets/img/college_2.jpg';
import partner_3 from '../assets/img/college_3.jpg';
import partner_4 from '../assets/img/college_4.jpg';
import partner_5 from '../assets/img/college_5.jpg';
import partner_6 from '../assets/img/college_6.jpg';
import partner_7 from '../assets/img/college_7.jpg';
import partner_8 from '../assets/img/college_8.jpg';
//import plugins
import 'uikit/dist/css/uikit.min.css';
import 'uikit/dist/js/uikit.min.js';
import 'uikit/dist/js/uikit-icons.min.js';

class Partner extends Component{
	render(){
		return(
			<div className='partner'>
				<header className='font'><img src={cooperate} alt='cooperate'/><span>合作院校</span></header>
				<div className='partnerBody' uk-scrollspy="cls: uk-animation-scale-up; target:  > div; delay: 300; repeat:true">
					<div className='partnerList'>
					<img src={partner_1} alt='partner'/>
					<h5>香港中文大学</h5>
					</div>
					<div className='partnerList'>
					<img src={partner_2} alt='partner'/>
					<h5>弘立书院</h5>
					</div>
					<div className='partnerList'>
					<img src={partner_3} alt='partner'/>
					<h5>圣公会邓肇坚中学</h5>
					</div>
					<div className='partnerList'>
					<img src={partner_4} alt='partner'/>
					<h5>保良局罗氏基金中学</h5>
					</div>
					<div className='partnerList'>
					<img src={partner_5} alt='partner'/>
					<h5>英华书院</h5>
					</div>
					<div className='partnerList'>
					<img src={partner_6} alt='partner'/>
					<h5>张沛松纪念中学</h5>
					</div>
					<div className='partnerList'>
					<img src={partner_7} alt='partner'/>
					<h5>乐善堂余近卿中学</h5>
					</div>
					<div className='partnerList'>
					<img src={partner_8} alt='partner'/>
					<h5>乐善堂顾超文中学</h5>
					</div>
				</div>
			</div>
		)
	}
}

export default Partner;
