import React from 'react'
import PropTypes from 'prop-types'
import * as THREE from 'three';
import what from '../assets/stl/coverForLomo.stl';

//to be edit to ES6
var STLLoader = require('three-stl-loader')(THREE)


class Loader extends React.Component {
  constructor(props){
    super(props);
    this.animate = this.animate.bind(this);
    this.loadMesh = this.loadMesh.bind(this)
    this.pick = this.pick.bind(this);
    this.init = this.init.bind(this);
    this.detectUploadState = this.detectUploadState.bind(this);
    this.updateMesh = this.updateMesh.bind(this);
    // this.storeTempValue = this.storeTempValue.bind(this);
    this.state={
      color:'#E0E0E0',
    }
  }
  componentDidMount(){
    let preValue , storeTempValue;
    // document.getElementById('uploadFile').addEventListener('click',this.storeTempValue);
    document.getElementById('uploadFile').addEventListener('change',this.detectUploadState);
    document.getElementById('picker').addEventListener('click',this.pick);
  }
  // storeTempValue(){
  //  this.preValue = document.getElementById('uploadFile').value;
  // }
  detectUploadState(){
    if(document.getElementById('uploadFile').files.length === 1 && typeof this.storeTempValue === 'undefined'){
      this.init();
      document.querySelectorAll('.present__placeholder')[1].className='present__placeholder--clicked';
    }else if(document.getElementById('uploadFile').files.length === 1 && document.getElementById('uploadFile').value !== this.storeTempValue){
      this.updateMesh();
    }
  }
  pick(event){
    if(event.target.nodeName === 'circle'){
      let color = event.target.getAttribute('fill');
      let convertHash = color.replace('#','0x')
      this.mesh.material.color.setHex(convertHash);
    }
  }
  updateMesh(){
    const loader = new STLLoader();
    this.storeTempValue = document.getElementById('uploadFile').value;
    let uploadObj = document.getElementById('uploadFile').files;
    let tempURL = window.URL.createObjectURL(uploadObj[0])
    this.scene.remove(this.mesh);
    window.cancelAnimationFrame(this.animate) //remove default AnimationFrame
    loader.load(tempURL, this.loadMesh);
  }
  loadMesh(geometry){
      let id;
      geometry.center();
      let mesh = new THREE.Mesh(
        geometry,
        new THREE.MeshLambertMaterial({
           overdraw:true,
          color:0xffffff,
          shininess: 200 
        })
      );
      // Center the object
      this.scene.add(mesh);
      this.mesh = mesh;
      cancelAnimationFrame(this.id);
      this.animate()
      let box = new THREE.Box3().setFromObject(mesh);
      let boxSize = box.getSize();
      let length = boxSize.y.toFixed(2);
      let width = boxSize.x.toFixed(2);
      let height = boxSize.z.toFixed(2);
      this.setState({
        length:length,
        width:width,
        height:height,
      })
      
  }
  init() {
    this.storeTempValue = document.getElementById('uploadFile').value;
    let uploadObj = document.getElementById('uploadFile').files;
    let tempURL;
    Object.keys(uploadObj).map((x)=> (tempURL = window.URL.createObjectURL(uploadObj[x])));
    
    const width = this.mount.clientWidth;
    const height = this.mount.clientHeight;
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera( 75, width / height, 0.1, 1000 );
    const pointLight = new THREE.PointLight(0xffffff);
    var renderer = new THREE.WebGLRenderer();
    const loader = new STLLoader();
    camera.position.set(0,0,100);
    pointLight.position.set( 10 , 0 ,1000);
    scene.add( camera);
    scene.add( pointLight);
    renderer.setSize( width, height );
    
    this.scene = scene
    this.camera = camera
    this.renderer = renderer
    this.pointLight = pointLight
    
    loader.load(tempURL, this.loadMesh);
    
    this.mount.appendChild( this.renderer.domElement );
  }
  animate(){
    this.id = requestAnimationFrame(this.animate)
    this.mesh.rotation.x += 0.02
    this.mesh.rotation.y += 0.02
    this.renderer.render(this.scene, this.camera);
  }
  render () {
    return(
      <div ref={(mount)=> {this.mount = mount}} style={{width:"100%",height:"100%"}}/>
    )
  }
}

export default Loader;
