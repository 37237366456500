import React from 'react'
import PropTypes from 'prop-types'
import TitleMain from '../components/titleMain';
import Register from '../components/register';
import { observer } from 'mobx-react';

@observer
class Signup extends React.Component {
  state = {
    codeShow: true
  }
  render() {
    return (
      <div className="Signup">
        {this.state.codeShow ? <TitleMain name='注册' /> : <TitleMain name='绑定邮箱' />}
        <Register store={this.props.userStore} />
      </div>
    )
  }
}

export default Signup;
