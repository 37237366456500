import React from 'react'
//plugin
import axios from 'axios';
//Router
import { Redirect } from 'react-router-dom';
//config
import config from '../config';

class Logout extends React.Component {
  componentDidMount() {
    this.logout();

  }
  logout() {
    let self = this;
    axios.defaults.withCredentials = true;
    axios.get(`${config.server}/api/logout`)
      .then(function (response) {
        if (response.data === 'logout') {
          self.props.userStore.login = false;
          self.props.userStore.username = '';
          self.props.userStore.order = new Map();

          if (window.QC) {
            window.QC.Login({
              btnId: "qqLogin",	//插入按钮的节点id
            }, (reqData, opts) => {
              window.QC.Login.getMe(function (openId, accessToken) {
                // thirdparty(null, null, reqData.figureurl_qq_2, reqData.nickname, 1, openId);
                window.QC.Login.signOut();//退出QQ登录调用事件
                // this.props.history.push('/login')
                // }
              })
            });
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  render() {
    return (
      <Redirect to='/' />
    )
  }
}

export default Logout;
