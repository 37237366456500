import React from 'react'
import PropTypes from 'prop-types'

//how to use
  //defined id
  //add trigger exp: <button uk-toggle='target:#definedId'></button>

//props
  //width
  //id
  //children

class Modal extends React.Component {
  static defaultProps = {
    width:'80%'
  }
  render () {
    return(
      <div id={this.props.id}  uk-modal='true' className={`uk-flex-top ${this.props.id}`}>
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            {this.props.children}
            <button className="uk-modal-close-default" uk-close='true'></button>
          </div>
      </div>
    )
  }
}

export default Modal;
