import React ,{Component} from 'react';
import '../assets/css/article.css';


class Article extends Component{
	render(){
		return(
			<div className='article'>
				<img src={this.props.img} width='100%'/>
				<header>
					<h2>{this.props.title}</h2>
					<p className='tag'>{this.props.tag}</p>
				</header>
				<p>{this.props.content}</p>
			</div>
		)
	}
}

export default Article;
