import React, { Component } from 'react';
import TitleMain from './titleMain';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select, { SelectChangeEvent } from '@material-ui/core/Select';
import '../assets/css/downLoad.css'



class Download extends Component {
    state = {
        system: "",
        system1: "",
        selectList1Windows: [
            {
                id: 0,
                value: "https://cocorobo.cn/downloads/CocoBlocklyUploaderInstallerv3.0.12.exe",
                name: "v3.0.12"
            },
        ],
        selectList1MacOS: [
            {
                id: 0,
                value: "https://cocorobo.cn/downloads/CocoBlocklyUploaderInstallerv3.0.12.pkg",
                name: "v3.0.12"
            },
        ],
        uploaderVersion1: [],
        uoloaderLink1: "",
        uploaderName1: "",
        selectList2Windows: [
            {
                id: 0,
                value: "https://cocorobo.cn/downloads/CocoBlocklyXUploaderInstallerv1.1.0.exe",
                name: "v1.1.0"
            }, {
                id: 1,
                value: "https://cocorobo.cn/downloads/CocoBlocklyXUploaderInstallerv1.0.18.exe",
                name: "v1.0.18"
            }
        ],
        selectList2MacOS: [
            {
                id: 0,
                value: "https://cocorobo.cn/downloads/CocoBlocklyXUploaderInstallerv1.1.1.pkg",
                name: "v1.1.1"
            },
            {
                id: 1,
                value: "https://cocorobo.cn/downloads/CocoBlocklyXUploaderInstallerv1.1.0.pkg",
                name: "v1.1.0"
            }, {
                id: 2,
                value: "https://cocorobo.cn/downloads/CocoBlocklyXUploaderInstallerv1.0.18.pkg",
                name: "v1.0.18"
            }
        ],
        uploaderVersion: [],
        uoloaderLink: "",
        uploaderName: ""
    }
    componentDidMount = () => {
        // console.log(document.getElementsByTagName('footer')[0].style)
        document.getElementsByTagName('footer')[0].style.marginTop = 0;
        let PCSystem = navigator.userAgentData.platform;
        let version = this.state.selectList2Windows
        let version1 = this.state.selectList1Windows
        if (PCSystem !== "Windows") {
            PCSystem = "MacOS"
            version = this.state.selectList2MacOS
            version1 = this.state.selectList1MacOS
        }
        this.setState({
            system1: PCSystem,
            system: PCSystem,
            uploaderVersion: version,
            uploaderName: version[0].name,
            uoloaderLink: version[0].value,
            uploaderVersion1: version1,
            uploaderName1: version1[0].name,
            uoloaderLink1: version1[0].value
        })
    }
    handleChange1 = event => {
        console.log(event.target.value)
        let version = []
        if (event.target.value === "Windows") {
            version = this.state.selectList1Windows
        } else {
            version = this.state.selectList1MacOS
        }
        this.setState({
            system1: event.target.value,
            uploaderVersion1: version,
            uoloaderLink1: version[0].value,
            uploaderName1: version[0].name
        })
    }

    handleChange = (event) => {
        let version = []
        if (event.target.value === "Windows") {
            version = this.state.selectList2Windows
        } else {
            version = this.state.selectList2MacOS
        }
        this.setState({
            system: event.target.value,
            uploaderVersion: version,
            uoloaderLink: version[0].value,
            uploaderName: version[0].name
        })
    }

    handleChangeVersion = (event) => {
        const { uploaderVersion } = this.state;
        let link = uploaderVersion.find(item => item.name == event.target.value)
        this.setState({
            uoloaderLink: link.value,
            uploaderName: event.target.value
        })
    }

    handleChangeVersion1 = (event) => {
        const { uploaderVersion1 } = this.state;
        let link = uploaderVersion1.find(item => item.name == event.target.value)
        this.setState({
            uoloaderLink1: link.value,
            uploaderName1: event.target.value
        })
    }
    componentWillUnmount = () => {

        document.getElementsByTagName('footer')[0].style.marginTop = 100 + 'px';
    }


    render() {
        const { system1, system, uploaderVersion, uploaderName, uoloaderLink, uploaderName1, uoloaderLink1, uploaderVersion1 } = this.state;
        return (
            <div className="downLoad">
                {/* <TitleMain name='下載' /> */}
                <div className="top">
                    <h1 style={{ fontWeight: "600" }}>离线编程软件</h1>
                    {/* <p>基于 Blockly 的积木式硬件编程工具</p> */}
                </div>
                <div className="down">
                    <div className="downLeft first" style={{ lineHeight: '220px' }}>
                        <img src={require('../assets/img/cocorobolixianban3.png')} style={{ borderRadius: 10 }} />
                    </div>
                    <div className="downRight">
                        <span className="downName">CocoPi 图形化编程离线版本</span>
                        <p className="content">为了能够让用户更方便的使用该产品，在网络较慢或者其他情况下，可以更好使用。</p>
                        <p className="setting">支持的操作系统：</p>
                        <span>Windows</span>
                        <div className="downing">
                            <a href="https://cocorobo.cn/downloads/CocoRoboPiDeskTop Installer v1.0.3.exe">
                                <button>下载 CocoPi 图形化编程离线版本（v1.0.3）</button>
                            </a>
                            <span className="down_time2">更新于2024/05/22</span>
                        </div>
                    </div>
                </div>
                <div className="down">
                    <div className="downLeft first" style={{ lineHeight: '220px' }}>
                        <img src={require('../assets/img/cocorobolixianban2.png')} style={{ borderRadius: 10 }} />
                    </div>
                    <div className="downRight">
                        <span className="downName">AIoT 图形化编程离线版本</span>
                        <p className="content">为了能够让用户更方便的使用该产品，在网络较慢或者其他情况下，可以更好使用。</p>
                        <p className="setting">支持的操作系统：</p>
                        <span>Windows</span>
                        <div className="downing">
                            <a href="https://cocorobo.cn/downloads/CocoRoboXDeskTop Installer v1.0.8.exe">
                                <button>下载 AIoT 图形化编程离线版本（v1.0.8）</button>
                            </a>
                            <span className="down_time2">更新于2024/04/09</span>
                        </div>
                    </div>
                </div>
                <div className="down">
                    <div className="downLeft first" style={{ lineHeight: '220px' }}>
                        <img src={require('../assets/img/离线版图标.png')} style={{ borderRadius: 10 }} />
                    </div>
                    <div className="downRight">
                        <span className="downName">百变智造图形化编程离线版本</span>
                        <p className="content">为了能够让用户更方便的使用该产品，在网络较慢或者其他情况下，可以更好使用。</p>
                        <p className="setting">支持的操作系统：</p>
                        <span>Windows</span>
                        <div className="downing">
                            <a href="https://cocorobo.cn/downloads/CocoBlockly%20Desktop%20Installer%20v3.0.7.exe">
                                <button>下载百变智造图形化编程离线版本（v3.0.7）</button>
                            </a>
                            <span className="down_time2">更新于2024/04/09</span>
                        </div>
                    </div>
                </div>

                <h1 className="titleName">图形化编程上传插件</h1>
                <div className="down">
                    <div className="downLeft first" style={{ lineHeight: '220px' }}>
                        <img src={require('../assets/img/6.png')} style={{ borderRadius: 10 }} />
                    </div>
                    <div className="downRight">
                        <span className="downName">百变智造图形化编程平台上传插件</span>
                        <p className="content">为了能够让百变智造图形化编程平台能够从浏览器和主控模块进行通信，我们推出一款跨平台的上传插件（Uploader），用户下载该插件并安装，随后即可访问 CocoBlockly，对我们的电子模块进行上传编程。</p>
                        <p className="setting">支持的操作系统：</p>
                        <span>Windows / macOS</span>
                        <div className="downing">
                            <InputLabel id="demo-simple-select-name">系统：</InputLabel>
                            <Select style={{ marginLeft: '5px' }}
                                labelId="demo-select-small-name"
                                id="demo-select-small"
                                value={system1}
                                name="Age"
                                onChange={this.handleChange1}
                            >
                                <MenuItem value="Windows">Windows</MenuItem>
                                <MenuItem value="MacOS">MacOS</MenuItem>
                            </Select>

                            <InputLabel id="demo-simple-select-name" style={{ marginLeft: '20px' }}>版本：</InputLabel>
                            <Select style={{ marginLeft: '5px' }}
                                labelId="demo-select-small-name"
                                id="demo-select-small"
                                value={uploaderName1}
                                name="uploaderName"
                                onChange={this.handleChangeVersion1}
                            >
                                {uploaderVersion1.map(item => {
                                    return <MenuItem value={item.name} key={item.value}>{item.name}</MenuItem>
                                })}
                            </Select>
                            <a href={uoloaderLink1} style={{ marginLeft: '20px' }}>
                                <button>下载</button>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="down">
                    <div className="downLeft" style={{ lineHeight: '220px' }}>
                        <img src={require('../assets/img/CocoBlockly_X_Uploader.png')} style={{ borderRadius: 10 }} />
                    </div>
                    <div className="downRight">
                        <span className="downName">AIoT图形化编程平台上传插件</span>
                        <p className="content">为了能够让AIoT图形化编程平台能够从浏览器和我们的 AI 和 IoT 模块进行通信，进行上传和运行的操作，我们推出一款跨平台的上传插件，用户下载该插件并安装，随后便可以对我们的电子模块进行上传编程。</p>
                        <p className="setting">支持的操作系统：</p>
                        <span>Windows / macOS</span>
                        <div className="downing">
                            <InputLabel id="demo-simple-select-name">系统：</InputLabel>
                            <Select style={{ marginLeft: '5px' }}
                                labelId="demo-select-small-name"
                                id="demo-select-small"
                                value={system}
                                name="Age"
                                onChange={this.handleChange}
                            >
                                <MenuItem value="Windows">Windows</MenuItem>
                                <MenuItem value="MacOS">MacOS</MenuItem>
                            </Select>

                            <InputLabel id="demo-simple-select-name" style={{ marginLeft: '20px' }}>版本：</InputLabel>
                            <Select style={{ marginLeft: '5px' }}
                                labelId="demo-select-small-name"
                                id="demo-select-small"
                                value={uploaderName}
                                name="uploaderName"
                                onChange={this.handleChangeVersion}
                            >
                                {uploaderVersion.map(item => {
                                    return <MenuItem value={item.name} key={item.value}>{item.name}</MenuItem>
                                })}
                            </Select>
                            <a href={uoloaderLink} style={{ marginLeft: '20px' }}>
                                <button>下载</button>
                            </a>
                        </div>
                    </div>
                </div>

                <h1 className="titleName">推荐浏览器</h1>
                <div className="down">
                    <div className="downLeft">
                        <img src={require('../assets/img/3.png')} />
                    </div>
                    <div className="downRight">
                        <span className="downName">下载谷歌浏览器</span>
                        <p className="content">使用谷歌浏览器，在线编程体验更流畅</p>
                        <div className="downing">
                            <a href="https://cocorobo.cn/downloads/Chrome.apk" className='android'>
                                <button>下载Android版本</button>
                            </a>
                            <a target="_blank" href="https://www.google.cn/chrome/index.html">
                                <button>前往下载</button>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="down">
                    <div className="downLeft">
                        <img src={require('../assets/img/7.png')} />
                    </div>
                    <div className="downRight">
                        <span className="downName">360安全浏览器</span>
                        <p className="content">CocoBlockly 编程工具在 360 极速浏览器中运行，让你在线编程体验进行流畅</p>
                        <div className="downing">
                            <a target="_blank" href="https://browser.360.cn">
                                <button>前往下载</button>
                            </a>
                        </div>
                    </div>
                </div>
                <h1 className="titleName">辅助工具及软件</h1>
                <div className="down">
                    <div className="downLeft">
                        <img src={require('../assets/img/4.png')} />
                    </div>
                    <div className="downRight">
                        <span className="downName">TeamViewer 远程协助软件</span>
                        <p className="content">随时随地连接到远程桌面电脑，让远程连接过程更加的快速和安全，轻松访问文件、网络及程序</p>
                        <div className="downing">
                            <a target="_blank" href="https://www.teamviewer.cn/cn/download/">
                                <button>前往下载</button>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="down">
                    <div className="downLeft">
                        <img src={require('../assets/img/5.png')} />
                    </div>
                    <div className="downRight">
                        <span className="downName">ZOOM 远程会议软件</span>
                        <p className="content">多人手机云视频会议软件，提供兼备高清视频与移动网络会议功能的免费云视频通话</p>
                        <div className="downing">
                            <a target="_blank" href="https://zoom.com.cn/download">
                                <button>前往下载</button>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="down">
                    <div className="downLeft">
                        <img src={require('../assets/img/8.jpg')} />
                    </div>
                    <div className="downRight">
                        <span className="downName">向日葵远程协助软件</span>
                        <p className="content">免费的集远程控制电脑手机、远程桌面连接、远程开机、远程管理、支持内网穿透的一体化远程控制管理工具软件</p>
                        <div className="downing">
                            <a target="_blank" href="https://sunlogin.oray.com/download/">
                                <button>前往下载</button>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="down">
                    <div className="downLeft">
                        <img src={require('../assets/img/9.jpg')} />
                    </div>
                    <div className="downRight">
                        <span className="downName">ToDesk 远程协助软件</span>
                        <p className="content">稳定流畅的远程控制,远程办公,远程协助软件.采用端对端加密,让每一次远程访问都安全可靠</p>
                        <div className="downing">
                            <a target="_blank" href="https://www.todesk.com/download.html">
                                <button>前往下载</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Download;