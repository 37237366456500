import i0o0 from '../assets/img/placeholder/moduleSet_i0o0.jpg';
import i0o1 from '../assets/img/placeholder/moduleSet_i0o1.jpg';
import i0o3 from '../assets/img/placeholder/moduleSet_i0o3.jpg';
import i0o5 from '../assets/img/placeholder/moduleSet_i0o5.jpg';
import i1o0 from '../assets/img/placeholder/moduleSet_i1o0.jpg';
import i1o1 from '../assets/img/placeholder/moduleSet_i1o1.jpg';
import i1o3 from '../assets/img/placeholder/moduleSet_i1o3.jpg';
// import i2o0 from '../assets/img/placeholder/moduleSet_i2o0.jpg';
import i2o1 from '../assets/img/placeholder/moduleSet_i2o1.jpg';
import i2o3 from '../assets/img/placeholder/moduleSet_i2o3.jpg';
import i3o1 from '../assets/img/placeholder/moduleSet_i3o1.jpg';
// import i3o3 from '../assets/img/placeholder/moduleSet_i3o3.jpg';
import i4o0 from '../assets/img/placeholder/moduleSet_i4o0.jpg';
import i4o1 from '../assets/img/placeholder/moduleSet_i4o1.jpg';
import i5o0 from '../assets/img/placeholder/moduleSet_i5o0.jpg';
import i5o1 from '../assets/img/placeholder/moduleSet_i5o1.jpg';
import i5o2 from '../assets/img/placeholder/moduleSet_i5o2.jpg';
import i5o3 from '../assets/img/placeholder/moduleSet_i5o3.jpg';
import i5o4 from '../assets/img/placeholder/moduleSet_i5o4.jpg';
import i6o1 from '../assets/img/placeholder/moduleSet_i6o1.jpg';

import i7o1 from '../assets/img/AI/AI-灯屏.jpg'
import i7o2 from '../assets/img/AI/AI-屏幕-新.jpg'
import i7o3 from '../assets/img/AI/AI-电机驱动.jpg'
import i7o4 from '../assets/img/AI/AI-扩展转接.jpg'
import i7o5 from '../assets/img/AI/AI-屏幕（摄像头）.jpg'
import i7o6 from '../assets/img/AI/AI-手柄-屏幕-新.jpg'
import i7o7 from '../assets/img/AI/AI-转接-屏幕-摄像头-新.jpg'
import i7o8 from '../assets/img/AI/AI-手柄-电机驱动-新.jpg'

import i8o1 from '../assets/img/IoT/IoT-灯屏.jpg'
import i8o2 from '../assets/img/IoT/IoT-屏幕-新.jpg'
import i8o3 from '../assets/img/IoT/IoT-电机驱动.jpg'
import i8o4 from '../assets/img/IoT/IoT-扩展转接.jpg'
import i8o5 from '../assets/img/IoT/IoT-手柄-新.jpg'

import VideoArea from '../assets/img/videoArea.png';



const sets = {
    i0o0: {
        title: '可调节音量的小音箱',
        content: '利用彩色卡纸制作一个小音箱的造型，通过教学模块上的按钮／旋钮，可以在播放音乐的同时调节小音箱的音量。',
        fn: '利用教学模块上的旋钮／开关／按键／红外遥控等按钮和传感器，来控制音乐的播放 ',
        inputModule: 'i0',
        outputModule: 'o0',
        cat: 'music',
        // img: VideoArea,
        img: i0o0,
				// more:{
				// 	1:{
				// 		title: '多彩LED灯',
		    //     content: '多彩LED灯是通过教学模块的旋钮设备切换不同的灯光动画模式。',
		    //     fn: '利用教学模块上的旋钮／开关／按键／红外遥控等按钮和传感器，可以灵活操纵简单又有趣的灯光设备 ',
				// 		img:i0o1,
				// 		cat:'light',
				// 	},
				// 	2:{
				// 		title: '红外控制小车',
		    //     content: '利用电机驱动模块可以制作一个小车，并通过红遥控器来控制小车的运动。',
		    //     fn: '利用教学模块上的旋钮／开关／按键／红外遥控等按钮和传感器，可以控制物体的运动',
				// 		img:i0o3,
				// 		cat:'power',
				// 	},
				// },
    },
    i0o1: {
        title: '多彩LED灯',
        content: '多彩LED灯是通过教学模块的旋钮设备切换不同的灯光动画模式。',
        fn: '利用教学模块上的旋钮／开关／按键／红外遥控等按钮和传感器，可以灵活操纵简单又有趣的灯光设备 ',
        inputModule: 'i0',
        outputModule: 'o1',
        cat: 'light',
        img: i0o1,
    },
    i0o3: {
        title: '红外控制小车',
        content: '利用电机驱动模块可以制作一个小车，并通过红遥控器来控制小车的运动。',
        fn: '利用教学模块上的旋钮／开关／按键／红外遥控等按钮和传感器，可以控制物体的运动',
        inputModule: 'i0',
        outputModule: 'o3',
        cat: 'power',
        img: i0o3,
    },
    i0o5: {
        title: '简易防盗设备',
        content: '利用环境监测模块的外置红外线功能，识别运动的物体并通过蜂鸣器发出“滴滴滴”的声音。可以运用在家中、商场、展会等的禁止触摸物品、防盗窃的场景。',
        fn: '利用该模块可以通过感应声音、温度、湿度、光照等外部环境条件的变化以及外置红外线、超声波等因素来控制蜂鸣器',
        inputModule: 'i0',
        outputModule: 'o5',
        cat: 'music',
        img: i0o5,
    },
    i1o0: {
        title: '空气琴',
        content: '空气琴的创意来源于一款十分神奇的乐器——特雷门琴，特雷门琴是世界上唯一不需要身体接触的电子乐器。而空气琴同样不需要接触任何键盘或者琴弦就能够弹奏出动人的音乐，只需要在一副手套上安装我们的电子模块并对其进行编程就能完成空气琴的制作，通过肢体的运动，就可以使空气琴发出各种乐器的声音啦。',
        fn: '利用该模块可以通过改变肢体动作来控制音乐的播放 ',
        inputModule: 'i1',
        outputModule: 'o0',
        cat: 'music',
        // img: VideoArea,
        img:i1o0,
    },
    i1o1: {
        title: '运动灯光手环',
        content: '小朋友可以根据自己的想象设计出独一无二的运动灯光手环，并且用3D打印机打印出来。在手环内部放入我们的模块组合五。运动感应模块会感应到肢体的运动变化，从而改变灯光的颜色和亮度。',
        fn: '利用该模块可以通过改变肢体动作来影响灯光的颜色',
        inputModule: 'i1',
        outputModule: 'o1',
        cat: 'light',
        img: i1o1,
    },
    i1o3: {
        title: '竹蜻蜓',
        content: '竹蜻蜓是利用运动感应模块可以提供运动相关的数据，并结合电机模块来控制竹蜻蜓的转动，小朋友只要动动脑袋，竹蜻蜓就可以自己转动起来啦！',
        fn: '该模块可以通过运用感应模块提供运动姿态相关的数据，并结合电机及来控制物体的运动',
        inputModule: 'i1',
        outputModule: 'o3',
        cat: 'power',
        img:i1o3,
        // img: VideoArea,
    },
    i2o0: {
        title: '迷你电子琴制作',
        content: '纸电子琴是由硬卡纸裁剪，折叠并粘合而成的迷你电子琴。在琴键位置上放置有导电胶布，可以由手指触摸直接控制它发声。电子琴的音色可以改变，小朋友可以直接用它来演奏乐曲。',
        fn: '通过触摸行为来发出乐器声音 ',
        inputModule: 'i2',
        outputModule: 'o0',
        cat: 'music',
				video:'https://v.qq.com/iframe/player.html?vid=b0524gha0um&tiny=0&auto=0',
        // video: 'https://player.vimeo.com/video/242438607?title=0&portrait=0',
        // img:i2o0,
        img: VideoArea,
    },
    i2o1: {
        title: '触摸式灯光雪人',
        content: '灯光雪人可以由卡纸、泡沫、透光塑料等多种可透光材料裁剪粘合而成，或者通过3D打印技术打印出灯光雪人的外形。在内部放入组合模块一。触摸感应模块加LED灯模块的组合，使我们能够通过触摸导电胶带或其他任意导电材料便可以调节灯光的颜色及大小，产生绚丽的灯光效果，由此制作出一个十分好看的灯光雪人。',
        fn: '利用该模块可以实现通过触摸的方式来控制灯光的颜色和大小的功能',
        inputModule: 'i2',
        outputModule: 'o1',
        cat: 'light',
				video:'https://v.qq.com/iframe/player.html?vid=z0524ouqt65&tiny=0&auto=0',
        // video: 'https://player.vimeo.com/video/232430618?title=0&portrait=0',
        img: i2o1,
    },
    i2o3: {
        title: '触摸感应旋转木马',
        content: '利用一些简单的材料活着3D打印技术制作出旋转木马的外形，结合一些导电材料就可以通过简单的触摸动作使旋转木马运转起来。',
        fn: '利用该模块可以实现通过触摸的方式来控制设备的运动',
        inputModule: 'i2',
        outputModule: 'o3',
        cat: 'power',
        img: i2o3,
    },
    i3o1: {
        title: '会跳舞的灯',
        content: '声控灯可以由卡纸、泡沫、透光塑料等多种可透光材料裁剪粘合而成，内部放入模块组合四。结合音频分析模块，我们可以利用音乐来改变和控制灯光的颜色及大小，得到非常炫目的“会跳舞的灯光。',
        fn: '使用该模块可以通过输入音频文档的方式来控制灯光',
        inputModule: 'i3',
        outputModule: 'o1',
        cat: 'light',
        img: i3o1,
    },
    i3o3: {
        title: '音乐摩天轮',
        content: '利用卡纸、木棒等简单的材料或者结合3D打印技术我们可以制作一个小小的摩天轮设备，通过与模块的结合，最终能够实现在播放音乐的同时，使摩天轮持续转动起来，当音乐停止的时候摩天轮便会停止转动。',
        fn: '该模块可以将音频文档转化为数据，并以此来控制设备的运动',
        inputModule: 'i3',
        outputModule: 'o3',
        cat: 'power',
        // img:i3o3,
        img: VideoArea,
    },
    i4o0: {
        title: '彩虹琴',
        content: '彩虹琴是一种通过用不同的颜色触发不同音调的电子琴。其原理是通过颜色分析模块的色彩识别功能，分辨出你手中任意物体的颜色，并通过编程将其这些颜色与特定的音调对应起来，如此便可完成一个十分有趣的彩虹琴啦！',
        fn: '利用该模块可以通过分辨各种不同的颜色来控制音乐的播放',
        inputModule: 'i1',
        outputModule: 'o0',
        cat: 'music',
        img:i4o0,
    },
    i4o1: {
        title: '辨色式灯光雪人',
        content: '灯光雪人可以由卡纸、泡沫、透光塑料等多种可透光材料裁剪粘合而成或者通过3D打印技术打印出灯光雪人的外形，内部放入我们的模块组合二。通过颜色分析模块的色彩识别功能，可以使雪人变换成你手中任意物体的颜色。',
        fn: '通过该模块可以使灯光的颜色变成你手中任意物体的颜色',
        inputModule: 'i4',
        outputModule: 'o1',
        cat: 'light',
        img: i4o1,
    },
    i5o0: {
        title: '电子门迎设备',
        content: '电子门迎设备是利用环境监测模块的外置红外线功能，识别运动的物体并通过音乐模块发出声音。通常可以放置在便利店门口，每当有顾客进出的时候便会发出“欢迎光临”等声音。',
        fn: '利用该模块可以通过感应声音、温度、湿度、光照等外部环境条件的变化以及外置红外线、超声波等因素来控制音乐的播放 ',
        inputModule: 'i5',
        outputModule: 'o0',
        cat: 'music',
        img:i5o0,
    },
    i5o1: {
        title: '声控灯',
        content: '声控灯可以由卡纸、泡沫、透光塑料等多种可透光材料裁剪粘合而成，内部放入模块组合三。结合环境检测模块，我们可以利用声音来改变和控制灯光的颜色及大小。',
        fn: '利用该模块可以通过感应声音、温度、湿度、光照等外部环境条件的变化以及外置红外线、超声波等因素来控制灯光',
        inputModule: 'i5',
        outputModule: 'o1',
        cat: 'light',
        img: i5o1,
    },
    i5o2: {
        title: '实时环境监测',
        content: '通过一个简易的设备实时监测周围环境的温度／湿度／光照等环境数据，并且会在OLED屏幕显示出来。',
        fn: '利用该模块可以通过感应声音、温度、湿度、光照等外部环境的信息并生成相应的数据 ',
        inputModule: 'i5',
        outputModule: 'o2',
        cat: 'light',
				video:'https://v.qq.com/iframe/player.html?vid=m0524obqgb2&tiny=0&auto=0',
        // video: 'https://player.vimeo.com/video/229513379?title=0&portrait=0',
        img: i5o2,
    },
    i5o3: {
        title: '声控窗帘',
        content: '声控窗帘是利用环境检测模块对声音的感应，并且通过电机驱动窗帘自动拉开和聚拢。',
        fn: '利用该模块可以通过感应声音、温度、湿度、光照等外部环境条件的变化以及外置红外线、超声波等因素，结合电机驱动模块来控制物体的运动',
        inputModule: 'i5',
        outputModule: 'o3',
        cat: 'power',
        img: i5o3,
    },
    i5o4: {
        title: '闻声花开',
        content: '首先可以利用一些简单的材料或者结合3D打印技术制作出花朵的外形，利用环境监测模块对声音强度值的检测，结合我们的舵机转接模块来对花瓣的运动进行控制，如此便可以制造出通过改变声音的强度就能控制花朵聚拢和开放的设备。',
        fn: '利用该模块可以通过感应声音、温度、湿度、光照等外部环境条件的变化以及外置红外线、超声波等因素，结合舵机转接模块来控制物体的运动',
        inputModule: 'i5',
        outputModule: 'o4',
        cat: 'power',
        img:i5o4,
    },
	i6o1: {
        title: '',
        content: 'Wi-Fi小枱灯是利用了包含Wi-Fi功能的模块，由此对台灯进行远程操控。',
        fn: '通过Wi-Fi功能对灯光进行远距离控制',
        inputModule: 'i6',
        outputModule: 'o1',
        cat: 'light',
        img:i6o1,
    },
    b0d0: {
        title: '语音灯光',
        content: '通过AI模块上的麦克风接口获取输入的语音指令，可以控制灯光的颜色与亮度变化。结合一个自制的语音台灯模型，就可以实现一盏语音台灯了。',
        fn: '通过AI模块上的麦克风进行控制',
        inputModule: 'b0',
        outputModule: 'd0',
        cat: 'light',
        img:i7o1,
    },
    b0d1: {
        title: '语音屏幕',
        content: '通过AI模块上的麦克风接口获取输入的语音指令，可以控制屏幕的显示内容（图案、文字、颜色）。',
        fn: '通过AI模块上的麦克风进行控制',
        inputModule: 'b0',
        outputModule: 'd1',
        cat: 'light',
        img:i7o2,
    },
    b0d2: {
        title: '语音小车',
        content: '通过AI模块上的麦克风接口获取输入的语音指令，可以控制电机驱动模块下达指令给对应接口的马达。结合一辆自制的小车模型，就可以实现语音控制下小车的不同运动。',
        fn: '通过AI模块上的麦克风进行控制',
        inputModule: 'b0',
        outputModule: 'd2',
        cat: 'light',
        img:i7o3,
    },
    b0d3: {
        title: '追踪云台',
        content: '在扩展模块上接入舵机，通过对摄像头的输入画面进行分析，可以实现舵机的跟随转动，从而制作出一个智能的人脸追踪云台了。',
        fn: '扩展模块可以提供更多的引脚，供使用者使用',
        inputModule: 'b0',
        outputModule: 'd3',
        cat: 'light',
        img:i7o7,
    },
    b0d4:{
        title: '人脸识别',
        content: '人脸识别可以通过摄像头获取人脸信息，图像显示在屏幕上，根据模型识别。',
        fn: '通过摄像头获取图像信息',
        inputModule: 'b0',
        outputModule: 'd4',
        cat: 'light',
        img:i7o5
    },
    b0d5:{
        title: '贪吃蛇',
        content: '游戏手柄可以实现摇杆与按钮的动作感应，在模块中设置程序，通过游戏手柄控制屏幕上的显示效果，就可以复现出经典的贪吃蛇了。',
        fn: '通过手柄上的按键、按钮与摇杆进行输入感应进行控制',
        inputModule: 'b0',
        outputModule: 'd5',
        cat: 'light',
        img:i7o6
    },
    b2d1:{
        title: '贪吃蛇',
        content: '游戏手柄可以实现摇杆与按钮的动作感应，在模块中设置程序，通过游戏手柄控制屏幕上的显示效果，就可以复现出经典的贪吃蛇了。',
        fn: '通过手柄上的按键、按钮与摇杆进行输入感应进行控制',
        inputModule: 'b1',
        outputModule: 'd1',
        cat: 'light',
        img:i7o6,
    },
    b2d2: {
        title: '遥控小车',
        content: '游戏手柄可以实现摇杆与按钮的动作感应，在模块中设置程序，结合自制的小车模型，就可以实现摇杆或按钮控制的遥控小车。',
        fn: '手柄可以的案件可以与按钮进行感应，从而达到控制的效果',
        inputModule: 'b1',
        outputModule: 'd1',
        cat: 'light',
        img:i7o8,
    },
    b1d3: {
        title: '追踪云台',
        content: '在扩展模块上接入舵机，通过对摄像头的输入画面进行分析，可以实现舵机的跟随转动，从而制作出一个智能的人脸追踪云台了。',
        fn: '扩展模块可以提供更多的引脚，供使用者使用',
        inputModule: 'b1',
        outputModule: 'd3',
        cat: 'light',
        img:i7o7,
    },
    b1d5: {
        title: '交互手柄',
        content: '将IoT模块与游戏手柄组合，通过IoT模块的按键、触摸、蓝牙、WIFi等进行信号传输，从而通过手柄发出控制指令到第三方硬件，实现一个不同交互形式的游戏手柄。',
        fn: '手柄',
        inputModule: 'b1',
        outputModule: 'd5',
        cat: 'light',
        img:i8o5,
    },
    s0e00:{
        title: '感光灯光',
        content: 'IoT模块内置的光线传感器可以获取光线强度，从而控制LED灯的灯光颜色及亮度变化。结合一盏自制的灯具模型，可以实现感光灯光的效果。',
        fn: '通过IoT模块中的光线传感器检测进行控制',
        inputModule: 'b1',
        outputModule: 'd5',
        cat: 'light',
        img:i8o1,
    },
    s0e11:{
        title: '环境显示',
        content: 'IoT模块内置的温湿度传感器可以监测此时的环境数据，并控制屏幕显示此时的环境监测结果。结合一台自制的环境监测装置模型，可以自制一盏小型的环境监测站。',
        fn: 'IoT模块内置的温湿度传感器可以监测此时的环境数据',
        inputModule: 'b1',
        outputModule: 'd5',
        cat: 'light',
        img:i8o2,
    },
    s0e12:{
        title: '抬起亮屏',
        content: 'IoT模块内置的运动传感器可以获取模块的运动状态。结合屏幕模块，可以在设定的角度下实现运动手表经典的抬起亮屏功能。',
        fn: 'IoT模块内置的运动传感器可以获取模块的运动状态',
        inputModule: 'b1',
        outputModule: 'd5',
        cat: 'light',
        img:i8o2,
    },
    s0e23:{
        title: '蓝牙遥控小车',
        content: 'IoT模块可以接收到蓝牙的数据信号，从而控制电机驱动模块进而驱动电机运动。自制一台小车模型，可以通过IoT模块的蓝牙控制实现一台蓝牙遥控小车。',
        fn: 'IoT模块可以接收到蓝牙的数据信号，从而控制电机驱动模块进而驱动电机运动',
        inputModule: 'b1',
        outputModule: 'd5',
        cat: 'light',
        img:i8o3,
    },
    s0e30:{
        title: '感光灯带',
        content: '不同于使用LED灯屏模块时的感光效果，通过扩展转接模块外接LED灯带到IoT模块上，可以实现更加丰富有趣的灯光效果。',
        fn: 'IoT模块内置的光线传感器可以获取光线强度，从而控制LED灯的灯光颜色及亮度变化',
        inputModule: 'b1',
        outputModule: 'd5',
        cat: 'light',
        img:i8o4,
    },
    s1e40:{
        title: '交互手柄',
        content: '将IoT模块与游戏手柄组合，通过IoT模块的按键、触摸、蓝牙、WIFi等进行信号传输，从而通过手柄发出控制指令到第三方硬件，实现一个不同交互形式的游戏手柄。',
        fn: '通过IoT模块的按键、触摸、蓝牙、WIFi等进行信号传输',
        inputModule: 'b1',
        outputModule: 'd5',
        cat: 'light',
        img:i8o5,
    },

}

export default sets;
