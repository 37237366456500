import React, { Component } from 'react';
import TitleMain from './titleMain';
import '../assets/css/demoShow.css'
import DemoDatas from '../data/demoShow'



class DemoShow extends Component {
    state = {
        oneTitle: ['机械主题', '物联网主题', '动力主题', '音乐主题', '灯光主题', '人机交互主题'],
        twoTitle: ['金属结构主题', '木制结构主题'],
        oneSelect: 0,
        twoSelect: 0,
        oneData: DemoDatas.one[0],
        twoData: DemoDatas.two[0].slice(0, 8),
        twoDataNum: parseInt(DemoDatas.two[0].length / 8) + 1,
        switchNum: 0
    }

    componentDidMount = () => {
        console.log(DemoDatas)
        document.getElementsByTagName('footer')[0].style.marginTop = 0;
    }

    componentWillUnmount = () => {
        document.getElementsByTagName('footer')[0].style.marginTop = 100 + 'px';
    }
    switchTitle = (i) => {
        this.setState({
            oneSelect: i,
            oneData: DemoDatas.one[i]
        })
    }
    switchTitleTwo = (i) => {
        this.setState({
            twoSelect: i,
            twoData: DemoDatas.two[i].slice(0, 8),
            twoDataNum: parseInt(DemoDatas.two[i].length / 8) + 1,
            switchNum: 0
        })
    }
    switchImg = (i) => {
        const { twoSelect } = this.state
        this.setState({
            switchNum: i,
            twoData: DemoDatas.two[twoSelect].slice(8 * i, 8 * (i + 1)),
        })
    }
    switchOne = (type) => {
        const { oneSelect } = this.state
        let num = oneSelect + type
        if (num === -1) {
            num = 5;
        } else if (num === 6) {
            num = 0;
        }
        console.log(type)
        this.setState({
            oneSelect: num,
            oneData: DemoDatas.one[num]
        })
    }
    switchTwo = (type) => {
        const { twoSelect,switchNum,twoData } = this.state;
        let num = twoSelect;
        let num1 = switchNum;
        if(type === 1){
            num1 = switchNum + type;
            if(num1<parseInt(twoData.length/8)+1){
                this.setState({
                    switchNum:num1,
                    twoData: DemoDatas.two[num].slice(8 * num1, 8 * (num1 + 1))
                })
            }else{
                num = twoSelect + type==2?0:twoSelect + type
                this.setState({
                    switchNum:0,
                    twoSelect:num,
                    twoData:DemoDatas.two[num].slice(0, 8)
                })
            }
        }else{
            num1 = switchNum + type;
            if(num1>-1){
                this.setState({
                    switchNum:num1,
                    twoData: DemoDatas.two[num].slice(0, 8)
                })
            }else{
                num = twoSelect + type==-1?1:twoSelect + type
                this.setState({
                    switchNum:1,
                    twoSelect:num,
                    twoData:DemoDatas.two[num].slice(8, 16)
                })
            }
        }
    }
    // 跳转详情页
    linkDetail=(type,data)=>{
        const {oneTitle,twoTitle,oneSelect,twoSelect} = this.state
        let title = '';
        let obj = data
        if(type===1){
            title = oneTitle[oneSelect];
        }else{
            title = twoTitle[twoSelect];
        }
        obj.title = title;
        obj.type = type
        console.log(obj)
        
        this.props.history.push('/demodetail/'+obj.id,obj);
    }
    render() {
        const { oneSelect, twoSelect, oneTitle, twoTitle, twoData, oneData, twoDataNum, switchNum } = this.state;
        return (
            <div className="demoShow">
                <img src={require('../assets/img/banner.png')} />
                <div className='showContent'>
                    <h2>一代 Demo</h2>
                    <span className='demoSwitch' onClick={() => this.switchOne(1)}><img src={require('../assets/img/icon_arrow_pre.png')} /></span>
                    <span className='demoSwitch' onClick={() => this.switchOne(-1)}><img src={require('../assets/img/icon_arrow_nor.png')} /></span>
                    <ul className='themeTitle'>
                        {oneTitle.map((x, i) => {
                            return <li key={x + i} className={oneSelect === i ? "activeDemo" : ""} onClick={() => { this.switchTitle(i) }}>{x}</li>
                        })}
                    </ul>
                    <ul className='themeImg'>
                        {oneData.map((x, i) => {
                            return (<li key={x.name + i} >
                                <img src={x.img} />
                                <p>{x.name}</p>
                                <div className='demoHide' onClick={()=>this.linkDetail(1,x)}>
                                    <p style={{fontSize:'1.5rem',borderBottom:'2px solid #fff',paddingBottom:20}}>{x.name}</p>
                                    <p style={{fontWeight:100}}><span className='contentTitle'>使用模块：</span>{x.module}</p>
                                    <p style={{fontWeight:100}} dangerouslySetInnerHTML={{__html: x.content}}></p>
                                </div>
                            </li>)
                        })}
                    </ul>
                    <h2>二代 Demo</h2>
                    <span className='demoSwitch' onClick={() => this.switchTwo(1)}><img src={require('../assets/img/icon_arrow_pre.png')} /></span>
                    <span className='demoSwitch' onClick={() => this.switchTwo(-1)}><img src={require('../assets/img/icon_arrow_nor.png')} /></span>
                    <ul className='themeTitle'>
                        {twoTitle.map((x, i) => {
                            return <li className={twoSelect === i ? "activeDemo" : ""} onClick={() => { this.switchTitleTwo(i) }}>{x}</li>
                        })}
                    </ul>
                    <ul className='themeImg'>
                        {twoData.map((x, i) => {
                            return (<li key={x.name + i} >
                                <img src={x.img} />
                                <p>{x.name}</p>
                                <div className='demoHide' onClick={()=>this.linkDetail(2,x)}>
                                    <p style={{fontSize:'1.5rem',borderBottom:'2px solid #fff',paddingBottom:20}}>{x.name}</p>
                                    <p style={{fontWeight:100}}><span className='contentTitle'>使用模块：</span>{x.module}</p>
                                    <p style={{fontWeight:100}} dangerouslySetInnerHTML={{__html: x.content}}></p>
                                </div>
                            </li>)
                        })}
                    </ul>
                    <p className='twoSwichImg'>
                        {Array.from({ length: twoDataNum }, (x, i) => {
                            return <span key={i} style={switchNum === i ? { background: "#205CC6" } : {}} onClick={() => { this.switchImg(i) }}></span>
                        })}
                    </p>
                </div>
                <div className='demoFooter'>
                    <img src={require('../assets/img/bg_banner2.png')} />
                    <p>
                        <span>一个</span>套件
                        <span style={{ marginLeft: '2%' }}>多种</span>创意
                        <img style={{ width: 39, marginLeft: '0.8%', marginTop: '-10px' }} src={require('../assets/img/cocoroboLogo.png')} />
                    </p>
                </div>
            </div>
        )
    }
}

export default DemoShow;