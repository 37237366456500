import React, { Component } from 'react';
import '../assets/css/kit.css';
import modules from '../components/modules';
import { Link } from 'react-router-dom'
import Button from './button'

class Kit extends Component {
	constructor(props) {
		super(props);
	}
	render() {
		return (
			<div
				className={`kit ${this.props.className}`}
				style={{ position: 'relative' }}
				id={this.props.id}
			>

				{/* <Link to='/order' className="kit--quiryBtn"> */}
					<img src={this.props.img} alt={this.props.nameEN} width='100%' />
				{/* </Link> */}
				{this.props.video && <Button
					uk-toggle="target: #modal-video"
					name='查看影片'
					size='button--medium'
					onClick={this.props.fn}
					style={{ backgroundColor: '#DD5031', position: 'absolute', top: '85%', left: '5%', fontSize: 14 }}
					styleclass='kit--demoBtn'
				/>}
			</div>
		)
	}
}

export default Kit;
