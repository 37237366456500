import React , {Component} from 'react';
// import '../assets/css/oneStepSolution.css';
import CardSmall from './card_small';
import CardLarge from './card_large';

import '../assets/css/oneStepSolution.css';

//import plugins
import 'uikit/dist/css/uikit.min.css';
import 'uikit/dist/js/uikit.min.js';
import 'uikit/dist/js/uikit-icons.min.js';

//import images
import card_s_1 from '../assets/img/card_small_1.svg';
import card_s_2 from '../assets/img/card_small_2.svg';
import card_s_3 from '../assets/img/card_small_3.svg';
import icon_puzzle from '../assets/img/icon_puzzle.svg';
import icon_service from '../assets/img/icon_service.svg';
import icon_curriculum from '../assets/img/icon_curriculum.svg';
import homepage_curriculum from '../assets/img/homepage_curriculum.svg';
import homepage_product from '../assets/img/homepage_product.svg';
import homepage_service from '../assets/img/homepage_service.svg';







const oneStepSolution ={
	card_s:{
		1:{
			name:'curriculum',
			content:'课程',
			img:card_s_3,
		},
		2:{
			name:'product',
			content:'产品',
			img:card_s_1,
		},
		3:{
			name:'service',
			content:'服务',
			img:card_s_2,
		},
	},
	card_l:{
		1:{
			name:'curriculum',
			title:'课程',
			content:'基于项目的课程培养体系，充分挖掘个人潜能',
			icon:icon_curriculum,
			img:homepage_curriculum,
		},
		2:{
			name:'product',
			title:'产品',
			content:'模块化产品，满足学生的个性化需求',
			icon:icon_puzzle,
			img:homepage_product,
		},
		3:{
			name:'service',
			title:'服务',
			content:'一条龙的教学服务，提供全方位课程支持',
			icon:icon_service,
			img:homepage_service,
		},
	},
}


class OneStepSolution extends Component{
	render(){
		return(
			<div>
				<div className="oneStepSolutionHeader">
					<h2 className="uk-heading-hero">STEAM教育</h2>
					<h3>一体化解决方案</h3>
				</div>
				<div className="uk-flex" style={{padding:'10%'}} uk-scrollspy="cls: uk-animation-slide-bottom-small; target:  > .uk-card; delay: 500; repeat: true">
					{Object.keys(oneStepSolution.card_s).map((x,y)=>
						<CardSmall
							name={oneStepSolution.card_s[x].name}
							key={y}
							content={oneStepSolution.card_s[x].content}
							img={oneStepSolution.card_s[x].img}
						/>)}
				</div>
				<div style={{padding:'0 10%'}} uk-scrollspy="cls: uk-animation-slide-left; target:  > .uk-card; delay: 3000;">
					{Object.keys(oneStepSolution.card_l).map((x,y) =>
						<CardLarge
							key={y}
							name={oneStepSolution.card_l[x].name}
							title={oneStepSolution.card_l[x].title}
							content={oneStepSolution.card_l[x].content}
							img={oneStepSolution.card_l[x].img}
							icon={oneStepSolution.card_l[x].icon}
						/>)}
				</div>
			</div>
		)
	}
}

export default OneStepSolution;
