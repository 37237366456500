import React,{Component} from 'react';
import '../assets/css/title.css';

class TitleMain extends Component{
	render(){
		return(
			<div>
				<h1 className='title'>{this.props.name}</h1>
			</div>
		)
	}
}

export default TitleMain;
