import React from 'react'
import PropTypes from 'prop-types'
//Components
import TitleMain from '../components/titleMain';
import CompetitionCard from '../components/competitionCard';
//image
import ys from '../assets/img/workshop_ys.jpg';
//css
import '../assets/css/workshop.css';

class Workshop extends React.Component {
  render () {
    return(
      <div>
        <TitleMain name='工作坊'/>
          <div className='Workshop'>
            <div className='Workshop__competitionCard'>
              <CompetitionCard title='「STEAM 工作坊」' subtitle={'柴湾青年广场5楼展览区'} date={'May 19 2018 | May 26 2018'} img={ys} link='http://www.youthsquare.hk/chi/hot-news/284/'/>
            </div>
          </div>
      </div>
    )
  }
}

export default Workshop;
