
import MainModule from '../assets/img/m0.jpg';
import MainModuleOldVersion from '../assets/img/m1.jpg';

import Sensor101Module from '../assets/img/i0.jpg';
import MotionSensorModule from '../assets/img/i1.jpg';
import TouchSensorModule from '../assets/img/i2.jpg';
import AudioAnalyzerModule from '../assets/img/i3.jpg';
import ColorAnalyzerModule from '../assets/img/i4.jpg';
import EnvironmentalSensorMoudle from '../assets/img/i5.jpg';
import MusicModule from '../assets/img/o0.jpg';
import LedMatrixModule from '../assets/img/o1.jpg';
import OledScreenModule from '../assets/img/o2.jpg';
import MotorDriverModule from '../assets/img/o3.jpg';
import ServoHubModule from '../assets/img/o4.jpg';
import WifiModule from '../assets/img/c0.jpg';
import BluetoothModule from '../assets/img/c1.jpg';
import HorizontalAdapterModule from '../assets/img/a0.jpg';
import HubModuleA1A2Module from '../assets/img/a1.jpg';
import HubModuleB1B2Module from '../assets/img/a2.jpg';
import MaleMaleAdapterModule from '../assets/img/a3.jpg';
import BatterySupply from '../assets/img/a4.jpg';
//temp
import MainModuleX4Temp from '../assets/img/m0x4_temp.jpg';
import BluetoothModuleX4Temp from '../assets/img/c1x4_temp.jpg';
import Sensor101ModuleX4Temp from '../assets/img/i0x4_temp.jpg';
import MotorDriverModuleX2Temp from '../assets/img/o3x2_temp.jpg';
import ServoHubModuleX2Temp from '../assets/img/o4x2_temp.jpg';

import icon_m0 from '../assets/img/icon/module/m0.svg';
import icon_i0 from '../assets/img/icon/module/i0.svg';
import icon_i1 from '../assets/img/icon/module/i1.svg';
import icon_i2 from '../assets/img/icon/module/i2.svg';
import icon_i3 from '../assets/img/icon/module/i3.svg';
import icon_i4 from '../assets/img/icon/module/i4.svg';
import icon_i5 from '../assets/img/icon/module/i5.svg';
import icon_i6 from '../assets/img/icon/module/i6.svg';
import icon_o0 from '../assets/img/icon/module/o0.svg';
import icon_o1 from '../assets/img/icon/module/o1.svg';
import icon_o2 from '../assets/img/icon/module/o2.svg';
import icon_o3 from '../assets/img/icon/module/o3.svg';
import icon_o4 from '../assets/img/icon/module/o4.svg';

// 主控
import m2ai from '../assets/img/ai-1.jpg';
import m2iot from '../assets/img/iot.jpg';
// 输出
import twoScreen from '../assets/img/screen-1.jpg';
import twoLed from '../assets/img/led.jpg';
// 输入
import twoSxt from '../assets/img/摄像头.jpg'
import twoCotroller from '../assets/img/controller.jpg';

// 转接
import twoExtnsion from '../assets/img/extension.jpg';
import two21 from '../assets/img/2-1.jpg'
import twoMotor from '../assets/img/motor.jpg'



//unitPrice by ST Jul 10 6:48 P.M




const modules = {
  m: {
    m0: {
      img: MainModule,
      name: '主板模块',
      icon: icon_m0,
      desc: '通过对主板模块编程，用它控制其他所有模块的数据的输入或输出。',
      wiki: 'https://help.cocorobo.cn/#/cocomod/main-controller',
      unitPrice: 200,
      // video:clip_m0,
    }
    // m1: {
    //     img: MainModuleOldVersion,
    //     name: '主控模块',
    // 		icon:icon_m0,
    // 		desc:'通过对主板模块编程，用它控制其他所有模块的数据的输入或输出。',
    //     wiki:'http://blockly-help.cocorobo.cn/',
    //     // video:clip_m1,
    // }
  },
  i: {
    i0: {
      img: Sensor101Module,
      name: '教学模块',
      match: ['o0', 'o1', 'o3', 'o5'],
      icon: icon_i0,
      desc: '教学板主要用于教学练习，帮助学生完成简单的编程实验，同时也可以用作感应器或者输入接口。例如：利用教学板上的按钮，与LED模块结合制作一个简易的俄罗斯方块游戏机。',
      wiki: 'https://help.cocorobo.cn/#/cocomod/sensor-101',
      video: 'https://online-cocorobo.s3.cn-northwest-1.amazonaws.com.cn/01_sensor-101-module.mp4',
    },
    i1: {
      img: MotionSensorModule,
      name: '运动感应模块',
      match: ['o0', 'o1', 'o3'],
      icon: icon_i1,
      desc: '此模块能够识别3轴加速度及角速度，提供运动姿态相关的数据。此模块可用于制作无人机或运动计步器。',
      wiki: 'https://help.cocorobo.cn/#/cocomod/motion',
      video: 'https://online-cocorobo.s3.cn-northwest-1.amazonaws.com.cn/11_motion-sensing-module.mp4',
    },
    i2: {
      img: TouchSensorModule,
      name: '触摸感应模块',
      match: ['o0', 'o1', 'o3'],
      icon: icon_i2,
      desc: '此模块为电容式触摸模块，可以识别人体微弱电流，并提供多个信号输入。此模块可结合音乐模块以制作迷你乐器。',
      video: "https://online-cocorobo.s3.cn-northwest-1.amazonaws.com.cn/04_touch-sensing-module.mp4",
      wiki: 'https://help.cocorobo.cn/#/cocomod/touch',
    },
    i3: {
      img: AudioAnalyzerModule,
      name: '音频分析模块',
      match: ['o1', 'o3'],
      icon: icon_i3,
      desc: '此模块可以将输入的音频文档转换为相应的数据，结合LED模块可以实现随音乐转换的灯光效果。与LED模块、频音乐模块结合可制作灯光频率音响。',
      wiki: 'https://help.cocorobo.cn/#/cocomod/audio-analyzer',
      // video:clip_i3,
    },
    i4: {
      img: ColorAnalyzerModule,
      name: '颜色分析模块',
      match: ['o0', 'o1'],
      icon: icon_i4,
      desc: '此模块提供色彩识别功能，能够检测到物体的色彩RGB值。',
      wiki: 'https://help.cocorobo.cn/#/cocomod/color-analyzer',
      // video:clip_i4,
    },
    i5: {
      img: EnvironmentalSensorMoudle,
      name: '环境感应模块',
      match: ['o0', 'o1', 'o2', 'o3', 'o4'],
      icon: icon_i5,
      desc: '此模块可以检测到环境的温度、湿度、光照强度、声音强度值，同时又有蜂鸣器以及 RGB（256 色） LED 灯，可提供警报的功能。',
      wiki: 'https://help.cocorobo.cn/#/cocomod/environment',
      video: "https://online-cocorobo.s3.cn-northwest-1.amazonaws.com.cn/06_environmental-sensing-module.mp4",
    },
    // i6: {
    //     img: WifiModule,
    //     name: 'WIFI模块',
    //     match: ['o1'],
    // 		icon:icon_i6,
    // 		desc:'Wi-Fi 无线通信模块在 CocoMods 负责进行数据的无线数据传输，支持 HTTP/WebSocket/MQTT 通信协议，有了它，用户可以搭建属于自己的物联网小应用。',
    // },
    // i7: {
    //     img: BluetoothModule,
    //     name: '蓝牙模块',
    //     match: ['o1'],
    // },

  },
  o: {
    o0: {
      img: MusicModule,
      name: '音乐模块',
      match: ['i0', 'i1', 'i2', 'i4', 'i5'],
      icon: icon_o0,
      desc: '此模块可用于播放音乐文档。例如制作一个简单的MP3音乐播放器，或与触摸板结合制作一个迷你电子琴。',
      wiki: 'https://help.cocorobo.cn/#/cocomod/music',
      video: 'https://online-cocorobo.s3.cn-northwest-1.amazonaws.com.cn/03_music-module.mp4',
    },
    o1: {
      img: LedMatrixModule,
      name: 'LED 灯屏模块',
      match: ['i0', 'i1', 'i2', 'i3', 'i4', 'i5', 'i6'],
      icon: icon_o1,
      desc: '此模块有提供5x5 彩色LED灯,每颗都可以独立控制，供用户自订显示模式。',
      wiki: 'https://help.cocorobo.cn/#/cocomod/led-matrix',
      video: 'https://online-cocorobo.s3.cn-northwest-1.amazonaws.com.cn/02_led-matrix-module.mp4',
    },
    o2: {
      img: OledScreenModule,
      name: '荧幕模块',
      match: ['i5'],
      icon: icon_o2,
      desc: '此模块可提供高解像度及高对比度的图像显示功能。与环境传感模块结合可制作一个迷你气象监测站',
      wiki: 'https://help.cocorobo.cn/#/cocomod/screen',
      // video:clip_o2,
    },
    o3: {
      img: MotorDriverModule,
      name: '马达驱动模块',
      match: ['i0', 'i1', 'i2', 'i3', 'i5'],
      icon: icon_o3,
      desc: '此模块可以用于控制两个直流马达，并提供4个数据输入口，方便连接各种感应器。',
      wiki: 'https://help.cocorobo.cn/#/cocomod/motor-driver',
      video: 'https://online-cocorobo.s3.cn-northwest-1.amazonaws.com.cn/07_motor-driver-module.mp4',
    },
    o4: {
      img: ServoHubModule,
      name: '伺服马达转接模块',
      match: ['i5'],
      icon: icon_o4,
      desc: '此模块提供内外部两种电源驱动模式，方便多种舵机的同时使用。此模块可用于制作机械臂、双足机器人等。',
      wiki: 'https://help.cocorobo.cn/#/cocomod/servo',
      video: "https://online-cocorobo.s3.cn-northwest-1.amazonaws.com.cn/08_servo-hub-module.mp4",
    },
    o5: {
      img: EnvironmentalSensorMoudle,
      name: '环境感应模块',
      match: ['i0'],
      icon: icon_i5,
      desc: '此模块可以检测到环境的温度、湿度、光照强度、声音强度值，同时又有蜂鸣器以及 RGB（256 色） LED 灯，可提供警报的功能。',
      wiki: 'https://help.cocorobo.cn/#/cocomod/environment',
      video: "https://online-cocorobo.s3.cn-northwest-1.amazonaws.com.cn/06_environmental-sensing-module.mp4",
    },
  },
  c: {
    c0: {
      img: WifiModule,
      name: 'Wi-Fi 通信模块',
      desc: 'WiFi 无线通信模块可进行无线数据传输，支持 HTTP/WebSocket/MQTT 通信协议。用户可以利用此模块搭建属于自己的物联网小应用。',
      wiki: 'https://help.cocorobo.cn/#/cocomod/wifi',
      // video:clip_c0,
    },
    c1: {
      img: BluetoothModule,
      name: '蓝牙通信模块',
      desc: '全功能蓝牙V2.0, 蓝牙通信模块可以用在一些需要手机控制的应用。',
      wiki: 'https://help.cocorobo.cn/#/cocomod/bluetooth',
      video: 'https://online-cocorobo.s3.cn-northwest-1.amazonaws.com.cn/10_bluetooth-module.mp4',
    },
  },
  a: {
    a0: {
      img: HorizontalAdapterModule,
      name: '平行转接模块',
      desc: '提供各类传感器的拓展接口。',
      wiki: 'https://help.cocorobo.cn/#/cocomod/horizontal-adapter',
      video: 'https://online-cocorobo.s3.cn-northwest-1.amazonaws.com.cn/14_hub-module.mp4',
    },
    a1: {
      img: HubModuleA1A2Module,
      name: '转接模块 A',
      desc: '提供各类传感器的拓展接口。',
      wiki: 'https://help.cocorobo.cn/#/cocomod/hub',
      video: 'https://online-cocorobo.s3.cn-northwest-1.amazonaws.com.cn/14_hub-module.mp4',
    },
    a2: {
      img: HubModuleB1B2Module,
      name: '转接模块 B',
      desc: '提供各类传感器的拓展接口。',
      wiki: 'https://help.cocorobo.cn/#/cocomod/hub',
      video: 'https://online-cocorobo.s3.cn-northwest-1.amazonaws.com.cn/14_hub-module.mp4',
    },
    a3: {
      img: MaleMaleAdapterModule,
      name: '正反转接模块',
      desc: '提供模块的反向连接功能，满足项目制作过程中的不同的场景应用。',
      wiki: 'https://help.cocorobo.cn/#/cocomod/reversed-adapter',
      // video:clip_a3,
    },
  },
  //temp
  t: {
    t0: {
      img: MainModuleX4Temp,
      name: '主板模块',
      icon: icon_m0,
      desc: '通过对主板模块编程，用它控制其他所有模块的数据的输入或输出。',
      wiki: 'https://help.cocorobo.cn/#/cocomod/main-controller',
    },
    t1: {
      img: Sensor101ModuleX4Temp,
      name: '教学模块',
      match: ['o0', 'o1', 'o3', 'o5'],
      icon: icon_i0,
      desc: '教学板主要用于教学练习，帮助学生完成简单的编程实验，同时也可以用作感应器或者输入接口。例如：利用教学板上的按钮，与LED模块结合制作一个简易的俄罗斯方块游戏机。',
      wiki: 'https://help.cocorobo.cn/#/cocomod/sensor-101',
    },
    t2: {
      img: MotorDriverModuleX2Temp,
      name: '马达驱动模块',
      match: ['i0', 'i1', 'i2', 'i3', 'i5'],
      icon: icon_o3,
      desc: '此模块可以用于控制两个直流马达，并提供4个数据输入口，方便连接各种感应器。',
      wiki: 'https://help.cocorobo.cn/#/cocomod/motor-driver',
    },
    t3: {
      img: ServoHubModuleX2Temp,
      name: '伺服马达转接模块',
      match: ['i5'],
      icon: icon_o4,
      desc: '舵机转接模块提供内外部两种电源驱动模式，方便多种舵机的同时使用。此模块可用于制作机械臂、双足机器人等。',
      wiki: 'https://help.cocorobo.cn/#/cocomod/servo',
    },
    t4: {
      img: BluetoothModuleX4Temp,
      name: '蓝牙通信模块',
      desc: '全功能蓝牙V2.0, 蓝牙通信模块可以用在一些需要手机控制的应用。',
      wiki: 'https://help.cocorobo.cn/#/cocomod/bluetooth'
    }
  },
  // others
  z: {
    z0: {
      img: BatterySupply,
      name: '电源管理模块',
      desc: '此模块能给主板模块提供稳定的电压，上有电源插座（杜邦线插座），可外置如 AA 电池盒或 9V 电池，模块上亦带有拨动开关，可调节电源的开和关',
      wiki: 'https://help.cocorobo.cn/#/cocomod/power-management',
      // video:clip_z0,
    }
  },
  b:{
    b0: {
      img: m2ai,
      name: 'AI 模块',
      match: ['d0', 'd1', 'd2','d3','d4','d5'],
      icon: icon_m0,
      desc: 'AI 模块作为人工智能相关应用的主控模块，拥有基础计算机视觉识别功能和机器学习模型的推理功能，可帮助实现入门和进阶级别的AI应用。模块主要应用于人工智能相关运算，如视觉识别、语音识别等。',
      wiki: 'https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8AI%E6%A8%A1%E5%9D%97',
      unitPrice: 200,
      // video:clip_m0,
    },
    b1:{
      img: twoSxt,
      name: '摄像头',
      match: ['d3'],
      icon: icon_m0,
      desc: '摄像头配备200万像素摄像头，两种安装方式可选，可作为前置或后置摄像头使用，以拍摄前方或后方图像，记录及传送图像到AI模块。 摄像头获取的图像尺寸为320 x 240 px及160 x 120 px两种。',
      wiki: 'https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E6%91%84%E5%83%8F%E5%A4%B4',
      unitPrice: 200,
      // video:clip_m0,
    },
    b2:{
      img: twoCotroller,
      name: '游戏手柄',
      match: ['d1', 'd2'],
      icon: icon_m0,
      desc: '游戏手柄模块内置摇杆与按钮，可用于自制游戏或者远程遥控的项目场景。模块可以分别与AI模块或IoT模块组装使用。',
      wiki: 'https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E6%B8%B8%E6%88%8F%E6%89%8B%E6%9F%84%E6%A8%A1%E5%9D%97',
      unitPrice: 200,
      // video:clip_m0,
    },
  },
  s:{
    s0: {
      img: m2iot,
      name: 'IoT 模块',
      match: ['e0','e1', 'e2','e3'],
      icon: icon_m0,
      desc: 'IoT 模块作为物联网相关应用的主机板模块，可用于基础教学及进阶项目的开发。',
      wiki: 'https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8IoT%E6%A8%A1%E5%9D%97',
      unitPrice: 200,
      // '光线传感器','温湿度传感器','陀螺仪','麦克风','电容触摸','按键开关'
      arrs:[
        {
          index:0,
          title:'光线传感器',
          arr:['e0','e3']  
        },
        {
          index:1,
          title:'温湿度传感器',
          arr:['e1']  
        },
        {
          index:2,
          title:'陀螺仪',
          arr:['e1']  
        },
        {
          index:3,
          title:'蓝牙',
          arr:['e2']  
        },
      ]
      // video:clip_m0,
    },
    s1:{
      img: twoCotroller,
      name: '游戏手柄',
      match: ['e4'],
      icon: icon_m0,
      desc: '游戏手柄模块内置摇杆与按钮，可用于自制游戏或者远程遥控的项目场景。模块可以分别与AI模块或IoT模块组装使用。',
      wiki: 'https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E6%B8%B8%E6%88%8F%E6%89%8B%E6%9F%84%E6%A8%A1%E5%9D%97',
      unitPrice: 200
      // video:clip_m0,
    },
    // s1:{
    //   img: twoSxt,
    //   name: '摄像头',
    //   match: ['d0','d1', 'd2','d3'],
    //   icon: icon_m0,
    //   desc: '摄像头配备200万像素摄像头，两种安装方式可选，可作为前置或后置摄像头使用，以拍摄前方或后方图像，记录及传送图像到AI模块。 摄像头获取的图像尺寸为320 x 240 px及160 x 120 px两种。',
    //   wiki: 'https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E6%91%84%E5%83%8F%E5%A4%B4',
    //   unitPrice: 200,
    //   // video:clip_m0,
    // },
    // s2:{
    //   img: twoCotroller,
    //   name: '游戏手柄',
    //   match: ['d0','d1', 'd2','d3'],
    //   icon: icon_m0,
    //   desc: '游戏手柄模块内置摇杆与按钮，可用于自制游戏或者远程遥控的项目场景。模块可以分别与AI模块或IoT模块组装使用。',
    //   wiki: 'https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E6%B8%B8%E6%88%8F%E6%89%8B%E6%9F%84%E6%A8%A1%E5%9D%97',
    //   unitPrice: 200,
    //   // video:clip_m0,
    // },
  },
  d:{
    d0:{
      img: twoLed,
      name: 'LED 灯屏模块',
      match: ['b0'],
      icon: icon_o1,
      desc: 'LED 灯屏模块是将36粒WS2813 全彩LED灯珠排列形成的电子模块，可以同时控制每粒LED灯的颜色和亮度，来实现基础图像绘制和效果显示。',
      wiki: 'https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8LED%E7%81%AF%E5%B1%8F%E6%A8%A1%E5%9D%97',
      unitPrice: 200,
      // video:clip_m0,
    },
    d1:{
      img: twoScreen,
      name: '屏幕模块',
      match: ['b0','b2'],
      icon: icon_o2,
      desc: '屏幕模块是一个LCD彩色屏幕显示器，屏幕尺寸为240 x 240 px (像素)，可显示JPG, BMP 等格式图片。集成摄像头模块插座，供后置拍照使用；屏幕模块搭载了播放芯片及扬声器，支持播放WAV格式的音频文件。',
      wiki: 'https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E5%B1%8F%E5%B9%95%E6%A8%A1%E5%9D%97',
      unitPrice: 200,
      // video:clip_m0,
    },
    d2:{
      img: twoMotor,
      name: '电机驱动模块',
      match: ['b0','b2'],
      icon: icon_o3,
      desc: '电机驱动模块内置一个型号为PCA9685的控制芯片，及2个型号为TB6612FNG的直流电机驱动芯片。模块可以控制直流电机的速度，并且可以控制伺服马达的转动角度。模块设置了外接传感器接口，可用于接入第三方传感器获取数据。',
      wiki: 'https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E7%94%B5%E6%9C%BA%E9%A9%B1%E5%8A%A8%E6%A8%A1%E5%9D%97',
      unitPrice: 200,
      // video:clip_m0,
    },
    d3:{
      img: twoExtnsion,
      name: '转接模块',
      icon: icon_o3,
      match: ['b0','b1'],
      desc: '扩展转接模块用于外接并控制第三方模块如舵机，以及外接并获取第三方传感器数据，如超声波传感器、光照传感器等。模块可以分别与AI模块或IoT模块组装使用，可以使用USB电源供电或外接电源供电。',
      wiki: 'https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E6%89%A9%E5%B1%95%E8%BD%AC%E6%8E%A5%E6%A8%A1%E5%9D%97',
      unitPrice: 200,
      // video:clip_m0,
    },
    
  },
  e:{
    e0:{
      img: twoLed,
      name: 'LED 灯屏模块',
      match: ['s0'],
      icon: icon_o1,
      desc: 'LED 灯屏模块是将36粒WS2813 全彩LED灯珠排列形成的电子模块，可以同时控制每粒LED灯的颜色和亮度，来实现基础图像绘制和效果显示。',
      wiki: 'https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8LED%E7%81%AF%E5%B1%8F%E6%A8%A1%E5%9D%97',
      unitPrice: 200,
      a:[0]
      // video:clip_m0,
    },
    e1:{
      img: twoScreen,
      name: '屏幕模块',
      match: ['s0'],
      icon: icon_o2,
      desc: '屏幕模块是一个LCD彩色屏幕显示器，屏幕尺寸为240 x 240 px (像素)，可显示JPG, BMP 等格式图片。集成摄像头模块插座，供后置拍照使用；屏幕模块搭载了播放芯片及扬声器，支持播放WAV格式的音频文件。',
      wiki: 'https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E5%B1%8F%E5%B9%95%E6%A8%A1%E5%9D%97',
      unitPrice: 200,
      a:[1,2]
      // video:clip_m0,
    },
    e2:{
      img: twoMotor,
      name: '电机驱动模块',
      match: ['s0'],
      icon: icon_o3,
      desc: '电机驱动模块内置一个型号为PCA9685的控制芯片，及2个型号为TB6612FNG的直流电机驱动芯片。模块可以控制直流电机的速度，并且可以控制伺服马达的转动角度。模块设置了外接传感器接口，可用于接入第三方传感器获取数据。',
      wiki: 'https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E7%94%B5%E6%9C%BA%E9%A9%B1%E5%8A%A8%E6%A8%A1%E5%9D%97',
      unitPrice: 200,
      a:[3]
      // video:clip_m0,
    },
    e3:{
      img: twoExtnsion,
      name: '转接模块',
      icon: icon_o3,
      match: ['s0'],
      desc: '扩展转接模块用于外接并控制第三方模块如舵机，以及外接并获取第三方传感器数据，如超声波传感器、光照传感器等。模块可以分别与AI模块或IoT模块组装使用，可以使用USB电源供电或外接电源供电。',
      wiki: 'https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8%E6%89%A9%E5%B1%95%E8%BD%AC%E6%8E%A5%E6%A8%A1%E5%9D%97',
      unitPrice: 200,
      a:[0]
      // video:clip_m0,
    },
    e4:{
      img: m2iot,
      name: 'IoT 模块',
      match: ['s1'],
      icon: icon_m0,
      desc: 'IoT 模块作为物联网相关应用的主机板模块，可用于基础教学及进阶项目的开发。',
      wiki: 'https://x-help.cocorobo.cn/#/md/%E4%BD%BF%E7%94%A8IoT%E6%A8%A1%E5%9D%97',
      unitPrice: 200,
      // '光线传感器','温湿度传感器','陀螺仪','麦克风','电容触摸','按键开关'
      // video:clip_m0,
    },
  }

}

export default modules;
