import React, { Component } from 'react';
import TitleMain from './titleMain';
import { Link } from 'react-router-dom';
import '../assets/css/demoShow.css'
import DemoDatas from '../data/demoShow'



class DemoDetail extends Component {
    state = {
        oneTitle: ['机械主题', '物联网主题', '动力主题', '音乐主题', '灯光主题', '人机交互主题'],
        twoTitle: ['金属结构主题', '木制结构主题'],
        oneSelect: 0,
        twoSelect: 0,
        oneData: DemoDatas.one[0],
        twoData: DemoDatas.two[0].slice(0, 8),
        twoDataNum: parseInt(DemoDatas.two[0].length / 8) + 1,
        switchNum: 0
    }

    componentDidMount = () => {
        console.log(this.props)
        // document.getElementsByTagName('footer')[0].style.marginTop = 0;
    }

    componentWillUnmount = () => {
        // document.getElementsByTagName('footer')[0].style.marginTop = 100 + 'px';
    }

    render() {
        const data = this.props.location.state
        return (
            <div className="demoDetail">
                <img src={require('../assets/img/banner.png')} />
                <div className='demodetailContent'>
                    <p style={{height:6,background:"#1F5CC6",width:'100%',marginBottom:60}}></p>
                    <div className='detailAbstract'>
                        <div className='abstractLeft' style={{width:'35%'}}>
                            <img src={require('../assets/img/icon_xian.png')} />
                            <h1>{data.type===1?"一代Demo":"二代Demo"}</h1>
                            <span>{data.type===1?"Generation Demo":"Second Generation Demo"}</span>
                            <p className='detailTitle'>{data.name}</p>
                            <p style={{fontWeight:100}}><span className='contentTitle'>使用模块：</span>{data.module}</p>
                            <p style={{fontWeight:100}} dangerouslySetInnerHTML={{__html: data.content}}></p>
                            <span style={{display:'inline-block',height:2,background:"#666",width:21,margin:0}}></span>
                            <div className='detailTheme'>{data.title}</div>
                        </div>
                        <div className='abstractRight'>
                            {data.video?<video src={data.video} controls="controls" style={{width:'100%',height:'100%'}}></video>:<img src={data.img} />}
                        </div>
                    </div>
                    <p style={{height:6,background:"#1F5CC6",width:'100%',marginTop:100,marginBottom:60}}></p>
                    <div className='detailAbstract'>
                        <div className='abstractLeft'>
                            <img src={require('../assets/img/icon_xian.png')} />
                            <h1>使用模块</h1>
                            <span>Use The Module</span>
                            <ul className='moduleImg'>
                                {data.moduleImgs.map((x,i)=>{
                                    return (<li key={i}>
                                        <div><img src={x.img} /></div>
                                        <a target='_blank' href={x.url}><span style={{marginLeft:0,fontSize:'1.3rem',color:'#333333',fontWeight:'bold'}}>{x.name}</span></a>
                                    </li>)
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DemoDetail;