import kit_keyboardPiano_bg from '../assets/img/kit_keyboardPiano_bg.png';
import kit_keyboardPiano from '../assets/img/kit_keyboardPiano.png';
import kit_carLineTracking_bg from '../assets/img/kit_carLineTracking_bg.png';
import kit_carLineTracking from '../assets/img/kit_carLineTracking.png';
import kit_LEDMiniBox_bg from '../assets/img/kit_LEDMiniBox_bg.png';
import kit_LEDMiniBox from '../assets/img/kit_LEDMiniBox.png';
import kit_bipedRobotBasic_bg from '../assets/img/kit_bipedRobotBasic_bg.png';
import kit_bipedRobotBasic from '../assets/img/kit_bipedRobotBasic.png';
import kit_weatherStation_bg from '../assets/img/kit_weatherStation_bg.png';
import kit_weatherStation from '../assets/img/kit_weatherStation.png';
import kit_robotArm_bg from '../assets/img/kit_robotArm_bg.png';
import kit_robotArm from '../assets/img/kit_robotArm.png';

const curriculumKit = {
	0: {
		name: '创意电子',
		nameEN: 'Creative Electronics',
		desc: '创意电子课程将围绕声音和灯光两大主题，带领学生进行创意探索及动手制造，从中领略声音和灯光的魅力。通过学习这门课程，学生能够制作出弹奏美妙音乐的电子乐器，或者是流光溢彩的灯光设备，同时，获得空间想像力、逻辑思维能力、创意设计能力的提升。',
		modules: ['m0', 'i2', 'o0', 'o1'],
		accessories: ['usb', 'dupont'],
		bgImg: kit_keyboardPiano_bg,
		img: kit_keyboardPiano,
		cat: '入门套装',
		video: 'https://online-cocorobo.s3.cn-northwest-1.amazonaws.com.cn/keyboardPiano.mp4',
		outline: ['学习音乐、灯光电子项目的科学原理', '通过3D建模设计电子琴、呼吸灯的个性化外观', '学习LED模组、音乐模组、触摸感应模组的功能原理，并结合 CocoBlockly学习图形化编程，实现不同的功能效果', '成果展示及改进、教学评估'],
		outlineLesson: ['1课时', '3～5课时', '3～5课时', '2课时'],
		data: 'https://drive.google.com/open?id=1shOTG9JRQX7b1SVDD_rxARvGuOxd1Fhwnwvu8N0BV5s',
	},
	1: {
		name: '动力小车',
		nameEN: 'Robot Car',
		desc: '动力小车课程将围绕动力主题，带领学生进行创意探索及动手制造。通过这门课程的学习，学生能够亲手制作出属于自己的个性化小车，并能够在赛场竞技。同时，提高自己的空间想象力、逻辑思维能力、问题解决能力。',
		modules: ['m0', 'o3', 'i0', 'c1', 'a1', 'a1'],
		accessories: ['IRSensor', 'rubberCircle', 'usb', 'dupont', 'motor'],
		bgImg: kit_carLineTracking_bg,
		img: kit_carLineTracking,
		cat: '中阶套装',
		video: 'https://online-cocorobo.s3.cn-northwest-1.amazonaws.com.cn/robotCarLineTracker.mp4',
		outline: ['学习电子小车的科学原理', '小车外观的3D建模及制作', '加入电机驱动、教学模块，并结合CocoBlockly学习图形化编程，实现不同的功能效果', '成果展示及改进、教学评估'],
		outlineLesson: ['1课时', '3～5课时', '3～5课时', '2课时'],
		data: 'https://drive.google.com/open?id=1-H-H5C1GccI3Wi-gqQZdjR9dCnTdZM_6o1fEltKy9dk',
	},
	2: {
		name: '机械达人',
		nameEN: 'Robot Guru',
		desc: '机械达人课程将围绕机械主题，带领学生进行创意探索及动手制造。学生将通过本次课程，学会亲手制作一款个性化百变双足机器人或者机械臂，并能用它来进行竞技，同时，获得空间想像力、逻辑思维能力、实践能力的提升。',
		modules: ['m0', 'o4', 'a2', 'a2', 'c1', 'a3', 'i0'],
		accessories: ['servo', 'usb'],
		bgImg: kit_bipedRobotBasic_bg,
		img: kit_bipedRobotBasic,
		cat: '中阶套装',
		video: 'https://online-cocorobo.s3.cn-northwest-1.amazonaws.com.cn/bipedRobot.mp4',
		outline: ['学习机器人、机械臂的科学原理', '机器人、机械臂外观的3D建模及制作', '加入舵机转接模块、蓝牙模块，并结合CocoBlockly学习图形化编程，实现不同的功能效果', '成果展示及改进、教学评估'],
		outlineLesson: ['1课时', '3～5课时', '3～5课时', '2课时'],
		data: 'https://drive.google.com/open?id=1sxImGLnnMrrECQ9Ejd0TJ6iPzUtPLsSAtUZlMXCYKog',
	},
	3: {
		name: '智能家居',
		nameEN: 'Smart Home Kit',
		desc: '智能创造课程将围绕物联网主题，带领学生进行创意探索及动手制造。通过学习这门课程学生能够制作一个可以采集环境的温／湿度等数据迷你气象站，并可以通过Wi-Fi实现数据的传输。同时，在这门课程中，学生能够获得空间想象力，逻辑思维能力、问题解决能力的提升。',
		modules: ['m0', 'c0', 'i5', 'o2', 'a1', 'a3'],
		accessories: ['usb', 'dupont', 'soilMoistureSensor', 'rainSensor', 'RTCSensor', 'gasSensor', 'PIRSensor', 'ultrasonicSensor', 'flameSensor', 'vibrationSensor', 'IRSensor', 'tiltSensor', 'lightSensor'],
		cat: '进阶套装',
		bgImg: kit_weatherStation_bg,
		img: kit_weatherStation,
		video: 'https://online-cocorobo.s3.cn-northwest-1.amazonaws.com.cn/weatherStation.mp4',
		outline: ['学习物联网的科学原理', '智能家居的3D建模设计及制作', '学习环境传感模块、OLED模块、Wi-Fi无线通信模块的功能原理，并结合CocoBlockly学习图形化编程，实现不同的功能效果', '成果展示及改进、教学评估'],
		outlineLesson: ['1课时', '3～5课时', '3～5课时', '2课时'],
		data: 'https://drive.google.com/open?id=1RY1JKxTrsf_dCqKDae92DW5cTSWW8P9Zt3M_tPFu-1c',
	},
}

export default curriculumKit;
