import {observable} from 'mobx'

class UserStore {
  @observable login = false;
  @observable isLoading = false;
  @observable directToRegister = false;
  @observable username = '';
  @observable alias = 'User';
  @observable googleId = '';
}

var userStore = new UserStore;

export default userStore;
