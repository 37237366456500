import React , {Component} from 'react';


//import plugins
import 'uikit/dist/css/uikit.min.css';
import 'uikit/dist/js/uikit.min.js';
import 'uikit/dist/js/uikit-icons.min.js';




class CardLarge extends Component{
	render(){
		return(
			<div className="uk-card uk-card-default uk-width-1-1" id={this.props.name} uk-grid="true" style={{padding:0,margin:'0',marginBottom:150}} >
    		<div className="uk-flex-last  uk-cover-container uk-width-2-3@m uk-width-1-1" style={{paddingLeft:0}}>
        	<img src={this.props.img} alt={this.props.name} width='100%'/>
    		</div>
    		<div className="uk-flex-first uk-card-body uk-width-1-1 uk-width-1-3@m uk-width-1-3@l">
					<img src={this.props.icon} alt={this.props.name} width="50%"/>
					<h2 className="uk-card-title">{this.props.title}</h2>
					<p>{this.props.content}</p>
				</div>
			</div>
		)
	}
}

export default CardLarge;
